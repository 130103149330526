// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`StudentPanel renders the student panel correctly 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.emotion-1 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 24px;
}

.emotion-1>.MuiGrid-item {
  padding-top: 16px;
}

.emotion-1>.MuiGrid-item {
  padding-left: 16px;
}

.emotion-2 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: -8px;
  width: calc(100% + 8px);
  margin-left: -8px;
  -webkit-flex-basis: calc(83.333333% + 8px);
  -ms-flex-preferred-size: calc(83.333333% + 8px);
  flex-basis: calc(83.333333% + 8px);
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: calc(83.333333% + 8px);
}

.emotion-2>.MuiGrid-item {
  max-width: none;
}

.emotion-2>.MuiGrid-item {
  padding-top: 8px;
}

.emotion-2>.MuiGrid-item {
  padding-left: 8px;
}

@media (min-width:600px) {
  .emotion-2 {
    -webkit-flex-basis: calc(83.333333% + 8px);
    -ms-flex-preferred-size: calc(83.333333% + 8px);
    flex-basis: calc(83.333333% + 8px);
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: calc(83.333333% + 8px);
  }
}

@media (min-width:900px) {
  .emotion-2 {
    -webkit-flex-basis: calc(83.333333% + 8px);
    -ms-flex-preferred-size: calc(83.333333% + 8px);
    flex-basis: calc(83.333333% + 8px);
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: calc(83.333333% + 8px);
  }
}

@media (min-width:1200px) {
  .emotion-2 {
    -webkit-flex-basis: calc(83.333333% + 8px);
    -ms-flex-preferred-size: calc(83.333333% + 8px);
    flex-basis: calc(83.333333% + 8px);
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: calc(83.333333% + 8px);
  }
}

@media (min-width:1536px) {
  .emotion-2 {
    -webkit-flex-basis: calc(83.333333% + 8px);
    -ms-flex-preferred-size: calc(83.333333% + 8px);
    flex-basis: calc(83.333333% + 8px);
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: calc(83.333333% + 8px);
  }
}

.emotion-3 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-4 {
  margin: 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.emotion-6 {
  margin: 0;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.emotion-7 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 16.666667%;
  -ms-flex-preferred-size: 16.666667%;
  flex-basis: 16.666667%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 16.666667%;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

@media (min-width:600px) {
  .emotion-7 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

@media (min-width:900px) {
  .emotion-7 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

@media (min-width:1200px) {
  .emotion-7 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

@media (min-width:1536px) {
  .emotion-7 {
    -webkit-flex-basis: 16.666667%;
    -ms-flex-preferred-size: 16.666667%;
    flex-basis: 16.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666667%;
  }
}

.emotion-9 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-9::-moz-focus-inner {
  border-style: none;
}

.emotion-9.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-9 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-9:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-9:hover {
    background-color: transparent;
  }
}

.emotion-9.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-10 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-11 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-12 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 24px;
}

.emotion-12>.MuiGrid-item {
  max-width: none;
}

.emotion-13 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.emotion-15 {
  margin: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: #1C1B1FB3;
}

.emotion-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.emotion-19 {
  box-sizing: border-box;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: thin;
}

.emotion-31 {
  overflow: hidden;
  min-height: 48px;
  -webkit-overflow-scrolling: touch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #D6D5DD;
  margin-bottom: 24px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

@media (max-width:599.95px) {
  .emotion-31 .MuiTabs-scrollButtons {
    display: none;
  }
}

.emotion-32 {
  position: relative;
  display: inline-block;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  white-space: nowrap;
  overflow-x: hidden;
  width: 100%;
}

.emotion-33 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.emotion-34 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.25;
  text-transform: none;
  max-width: none;
  min-width: 90px;
  position: relative;
  min-height: 48px;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  padding: 12px 16px;
  overflow: hidden;
  white-space: normal;
  text-align: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #1C1B1FB3;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}

.emotion-34::-moz-focus-inner {
  border-style: none;
}

.emotion-34.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-34 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-34.Mui-selected {
  color: #4B35FF;
}

.emotion-34.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-38 {
  position: absolute;
  height: 2px;
  bottom: 0;
  width: 100%;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #4B35FF;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  height: 3px;
}

.emotion-39 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.emotion-39>.MuiGrid-item {
  max-width: none;
}

.emotion-40 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 24px;
}

.emotion-42 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -8px;
  width: calc(100% + 8px);
  margin-left: -8px;
  margin-bottom: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: top;
  -webkit-box-align: top;
  -ms-flex-align: top;
  align-items: top;
}

.emotion-42>.MuiGrid-item {
  padding-top: 8px;
}

.emotion-42>.MuiGrid-item {
  padding-left: 8px;
}

.emotion-43 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 8.333333%;
  -ms-flex-preferred-size: 8.333333%;
  flex-basis: 8.333333%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 8.333333%;
}

@media (min-width:600px) {
  .emotion-43 {
    -webkit-flex-basis: 8.333333%;
    -ms-flex-preferred-size: 8.333333%;
    flex-basis: 8.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 8.333333%;
  }
}

@media (min-width:900px) {
  .emotion-43 {
    -webkit-flex-basis: 8.333333%;
    -ms-flex-preferred-size: 8.333333%;
    flex-basis: 8.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 8.333333%;
  }
}

@media (min-width:1200px) {
  .emotion-43 {
    -webkit-flex-basis: 8.333333%;
    -ms-flex-preferred-size: 8.333333%;
    flex-basis: 8.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 8.333333%;
  }
}

@media (min-width:1536px) {
  .emotion-43 {
    -webkit-flex-basis: 8.333333%;
    -ms-flex-preferred-size: 8.333333%;
    flex-basis: 8.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 8.333333%;
  }
}

.emotion-44 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  color: #BA1A1A;
  margin-right: 8px;
}

.emotion-45 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-basis: 91.666667%;
  -ms-flex-preferred-size: 91.666667%;
  flex-basis: 91.666667%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 91.666667%;
}

.emotion-45>.MuiGrid-item {
  max-width: none;
}

@media (min-width:600px) {
  .emotion-45 {
    -webkit-flex-basis: 91.666667%;
    -ms-flex-preferred-size: 91.666667%;
    flex-basis: 91.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 91.666667%;
  }
}

@media (min-width:900px) {
  .emotion-45 {
    -webkit-flex-basis: 91.666667%;
    -ms-flex-preferred-size: 91.666667%;
    flex-basis: 91.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 91.666667%;
  }
}

@media (min-width:1200px) {
  .emotion-45 {
    -webkit-flex-basis: 91.666667%;
    -ms-flex-preferred-size: 91.666667%;
    flex-basis: 91.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 91.666667%;
  }
}

@media (min-width:1536px) {
  .emotion-45 {
    -webkit-flex-basis: 91.666667%;
    -ms-flex-preferred-size: 91.666667%;
    flex-basis: 91.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 91.666667%;
  }
}

.emotion-47 {
  margin: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.emotion-49 {
  margin: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.emotion-51 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #4B35FF;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  padding: 10px 12px;
}

.emotion-51::-moz-focus-inner {
  border-style: none;
}

.emotion-51.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-51 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-51:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(75, 53, 255, 0.04);
}

@media (hover: none) {
  .emotion-51:hover {
    background-color: transparent;
  }
}

.emotion-51.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-51:hover {
  box-shadow: none;
}

.emotion-51.Mui-focusVisible {
  box-shadow: none;
}

.emotion-51:active {
  box-shadow: none;
}

.emotion-51.Mui-disabled {
  box-shadow: none;
}

.emotion-52 {
  display: inherit;
  margin-right: 8px;
  margin-left: -4px;
}

.emotion-52>*:nth-of-type(1) {
  font-size: 20px;
}

<div
    class="MuiStack-root emotion-0"
  >
    <div
      class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 emotion-1"
    >
      <div
        class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-spacing-xs-1 MuiGrid-direction-xs-column MuiGrid-grid-xs-10 emotion-2"
      >
        <div
          class="MuiGrid-root MuiGrid-item emotion-3"
        >
          <p
            class="MuiTypography-root MuiTypography-bodySmall emotion-4"
          >
            assignment
          </p>
        </div>
        <div
          class="MuiGrid-root MuiGrid-item emotion-3"
        >
          <h2
            class="MuiTypography-root MuiTypography-titleLarge emotion-6"
          >
            student 1
          </h2>
        </div>
      </div>
      <div
        class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-2 emotion-7"
      >
        <div
          class="MuiGrid-root MuiGrid-item emotion-3"
        >
          <button
            class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-9"
            data-testid="close-panel"
            tabindex="0"
            type="button"
          >
            <svg
              aria-hidden="true"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-10"
              data-testid="CloseIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              />
            </svg>
            <span
              class="MuiTouchRipple-root emotion-11"
            />
          </button>
        </div>
      </div>
    </div>
    <div
      class="MuiGrid-root MuiGrid-container MuiGrid-direction-xs-column emotion-12"
    >
      <div
        class="MuiGrid-root MuiGrid-container MuiGrid-item emotion-13"
      >
        <div
          class="MuiGrid-root MuiGrid-item emotion-3"
        >
          <span
            class="MuiTypography-root MuiTypography-labelMedium emotion-15"
          >
            Status
          </span>
        </div>
        <div
          class="MuiGrid-root MuiGrid-item emotion-3"
        >
          <div
            class="MuiStack-root emotion-17"
          >
            <p
              class="MuiTypography-root MuiTypography-bodySmall emotion-4"
            >
              Finsihed
            </p>
          </div>
        </div>
      </div>
      <div
        class="MuiGrid-root MuiDivider-root MuiDivider-fullWidth emotion-19"
        role="separator"
      />
      <div
        class="MuiGrid-root MuiGrid-container MuiGrid-item emotion-13"
      >
        <div
          class="MuiGrid-root MuiGrid-item emotion-3"
        >
          <span
            class="MuiTypography-root MuiTypography-labelMedium emotion-15"
          >
            Time Spent
          </span>
        </div>
        <div
          class="MuiGrid-root MuiGrid-item emotion-3"
        >
          <p
            class="MuiTypography-root MuiTypography-bodySmall emotion-4"
          >
            2 hours
          </p>
        </div>
      </div>
      <div
        class="MuiGrid-root MuiDivider-root MuiDivider-fullWidth emotion-19"
        role="separator"
      />
      <div
        class="MuiGrid-root MuiGrid-container MuiGrid-item emotion-13"
      >
        <div
          class="MuiGrid-root MuiGrid-item emotion-3"
        >
          <span
            class="MuiTypography-root MuiTypography-labelMedium emotion-15"
          >
            Finished At
          </span>
        </div>
        <div
          class="MuiGrid-root MuiGrid-item emotion-3"
        >
          <p
            class="MuiTypography-root MuiTypography-bodySmall emotion-4"
          >
            7 hours ago
          </p>
        </div>
      </div>
    </div>
    <div
      class="MuiTabs-root emotion-31"
    >
      <div
        class="MuiTabs-scroller MuiTabs-fixed emotion-32"
        style="overflow: hidden; margin-bottom: 0px;"
      >
        <div
          class="MuiTabs-flexContainer emotion-33"
          role="tablist"
        >
          <button
            aria-selected="true"
            class="MuiButtonBase-root MuiTab-root MuiTab-textColorPrimary MuiTab-fullWidth Mui-selected emotion-34"
            role="tab"
            tabindex="0"
            type="button"
          >
            2 Misconceptions
            <span
              class="MuiTouchRipple-root emotion-11"
            />
          </button>
          <button
            aria-selected="false"
            class="MuiButtonBase-root MuiTab-root MuiTab-textColorPrimary MuiTab-fullWidth emotion-34"
            data-testid="transcript-tab"
            role="tab"
            tabindex="-1"
            type="button"
          >
            Transcript
            <span
              class="MuiTouchRipple-root emotion-11"
            />
          </button>
        </div>
        <span
          class="MuiTabs-indicator emotion-38"
          style="left: 0px; width: 0px;"
        />
      </div>
    </div>
    <div
      class="MuiGrid-root MuiGrid-container MuiGrid-direction-xs-column emotion-39"
    >
      <div
        class="MuiGrid-root MuiGrid-item emotion-40"
      >
        <p
          class="MuiTypography-root MuiTypography-bodySmall emotion-4"
        >
          Misconceptions are common mistakes that students make when answering questions in a Kyron lesson. They are automatically detected by Kyron's AI and can be used to help guide your instruction.
        </p>
      </div>
      <div
        class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-spacing-xs-1 emotion-42"
      >
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 emotion-43"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-colorError MuiSvgIcon-fontSizeMedium emotion-44"
            data-testid="WarningIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M1 21h22L12 2zm12-3h-2v-2h2zm0-4h-2v-4h2z"
            />
          </svg>
        </div>
        <div
          class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-direction-xs-column MuiGrid-grid-xs-11 emotion-45"
        >
          <div
            class="MuiGrid-root MuiGrid-item emotion-3"
          >
            <h4
              class="MuiTypography-root MuiTypography-titleSmall emotion-47"
            >
              misconception 1
            </h4>
          </div>
          <div
            class="MuiGrid-root MuiGrid-item emotion-3"
          >
            <p
              class="MuiTypography-root MuiTypography-bodyMedium emotion-49"
            >
              description 1
            </p>
          </div>
          <div
            class="MuiGrid-root MuiGrid-item emotion-3"
          >
            <button
              aria-label="Create a Follow-up lesson"
              class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-disableElevation emotion-51"
              tabindex="0"
              type="button"
            >
              <span
                class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-52"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-10"
                  data-testid="EditIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75z"
                  />
                </svg>
              </span>
              Create a Follow-up Lesson
              <span
                class="MuiTouchRipple-root emotion-11"
              />
            </button>
          </div>
        </div>
      </div>
      <div
        class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-spacing-xs-1 emotion-42"
      >
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-1 emotion-43"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-colorError MuiSvgIcon-fontSizeMedium emotion-44"
            data-testid="WarningIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M1 21h22L12 2zm12-3h-2v-2h2zm0-4h-2v-4h2z"
            />
          </svg>
        </div>
        <div
          class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-direction-xs-column MuiGrid-grid-xs-11 emotion-45"
        >
          <div
            class="MuiGrid-root MuiGrid-item emotion-3"
          >
            <h4
              class="MuiTypography-root MuiTypography-titleSmall emotion-47"
            >
              misconception 2
            </h4>
          </div>
          <div
            class="MuiGrid-root MuiGrid-item emotion-3"
          >
            <p
              class="MuiTypography-root MuiTypography-bodyMedium emotion-49"
            >
              description 2
            </p>
          </div>
          <div
            class="MuiGrid-root MuiGrid-item emotion-3"
          >
            <button
              aria-label="Create a Follow-up lesson"
              class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-disableElevation emotion-51"
              tabindex="0"
              type="button"
            >
              <span
                class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-52"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-10"
                  data-testid="EditIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75z"
                  />
                </svg>
              </span>
              Create a Follow-up Lesson
              <span
                class="MuiTouchRipple-root emotion-11"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
