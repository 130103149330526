import { Close, Language, Settings, Visibility, WarningAmber, Description } from '@mui/icons-material';
import { Box, Dialog, IconButton, Stack, styled } from '@mui/material';
import { TypographyWithEllipsis } from 'components/TypographyWithEllipsis/TypographyWithEllipsis';
import { NavItemButton } from 'components/NavItemButton/NavItemButton';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Row } from 'components/Row/Row';
import { useUpdateLessonAndLessonCollection } from '../../../controllers/react-query';
import { useLessonFromParams } from '../../../controllers/react-query/lessonHooks';
import { LessonPayload } from '../../../controllers/types';

type NavItem = {
  label: string;
  path: string;
  icon: JSX.Element;
};

const settingsNavItems: NavItem[] = [
  { label: 'General', path: 'general', icon: <Settings /> },
  { label: 'Language & Voice', path: 'language-and-voice', icon: <Language /> },
  { label: 'Visibility', path: 'visibility', icon: <Visibility /> },
  { label: 'Misconceptions', path: 'misconceptions', icon: <WarningAmber /> },
  { label: 'Lesson Context', path: 'lesson-context', icon: <Description /> },
];

export const LESSON_SETTINGS_NAV_WIDTH = 200;

const CustomDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    height: '720px',
    maxWidth: '880px',
    width: '880px',
  },
}));

const SettingsNavigation = styled(Box)`
  background-color: ${({ theme }) => theme.palette.surfaceContainer.main};
  width: ${LESSON_SETTINGS_NAV_WIDTH}px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 16px;
  box-shadow: ${({ theme }) => theme.shadows[1]};
  z-index: 9;
`;

const SettingsMainPart = styled(Box)`
  overflow: hidden;
  padding-left: ${LESSON_SETTINGS_NAV_WIDTH}px;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 64px 1fr;
`;

export function LessonSettingsDialog() {
  const location = useLocation();
  const previousLocation = location.state?.previousLocation;
  const navigate = useNavigate();
  const [title, setTitle] = useState<string | null>(null);

  const { data: lesson } = useLessonFromParams();
  const { mutate: updateLesson, isPending: isUpdatePending } = useUpdateLessonAndLessonCollection({
    lessonId: lesson?.id,
    collectionId: lesson?.lesson_collection?.id,
  });

  function handleClose() {
    navigate(previousLocation || '..');
  }

  useLayoutEffect(() => {
    // Clean up common state on location change
    setTitle(null);
  }, [location.pathname]);

  return (
    // eslint-disable-next-line
    <CustomDialog open={true} onClose={handleClose}>
      <SettingsNavigation>
        <Stack gap='2px' component='nav' sx={{ typography: 'labelMedium', color: 'text.secondary', py: 1 }}>
          {settingsNavItems.map(({ label, path, icon }) => (
            <NavItemButton to={path} key={path} icon={icon} label={label} replace state={{ previousLocation }} dense />
          ))}
        </Stack>
      </SettingsNavigation>

      <SettingsMainPart>
        <Row justifyContent='space-between' sx={{ px: 4, boxShadow: t => `0 0 0 1px ${t.palette.divider}` }}>
          <TypographyWithEllipsis variant='titleSmall'>{title}</TypographyWithEllipsis>
          <IconButton onClick={handleClose} sx={{ transform: 'translateX(16px)' }}>
            <Close />
          </IconButton>
        </Row>
        <Box sx={{ flex: 1, overflowY: 'scroll', height: '100%', fontSize: '0.88em' }}>
          <Outlet context={{ setTitle, updateLesson, isUpdatePending } satisfies SettingsContextType} />
        </Box>
      </SettingsMainPart>
    </CustomDialog>
  );
}

type SettingsContextType = {
  setTitle: React.Dispatch<React.SetStateAction<string | null>>;
  updateLesson: (fields: Partial<LessonPayload>, opts?: { onSuccessCallback: () => void }) => void;
  isUpdatePending: boolean;
};

export const useLessonSettingsDialogContext = () => useOutletContext<SettingsContextType>();

/**
 * Sets the title of the settings dialog page
 */
export const useSettingsTitle = (title: string) => {
  const { setTitle } = useOutletContext<SettingsContextType>();

  useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);
};
