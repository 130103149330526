import React, { useState } from 'react';
import { Button, Stack, Link, TableRow, TableCell, Typography, TextField, Box } from '@mui/material';
import { Share, Delete, BugReport, Assignment, Check, PlayCircleOutline, MultipleStop } from '@mui/icons-material';
import { KyronMenu } from 'components/KyronMenu';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ErrorIndicator } from 'components/ErrorIndicator';
import {
  useWatchDraftLessonTasks,
  useWatchPublishedLessonTasks,
} from 'components/BackgroundTasks/useProgressBarChannel';
import { getLessonCollectionURL, getLTILink } from 'components/utils/urlUtils';
import { Lesson, LessonCollection } from 'controllers/types';
import { useDeleteLessonCollection } from 'controllers/react-query/lessonCollectionHooks';
import { useUserContext } from 'components/UserContext';
import { ShareDialog } from 'components/ShareDialog';
import { ClassroomSelector } from 'components/CatalogLessonCard/ClassroomSelector';
import { ReauthenticateDialog } from 'components/ReauthenticateDialog';
import { useQueryClient } from '@tanstack/react-query';
import { useModal } from 'components/utils/ModalContext';
import { enqueueSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { KyronMenuItem } from 'components/KyronMenuItem';
import { KyronIntercom } from '../../../utils/KyronIntercom';
import { Row } from '../../../Row/Row';
import { CopyButton } from '../../../CopyButton';
import { KyronEvents } from '../../../utils/KyronEvents';

export const useWatchLessonStatus = (draftLesson?: Lesson, publishedLesson?: Lesson) => {
  const lessonQueryClient = useQueryClient();
  const [watchingLessonIds, setWatchingLessonIds] = useState<number[]>([]);
  const { isInProgress, error, warning } = useWatchDraftLessonTasks(draftLesson);
  const { isInProgress: isTranslating, warning: translationWarning } = useWatchPublishedLessonTasks(publishedLesson);

  if (isInProgress && draftLesson && !watchingLessonIds.includes(draftLesson.id)) {
    setWatchingLessonIds(prev => [...prev, draftLesson.id]);
  }

  if (isTranslating && publishedLesson && !watchingLessonIds.includes(publishedLesson.id)) {
    setWatchingLessonIds(prev => [...prev, publishedLesson.id]);
  }

  if (!isInProgress && draftLesson && watchingLessonIds.includes(draftLesson.id)) {
    lessonQueryClient.invalidateQueries({ queryKey: ['/lessons'] });
    setWatchingLessonIds(prev => prev.filter(id => id !== draftLesson.id));
  }

  if (!isTranslating && publishedLesson && watchingLessonIds.includes(publishedLesson.id)) {
    lessonQueryClient.invalidateQueries({ queryKey: ['/lessons'] });
    setWatchingLessonIds(prev => prev.filter(id => id !== publishedLesson.id));
  }

  return {
    isInProgress: isInProgress || isTranslating,
    error,
    warning: warning || translationWarning,
  };
};

export function LibraryListItem({ lessonCollection }: { lessonCollection: LessonCollection }) {
  const { isPlatformAdmin, user } = useUserContext();

  // Studio library should have only one lesson variant in the lesson collection, so will always pick the first one.
  const draftLesson = lessonCollection.draft_lessons?.[0];
  const publishedLesson = lessonCollection.lessons?.find(lesson => lesson.locale === 'en');
  const hasPublishedLesson = !isEmpty(lessonCollection.lessons);
  const publishedLessonLink = hasPublishedLesson ? getLessonCollectionURL(lessonCollection.id) : '';
  const { isInProgress, error, warning } = useWatchLessonStatus(draftLesson, publishedLesson);
  const shouldDisableLessonLink = !hasPublishedLesson || isInProgress || Boolean(error);
  const isPrivateLesson = lessonCollection.visibility === 'PRIVATE';

  const [openShareDialog, setOpenShareDialog] = React.useState(false);
  const [openAssignDialog, setOpenAssignDialog] = React.useState(false);
  const [showReauthenticateDialog, setShowReauthenticateDialog] = React.useState(false);

  const { openModal, closeModal } = useModal();
  const { mutateAsync: deleteLessonCollection } = useDeleteLessonCollection();

  function openDeleteModal() {
    const handleDelete = () => {
      if (!lessonCollection)
        return console.error('Lesson deletion error: No lesson collection ID found when trying to delete.');
      deleteLessonCollection({ lessonCollectionId: lessonCollection.id })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          if (response.code === 0) {
            enqueueSnackbar(`Deleted Lesson ${lessonCollection.name}.`);
          } else if (response.code === 1) {
            enqueueSnackbar(`Warning: Active Lesson ${lessonCollection.name} deleted.`);
          }
        })
        .catch((err: Error) => {
          console.error(err);
          enqueueSnackbar(`Error deleting lesson ${lessonCollection.name}`, { variant: 'error' });
        });
      closeModal();
    };

    openModal({
      id: 'delete-lesson',
      title: 'Delete Lesson',
      content: 'Are you sure you want to delete this lesson?',
      action: (
        <Stack direction='row' spacing={2}>
          <Button variant='outlined' onClick={closeModal}>
            Cancel
          </Button>
          <Button color='error' onClick={handleDelete}>
            Delete
          </Button>
        </Stack>
      ),
    });
  }

  const handleLTILinkCopy = () => {
    const ltiLink = getLTILink(lessonCollection);
    openModal({
      id: 'get-lti-link',
      title: 'LTI Link',
      content: (
        <Stack spacing={2}>
          <Typography>
            Use this link to setup LTI connection to the lesson. Follow{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link component='button' onClick={() => KyronIntercom.showArticle('lti')} sx={{ verticalAlign: 'bottom' }}>
              Kyron LTI 1.3 documentation
            </Link>{' '}
            for more details.
          </Typography>
          <Row gap={1} justifyContent='space-between' width='100%'>
            <TextField
              variant='outlined'
              size='small'
              fullWidth
              defaultValue={ltiLink}
              inputProps={{ readOnly: true }}
            />
            <CopyButton
              value={ltiLink}
              label='Copy'
              onAnimationEnd={() =>
                KyronEvents.sendEvent('Copy LTI Link', {
                  lesson_collection_id: lessonCollection.id,
                  lesson_name: lessonCollection.name,
                  user_id: user?.id,
                })
              }
            />
          </Row>
        </Stack>
      ),
    });
  };

  const editPath = draftLesson?.use_df
    ? `/studio/internal_lesson_editor/${draftLesson.id}/edit`
    : `/studio/${draftLesson?.id}`;

  const visibility = hasPublishedLesson ? lessonCollection.visibility : 'DRAFT';

  const hasUnpublishedChanges = Boolean(draftLesson?.has_unpublished_changes);

  const menu = (
    <KyronMenu>
      <KyronMenuItem
        data-testid='lesson-start-button'
        icon={<PlayCircleOutline />}
        label='Go to published lesson'
        component={RouterLink}
        to={publishedLessonLink}
        target='_blank'
        disabled={shouldDisableLessonLink}
      />

      <KyronMenuItem
        data-testid='lesson-share-button'
        icon={<Share />}
        label='Share'
        onClick={() => setOpenShareDialog(true)}
        disabled={shouldDisableLessonLink || isPrivateLesson}
      />

      <KyronMenuItem
        data-testid='copy-lti-link-button'
        icon={<MultipleStop />}
        label='Get LTI Link'
        onClick={handleLTILinkCopy}
        disabled={shouldDisableLessonLink}
      />

      <KyronMenuItem
        data-testid='assign-lesson-button'
        icon={<Assignment />}
        label='Assign lesson'
        onClick={() => setOpenAssignDialog(true)}
        disabled={shouldDisableLessonLink}
      />

      {isPlatformAdmin && (
        <KyronMenuItem
          data-testid='lesson-edit-debug-button'
          icon={<BugReport />}
          label='Debug'
          component={RouterLink}
          to={`/studio/internal_lesson_editor/${draftLesson?.id}/edit`}
          disabled={isInProgress}
        />
      )}

      <KyronMenuItem
        data-testid='lesson-delete-button'
        icon={<Delete />}
        label='Delete lesson'
        onClick={openDeleteModal}
        sx={{ color: 'error.main' }}
        disabled={draftLesson?.use_df}
      />
    </KyronMenu>
  );

  const shareDialog = (
    <ShareDialog
      open={openShareDialog}
      onClose={() => setOpenShareDialog(false)}
      quote={`Check out this lesson I created on Kyron Learning, ${lessonCollection.name}!`}
      emailMessage={`I made a lesson on Kyron Learning called ${lessonCollection.name}, and I wanted to share it with you. Click the link to check it out!`}
      link={publishedLessonLink}
      eventResource={{ lesson_collection_id: lessonCollection.id, lesson_name: lessonCollection.name }}
    />
  );

  const assignDialog = hasPublishedLesson && (
    <>
      <ClassroomSelector
        open={openAssignDialog}
        close={() => setOpenAssignDialog(false)}
        lessonCollection={lessonCollection}
        reauthenticate={() => {
          setShowReauthenticateDialog(true);
          setOpenAssignDialog(false);
        }}
      />
      <ReauthenticateDialog open={showReauthenticateDialog} onClose={() => setShowReauthenticateDialog(false)} />
    </>
  );

  const getStatusIcon = () => {
    if (isInProgress) return <LoadingIndicator loading={isInProgress} />;
    if (error || warning) {
      const message = error || warning || undefined;
      return <ErrorIndicator error={new Error(message)} severity={error ? 'error' : 'warning'} />;
    }
    return null;
  };

  return (
    <>
      <TableRow>
        <TableCell width={56}>
          <Box sx={{ maxWidth: 56 }}>{getStatusIcon()}</Box>
        </TableCell>

        <TableCell sx={{ maxWidth: '240px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <Link
            component={RouterLink}
            to={editPath}
            sx={{ color: `${error ? 'error.main' : 'primary.main'}` }}
            data-testid='lesson-edit-link'
          >
            {lessonCollection.name}
          </Link>
        </TableCell>

        <TableCell width={96} sx={{ maxWidth: '96px' }}>
          {visibility}
        </TableCell>

        <TableCell width={180} sx={{ maxWidth: '180px' }}>
          {hasUnpublishedChanges && <Check />}
        </TableCell>

        <TableCell width={140} sx={{ maxWidth: '140px' }}>
          {dayjs(draftLesson?.updated_at).fromNow()}
        </TableCell>

        <TableCell width={72} sx={{ maxWidth: '72px' }}>
          {menu}
        </TableCell>
      </TableRow>

      {shareDialog}

      {assignDialog}
    </>
  );
}
