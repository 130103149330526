// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`RoleSelect renders as expected for current user 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  margin: 8px;
}

.emotion-1 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  width: 120px;
}

.emotion-1.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

label+.emotion-1 {
  margin-top: 16px;
}

.emotion-1::after {
  border-bottom: 2px solid #4B35FF;
  left: 0;
  bottom: 0;
  content: "";
  position: absolute;
  right: 0;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.emotion-1.Mui-focused:after {
  -webkit-transform: scaleX(1) translateX(0);
  -moz-transform: scaleX(1) translateX(0);
  -ms-transform: scaleX(1) translateX(0);
  transform: scaleX(1) translateX(0);
}

.emotion-1.Mui-error::before,
.emotion-1.Mui-error::after {
  border-bottom-color: #BA1A1A;
}

.emotion-1::before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  left: 0;
  bottom: 0;
  content: "\\00a0";
  position: absolute;
  right: 0;
  -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.emotion-1:hover:not(.Mui-disabled, .Mui-error):before {
  border-bottom: 2px solid #1C1B1F;
}

@media (hover: none) {
  .emotion-1:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }
}

.emotion-1.Mui-disabled:before {
  border-bottom-style: dotted;
}

.emotion-2 {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 0;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
}

.emotion-2:focus {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0;
}

.emotion-2::-ms-expand {
  display: none;
}

.emotion-2.Mui-disabled {
  cursor: default;
}

.emotion-2[multiple] {
  height: auto;
}

.emotion-2:not([multiple]) option,
.emotion-2:not([multiple]) optgroup {
  background-color: #FCFAFD;
}

.emotion-2.emotion-2.emotion-2 {
  padding-right: 24px;
  min-width: 16px;
}

.emotion-2.MuiSelect-select {
  height: auto;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.emotion-2::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-2::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-2:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-2::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-2:focus {
  outline: 0;
}

.emotion-2:invalid {
  box-shadow: none;
}

.emotion-2::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-2::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-2::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-2:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-2::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-2:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-2:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-2:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-2:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-2.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-2:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-3 {
  bottom: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  box-sizing: border-box;
}

.emotion-4 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  position: absolute;
  right: 0;
  top: calc(50% - .5em);
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
}

.emotion-4.Mui-disabled {
  color: #1C1B1F61;
}

<div
    class="MuiFormControl-root emotion-0"
  >
    <div
      class="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-colorPrimary Mui-disabled MuiInputBase-formControl  emotion-1"
      data-testid="role-select"
    >
      <div
        aria-controls=":r0:"
        aria-disabled="true"
        aria-expanded="false"
        aria-haspopup="listbox"
        aria-labelledby="role-select-label"
        class="MuiSelect-select MuiSelect-standard Mui-disabled MuiInputBase-input MuiInput-input Mui-disabled emotion-2"
        role="combobox"
      >
        Admin
      </div>
      <input
        aria-hidden="true"
        aria-invalid="false"
        class="MuiSelect-nativeInput emotion-3"
        disabled=""
        tabindex="-1"
        value="org_admin"
      />
      <svg
        aria-hidden="true"
        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconStandard Mui-disabled emotion-4"
        data-testid="ArrowDropDownIcon"
        focusable="false"
        viewBox="0 0 24 24"
      >
        <path
          d="M7 10l5 5 5-5z"
        />
      </svg>
    </div>
  </div>
</DocumentFragment>
`;

exports[`RoleSelect renders as expected for other users 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  margin: 8px;
}

.emotion-1 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  width: 120px;
}

.emotion-1.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

label+.emotion-1 {
  margin-top: 16px;
}

.emotion-1::after {
  border-bottom: 2px solid #4B35FF;
  left: 0;
  bottom: 0;
  content: "";
  position: absolute;
  right: 0;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.emotion-1.Mui-focused:after {
  -webkit-transform: scaleX(1) translateX(0);
  -moz-transform: scaleX(1) translateX(0);
  -ms-transform: scaleX(1) translateX(0);
  transform: scaleX(1) translateX(0);
}

.emotion-1.Mui-error::before,
.emotion-1.Mui-error::after {
  border-bottom-color: #BA1A1A;
}

.emotion-1::before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  left: 0;
  bottom: 0;
  content: "\\00a0";
  position: absolute;
  right: 0;
  -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.emotion-1:hover:not(.Mui-disabled, .Mui-error):before {
  border-bottom: 2px solid #1C1B1F;
}

@media (hover: none) {
  .emotion-1:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }
}

.emotion-1.Mui-disabled:before {
  border-bottom-style: dotted;
}

.emotion-2 {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 0;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
}

.emotion-2:focus {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0;
}

.emotion-2::-ms-expand {
  display: none;
}

.emotion-2.Mui-disabled {
  cursor: default;
}

.emotion-2[multiple] {
  height: auto;
}

.emotion-2:not([multiple]) option,
.emotion-2:not([multiple]) optgroup {
  background-color: #FCFAFD;
}

.emotion-2.emotion-2.emotion-2 {
  padding-right: 24px;
  min-width: 16px;
}

.emotion-2.MuiSelect-select {
  height: auto;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.emotion-2::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-2::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-2:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-2::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-2:focus {
  outline: 0;
}

.emotion-2:invalid {
  box-shadow: none;
}

.emotion-2::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-2::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-2::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-2:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-2::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-2:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-2:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-2:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-2:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-2.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-2:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-3 {
  bottom: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  box-sizing: border-box;
}

.emotion-4 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  position: absolute;
  right: 0;
  top: calc(50% - .5em);
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
}

.emotion-4.Mui-disabled {
  color: #1C1B1F61;
}

<div
    class="MuiFormControl-root emotion-0"
  >
    <div
      class="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-colorPrimary MuiInputBase-formControl  emotion-1"
      data-testid="role-select"
    >
      <div
        aria-controls=":r2:"
        aria-expanded="false"
        aria-haspopup="listbox"
        aria-labelledby="role-select-label"
        class="MuiSelect-select MuiSelect-standard MuiInputBase-input MuiInput-input emotion-2"
        role="combobox"
        tabindex="0"
      >
        Teacher
      </div>
      <input
        aria-hidden="true"
        aria-invalid="false"
        class="MuiSelect-nativeInput emotion-3"
        tabindex="-1"
        value="teacher"
      />
      <svg
        aria-hidden="true"
        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconStandard emotion-4"
        data-testid="ArrowDropDownIcon"
        focusable="false"
        viewBox="0 0 24 24"
      >
        <path
          d="M7 10l5 5 5-5z"
        />
      </svg>
    </div>
  </div>
</DocumentFragment>
`;
