import React from 'react';
import { Stack, Box } from '@mui/material';
import { Outlet, useParams, useOutletContext } from 'react-router-dom';
import { LessonSection, SegmentType } from 'controllers/types';
import { LessonsLoadingIndicator } from 'components/admin/LessonBuilder/LessonsLoadingIndicator';
import { useSnackbar } from 'notistack';
import { useUpdateSection } from 'controllers/react-query';
import { SectionListItem } from './SectionListItem';
import { AddSectionButton } from '../components/AddSectionButton';

export function SectionListEditor({ lessonSections }: { lessonSections?: LessonSection[] }) {
  const { segmentId } = useParams<{ segmentId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: updateSectionPosition, isPending } = useUpdateSection();

  const segmentsArr = lessonSections?.flatMap(section => section.segments);
  const currentSegment = segmentsArr && segmentsArr.find(({ id }) => id === Number(segmentId));
  const fallbackSegment = segmentsArr && segmentsArr[0]; // fallback to first segment
  // selectedSegment is the currently viewed segment, if cannot found, use first segment as fallback
  const selectedSegment = currentSegment || fallbackSegment;

  const selectedSection = selectedSegment
    ? lessonSections?.find(section => section.segments.includes(selectedSegment))
    : null;

  const reposition = (section: LessonSection, options: { direction: number }) => {
    const newPosition = section.position + options.direction;
    updateSectionPosition(
      {
        lessonSectionId: section.id,
        lessonId: section.lesson_id,
        payload: { lessonSection: { position: newPosition } },
      },
      {
        onError: error => {
          const errorTitle = `Failed to reposition section`;
          console.error(errorTitle, section.id, error.message);
          enqueueSnackbar(errorTitle, { variant: 'error' });
        },
      },
    );
  };

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} gap={8}>
      <Stack flex='0 0 280px' gap={4} position={{ xs: 'static', sm: 'sticky' }} top={64} height='100%'>
        {isPending ? (
          <LessonsLoadingIndicator />
        ) : (
          lessonSections?.map(section => (
            <SectionListItem
              key={section.id}
              isLast={section.position === lessonSections.length}
              reposition={reposition}
              section={section}
            />
          ))
        )}
        <Box>
          <AddSectionButton disabled={isPending} />
        </Box>
      </Stack>
      <Outlet
        key={segmentId}
        context={
          {
            segmentId: selectedSegment?.id || 0,
            segmentType: selectedSegment?.segment_type,
            topic: selectedSection?.topic,
            segmentCountInSection: selectedSection?.segments.length || 0,
          } satisfies SectionListEditorContext
        }
      />
    </Stack>
  );
}

type SectionListEditorContext = {
  segmentId: number | 0;
  segmentType: SegmentType | undefined;
  topic: string | undefined;
  segmentCountInSection: number;
};

export function useSectionListEditorContext() {
  return useOutletContext<SectionListEditorContext>();
}
