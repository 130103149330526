import { PaletteMode, ThemeOptions, createTheme } from '@mui/material';
import { kyronPaletteLight, kyronPaletteDark } from './kyronPalette';
import { kyronComponents } from './kyronComponents';
import { kyronTypography } from './kyronTypography';

export const getTheme = (mode?: PaletteMode) =>
  createTheme({
    components: kyronComponents,
    palette: mode === 'dark' ? kyronPaletteDark : kyronPaletteLight,
    typography: kyronTypography,
    shape: {
      borderRadius: 8,
    },
    shadows: [
      'none',
      '0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34), 0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34), 1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34)',
      '0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34), 1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34), 2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34), 6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34)',
      '0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34), 1.9px 1.9px 3px -0.4px rgba(162, 147, 169, 0.34), 3.5px 3.6px 5.6px -0.7px rgba(162, 147, 169, 0.34), 5.8px 5.9px 9.3px -1.1px rgba(162, 147, 169, 0.34), 9.2px 9.4px 14.8px -1.4px rgba(162, 147, 169, 0.34), 14.4px 14.6px 23.1px -1.8px rgba(162, 147, 169, 0.34), 21.9px 22.2px 35.1px -2.1px rgba(162, 147, 169, 0.34), 32.3px 32.8px 51.8px -2.5px rgba(162, 147, 169, 0.34)',
      ...Array(21).fill('none'),
    ] as ThemeOptions['shadows'],
  });
