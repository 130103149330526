import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Alert, AlertTitle, Box, Button, CircularProgress, Radio, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Download } from '@mui/icons-material';
import { LessonCollection, Pagination, QueryPaginationProps } from '../../controllers/types';
import { SearchField } from '../SearchField';
import { NoResources } from '../NoResources';
import { KyronImage } from '../KyronImage/KyronImage';
import { TypographyWithEllipsis } from '../TypographyWithEllipsis/TypographyWithEllipsis';
import { useRemoveIntercom } from '../utils/KyronIntercom';
import { useGetDeepLinkResponse } from '../../controllers/react-query/ltiPlatformHooks';
import { useKyronInfiniteQuery } from '../../controllers/react-query/kyronQuery';
import { LoadingSkeletonItem } from '../LessonAssignmentList/utils';
import { DEFAULT_HEIGHT as ROUNDED_TEXT_FIELD_HEIGHT } from '../RoundedTextField';

/**
 * Given the following params, fetches all the lessons that are in the organization that this LTI platform belongs to.
 */
const useLtiPlatformLessons = ({
  platformId,
  ltiLaunch,
  perPage = 100,
  searchText,
}: {
  platformId?: string;
  ltiLaunch?: string | null;
  searchText?: string;
} & QueryPaginationProps) => {
  type TResponse = { lesson_collections: LessonCollection[]; meta: Pagination };
  const searchQueryStr = searchText ? `&q=${searchText}` : '';

  return useKyronInfiniteQuery<TResponse>(
    `/lti_platforms/${platformId}/lessons?lti_launch=${ltiLaunch}&per_page=${perPage}${searchQueryStr}`,
    {
      queryKey: [`/lti_platform/${platformId}/lessons`, ltiLaunch, perPage, searchQueryStr],
      refetchOnWindowFocus: false,
    },
  );
};

const LAYOUT_PADDING = 24;
const FOOTER_HEIGHT = 72;
const HEADER_HEIGHT = 72;
const SEARCH_HEIGHT = ROUNDED_TEXT_FIELD_HEIGHT;
const SEARCH_HEIGHT_ERROR = 72;
const LIST_CONTENT_WIDTH = '94%';
const LIST_CONTENT_ML = '32px'; // margin-left for the list content (pushes the radio button to the right)

const Layout = styled(Box)`
  width: 100vw;
  height: 100vh;
  padding: ${LAYOUT_PADDING}px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Footer = styled(Box)`
  width: 100vw;
  background-color: ${({ theme }) => theme.palette.surface.main};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: ${FOOTER_HEIGHT}px;
`;

/**
 * Grid layout that will reshape depending on the noResource state
 */
type CustomProps = { noResource: boolean; isError: boolean };
const customProps = ['noResource', 'isError'];
const Grid = styled(Box, { shouldForwardProp: (p: string) => !customProps.includes(p) })<CustomProps>`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: ${p => {
    // Show header, search, error (if any) and no resource message
    if (p.noResource) return `${HEADER_HEIGHT}px ${SEARCH_HEIGHT_ERROR}px max-content auto`; // respectively: header, error, no resource message
    // Show header, search, error and the existing lesson list
    if (!p.noResource && p.isError) return `${HEADER_HEIGHT}px ${SEARCH_HEIGHT_ERROR}px max-content auto`; // respectively: header, search, error, lesson list
    // Show header, search and the existing lesson list
    return `${HEADER_HEIGHT}px ${SEARCH_HEIGHT}px auto`; // respectively: header, search, lesson list, buttons
  }};
  max-width: 800px;
  max-height: calc(100vh - ${LAYOUT_PADDING + FOOTER_HEIGHT}px);
`;

export const LtiPlatformLessonsIframeView = () => {
  useRemoveIntercom();
  const { enqueueSnackbar } = useSnackbar();

  const [searchParam] = useSearchParams();
  const ltiLaunch = searchParam.get('lti_launch');
  const [searchText, setSearchText] = useState('');
  const [selectedLessonCollection, setSelectedLessonCollection] = useState<LessonCollection | null>(null);
  const { platformId } = useParams();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [deepLinkResponse, setDeepLinkResponse] = useState({ jwt: '', redirect_url: '' });
  const { mutateAsync: getDeepLinkResponse } = useGetDeepLinkResponse();
  const { data, isFetching, isRefetching, isError, error, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useLtiPlatformLessons({ platformId, ltiLaunch, perPage: 10, searchText });
  const noLessonsFound = !isFetching && (!data?.pages?.length || !data?.pages?.[0]?.lesson_collections?.length);

  /**
   * This will call our server with the selected lesson collection id to get the deep link response (jwt) and the
   * redirect url to send the user back to the LTI platform. Then we will use those to submit the hidden form below.
   * Submission will be done by the side effect that calls handleRedirection.
   */
  const prepareDeepLinkResponse = (action: 'for_submission' | 'for_cancel') => () => {
    if (!platformId || !ltiLaunch) {
      return enqueueSnackbar('Something went wrong, see logs', { variant: 'error' });
    }

    let request: ReturnType<typeof getDeepLinkResponse> | null = null;

    if (selectedLessonCollection && action === 'for_submission') {
      request = getDeepLinkResponse({
        platformId,
        ltiLaunch,
        payload: { lessonCollectionIds: [selectedLessonCollection.id] },
      });
    }

    if (action === 'for_cancel') {
      // As an LTI tool we are responsible to provide a proper cancellation flow for the deep linking request.
      // That should redirect theUI as if it would redirect with a selected lesson but with an empty selection array.
      // We achieve this by receiving a cancel_process param from the UI. We could do this by just sending an empty array.
      // However, that creates an implicit contract that might be overlooked or be confusing in the future.
      request = getDeepLinkResponse({ platformId, ltiLaunch, cancelProcess: true });
    }

    if (!request) throw new Error('Request is not initialized properly');
    request
      .then(resp => {
        setDeepLinkResponse(resp);
      })
      .catch(err => {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
        console.error(err);
      });
  };

  // This effect will submit the form when the deep link response is ready
  useEffect(handleRedirection, [deepLinkResponse.jwt, deepLinkResponse.redirect_url]);
  function handleRedirection() {
    if (deepLinkResponse.jwt && deepLinkResponse.redirect_url) {
      formRef.current?.submit();
    }
  }

  const renderLoadMore = () => {
    // TODO(ege): Support this with automatic loader to fetch more lessons using react-infinite-scroll-component

    // is initial data fetch? then don't show this button
    if (isFetching && !isFetchingNextPage) return null;

    let buttonIcon: JSX.Element | null = <Download />;
    let buttonText = 'Load More Lessons';

    if (isFetchingNextPage) {
      buttonIcon = <CircularProgress size={20} />;
      buttonText = 'Loading more...';
    }

    if (!hasNextPage && !isFetching) {
      buttonIcon = null;
      buttonText = 'End of the lesson list';
    }

    return (
      <Button disabled={!hasNextPage} startIcon={buttonIcon} variant='text' onClick={() => fetchNextPage()}>
        {buttonText}
      </Button>
    );
  };

  return (
    <Layout>
      <Grid noResource={noLessonsFound} isError={isError}>
        <Stack>
          <Typography variant='headlineMedium'>Select a Lesson</Typography>
          <Typography>All the lessons that are available in your Kyron Learning organization.</Typography>
        </Stack>

        <SearchField
          dataTestID='search-field'
          color={isError ? 'error' : 'primary'}
          placeholder='Search for lessons'
          onSearch={setSearchText}
          isLoading={isFetching}
          searchError={isError ? 'Failed when searching for lessons' : null}
        />

        {isError && (
          <Alert severity='error'>
            <AlertTitle>An error occurred while fetching lessons</AlertTitle>
            {error.message}
          </Alert>
        )}

        {/* LESSON LIST */}
        <Stack spacing={1} sx={{ py: 2, overflow: 'scroll', borderRadius: 1 }}>
          <LoadingSkeletonItem count={isFetching && !isRefetching ? 5 : 0} listContentWidth={LIST_CONTENT_WIDTH} />
          {noLessonsFound && <NoResources message='No lessons found' />}
          {data?.pages.map(lessonCollectionPage =>
            lessonCollectionPage.lesson_collections?.map(lessonCollection => (
              <Box
                key={lessonCollection.slug}
                sx={{ display: 'grid', placeItems: 'center', gridTemplateColumns: '40px 1fr', gap: 1 }}
              >
                <Radio
                  name={lessonCollection.id?.toString()}
                  data-testid={`radio-button-${lessonCollection.id}`}
                  sx={{ ml: LIST_CONTENT_ML }}
                  checked={lessonCollection.id === selectedLessonCollection?.id}
                  onClick={() =>
                    setSelectedLessonCollection(p => (p?.id === lessonCollection.id ? null : lessonCollection))
                  }
                />
                <Box sx={{ width: LIST_CONTENT_WIDTH, bgcolor: 'surfaceContainer.main', borderRadius: 1 }}>
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      padding: '12px',
                      display: 'grid',
                      gridTemplateColumns: '80px 1fr',
                      gridTemplateRows: '100%',
                      gap: 2,
                    }}
                  >
                    <KyronImage
                      size={80}
                      objectFit='cover'
                      src={lessonCollection.collection_thumbnail}
                      alt={`collection-thumbnail-${lessonCollection.name}`}
                      dataTestID='collection-thumbnail'
                    />
                    <Stack spacing={0.5}>
                      <TypographyWithEllipsis variant='titleMedium' lineCount={2}>
                        {lessonCollection.name}
                      </TypographyWithEllipsis>
                      <TypographyWithEllipsis variant='bodySmall' lineCount={2}>
                        {lessonCollection.description}
                      </TypographyWithEllipsis>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            )),
          )}
          <Stack sx={{ width: '100%', alignItems: 'center', justifyContent: 'center', minHeight: 40 }}>
            {renderLoadMore()}
          </Stack>
        </Stack>
      </Grid>
      {noLessonsFound ? null : (
        <Footer data-testid='footer'>
          <Button variant='outlined' onClick={prepareDeepLinkResponse('for_cancel')}>
            Cancel
          </Button>
          <Button disabled={!selectedLessonCollection} onClick={prepareDeepLinkResponse('for_submission')}>
            Submit Selection
          </Button>
        </Footer>
      )}

      {/**
       * What's this form for?
       * This is a hidden for to submit when lesson selection is submitted. This is the only correct way of redirecting
       * user back to the platform. Because, the LTI platform expect the user agent (UI) to be redirect by the result of
       * a form submission, expecting JWT to be a form data (field) in the submitted form. Any other way of redirection
       * will result in a CORS error.
       */}
      <form
        id='form'
        method='post'
        action={`${deepLinkResponse.redirect_url}`}
        style={{ display: 'none' }}
        ref={formRef} // Will be used to manually submit the form
      >
        {/* The LTI platform expect to find the payload in a form data with name JWT (all uppercase) */}
        <input id='id_jwt' name='JWT' required defaultValue={deepLinkResponse.jwt} />
      </form>
    </Layout>
  );
};
