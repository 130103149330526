import { Button, styled } from '@mui/material';

export const OrganizationSelectButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.surfaceContainer.main,
  borderRadius: 4,
  color: theme.palette.text.secondary,
  height: 32,
  justifyContent: 'space-between',
  minWidth: 0,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  typography: theme.typography.labelMedium,
  '&:hover': { backgroundColor: theme.palette.surfaceContainer.dark },
})) as typeof Button;
