import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { LessonCollection } from 'controllers/types';

dayjs.extend(duration);

export function getLessonCollectionDuration(lessonCollection: LessonCollection) {
  const seconds = lessonCollection.duration || 0;

  const durationFromSeconds = dayjs.duration(seconds, 'seconds');
  if (durationFromSeconds.hours() > 0) {
    return `${durationFromSeconds.hours()} hr`;
  }
  if (durationFromSeconds.minutes() > 5) {
    return `${Math.round(durationFromSeconds.minutes() / 5) * 5} min`;
  }
  if (durationFromSeconds.minutes() > 1) {
    return `${durationFromSeconds.minutes()} min`;
  }
  return '1 min';
}
