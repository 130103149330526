import React, { useState } from 'react';
import { Dialog, Stack, Button, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useGenerateEntireLesson } from 'controllers/react-query';
import { GenerateQuestionsPayload } from 'controllers/react-query/lessonGenerationHooks';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { KyronEvents } from 'components/utils/KyronEvents';
import { useUserContext } from 'components/UserContext';
import { CreateLessonForm } from './CreateLessonForm';

type CreateLessonDialogProps = {
  onClose: () => void;
  dialogTitle: string;
  payload: Partial<GenerateQuestionsPayload>;
};

export function CreateLessonDialog({ onClose, dialogTitle, payload }: CreateLessonDialogProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isPending: isCreatingLesson, mutate: createLesson } = useGenerateEntireLesson();

  const [createLessonPayload, setCreateLessonPayload] = useState<GenerateQuestionsPayload>({
    lessonTitle: '',
    lessonObjective: '',
    audience: '',
    signedMaterialIds: [],
    questionType: null,
    activityTemplate: 'lesson',
    ttsVoiceId: 'alloy',
    ...payload,
  });

  const { user } = useUserContext();
  function handleCreateLesson() {
    createLesson(
      { payload: createLessonPayload },
      {
        onSuccess: lesson => {
          navigate(`/studio/${lesson.id}`);
          enqueueSnackbar(`Your lesson ${lesson.name} is being created. This may take a few minutes.`, {
            variant: 'success',
          });
          KyronEvents.sendEvent('Clicked Create Lesson', {
            lesson_title: createLessonPayload.lessonTitle,
            lesson_id: lesson.id,
            user_id: user?.id || 'anonymous',
          });
        },
        onError: error => {
          enqueueSnackbar(`Failed to create lesson ${createLessonPayload.lessonTitle}`, { variant: 'error' });
          console.error(error.message);
        },
      },
    );
  }

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth='md'>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <CreateLessonForm
          promptParams={createLessonPayload}
          setPromptParams={setCreateLessonPayload}
          shouldHideLuckyButton
        />
      </DialogContent>
      <DialogActions>
        <Stack direction='row' justifyContent='space-between'>
          <Button variant='text' onClick={onClose} aria-label='close'>
            Cancel
          </Button>
          <Button
            aria-label='Create Lesson'
            startIcon={isCreatingLesson ? <LoadingIndicator spinnerSize={20} /> : null}
            onClick={handleCreateLesson}
            disabled={
              !createLessonPayload.audience ||
              !createLessonPayload.lessonObjective ||
              !createLessonPayload.lessonTitle ||
              isCreatingLesson
            }
          >
            Create Lesson
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
