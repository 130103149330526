import { Tooltip, TooltipProps } from '@mui/material';
import React from 'react';

type Props = { inactive?: boolean; children: React.ReactNode } & TooltipProps;

/**
 * A wrapper around MUI Tooltip that allows inactivating the tooltip.
 * @param inactive - If true, the tooltip will not be displayed.
 */
export function KyronTooltip({ inactive, children, ...props }: Props) {
  return inactive ? children : <Tooltip {...props}>{children}</Tooltip>;
}
