import React, { forwardRef } from 'react';
import { Box, Stack } from '@mui/material';
import { AnimatedThreeDots } from 'components/common/AnimatedThreeDots';
import { ConversationRole } from 'enums/conversationRole';
import { ConversationItem } from 'controllers/types';
import { ConversationChatAvatar } from './ConversationChatAvatar';
import { ChatFeedback } from '../ChatFeedback/ChatFeedback';

type ConversationTurnProps = {
  turn: ConversationItem;
  isLastTurn?: boolean;
  chatId?: number;
  replaceLastMessage?: (text: string) => void;
  question?: string | null;
  conversation?: Array<ConversationItem>;
  isAssistantResponding?: boolean;
};

export const ConversationTurn = forwardRef<HTMLDivElement, ConversationTurnProps>(
  ({ turn, isLastTurn = false, chatId, replaceLastMessage, question, conversation, isAssistantResponding }, ref) => {
    const turnRoleIsAi = turn.role === ConversationRole.AI;
    const waitingForAiResponse = isLastTurn && turnRoleIsAi && turn.text === '' && isAssistantResponding;

    return (
      <Stack ref={ref} gap={1} sx={{ alignItems: 'flex-start', flexDirection: turnRoleIsAi ? 'row' : 'row-reverse' }}>
        <ConversationChatAvatar isAi={turnRoleIsAi} />

        {waitingForAiResponse ? (
          <AnimatedThreeDots size={12} />
        ) : (
          <Stack sx={{ maxWidth: 'calc(100% - 120px)' }} gap={0.5}>
            <Box
              data-testid={turnRoleIsAi ? 'ai-message' : 'student-message'}
              sx={{
                p: '4px 16px',
                borderRadius: 2,
                bgcolor: turnRoleIsAi ? 'surface.main' : 'primary.main',
                color: turnRoleIsAi ? 'surface.contrastText' : 'primary.contrastText',
              }}
            >
              {turn.text}
            </Box>

            {isLastTurn && turnRoleIsAi && chatId && replaceLastMessage && conversation && question && (
              <Box>
                <ChatFeedback
                  aiText={turn.text}
                  chatId={chatId}
                  rejectedMessage={turn.text}
                  replaceLastMessage={replaceLastMessage}
                  conversation={conversation}
                  question={question}
                />
              </Box>
            )}
          </Stack>
        )}
      </Stack>
    );
  },
);
