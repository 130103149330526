import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { useUpdateSegment } from 'controllers/react-query';
import { LessonSegment } from 'controllers/types';
import { SegmentMultipleChoice } from '../components/SegmentMultipleChoice';
import { SegmentField } from '../components/SegmentField';
import { isUsingSegmentDescription, getTooltipNote } from '../helpers';

type SegmentFieldsProps = {
  segment: LessonSegment;
  isLoading?: boolean;
};

export const StepSegmentFields = ({ segment, isLoading = false }: SegmentFieldsProps) => {
  const updateSegmentMutation = useUpdateSegment({ segmentId: segment.id });
  const isParentSegmentInProgress = isLoading || updateSegmentMutation.isPending;

  const [description, setDescription] = useState(segment.description);
  const mediaType = segment.media_type;

  async function handleSaveDescription(param: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = param.target.value.trim();
    if (!value || value === segment.description) return;
    await updateSegmentMutation.mutateAsync({
      lessonId: segment.lesson_id,
      payload: { ...segment, description: value },
    });
  }

  return (
    <SegmentField
      title='Video Transcript'
      info=''
      value={description}
      onChange={e => setDescription(e.target.value)}
      onBlur={handleSaveDescription}
      disabled={isParentSegmentInProgress || !isUsingSegmentDescription(mediaType)}
      tooltipNote={getTooltipNote(mediaType, isParentSegmentInProgress, true)}
    />
  );
};

export const WaitSegmentFields = ({ segment, isLoading = false }: SegmentFieldsProps) => {
  const updateSegmentMutation = useUpdateSegment({ segmentId: segment.id });
  const isParentSegmentInProgress = isLoading || updateSegmentMutation.isPending;

  const [question, setQuestion] = useState(segment.question);
  const [answer, setAnswer] = useState(segment.answer);

  const mediaType = segment.media_type;
  const isMultipleChoice = Boolean(segment?.multiplechoices?.length);

  async function handleSaveQuestion(param: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = param.target.value.trim();
    if (!value || value === segment.question) return;
    await updateSegmentMutation.mutateAsync({
      lessonId: segment.lesson_id,
      payload: { ...segment, question: value },
    });
  }

  function handleSaveAnswer(param: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = param.target.value.trim();
    if (!value || value === segment.answer) return;
    updateSegmentMutation.mutate({ lessonId: segment.lesson_id, payload: { ...segment, answer: value } });
  }

  return (
    <Stack gap={2}>
      <SegmentField
        title='Question'
        info=''
        value={question}
        onChange={e => setQuestion(e.target.value)}
        onBlur={handleSaveQuestion}
        disabled={isParentSegmentInProgress}
        tooltipNote={getTooltipNote(mediaType, isParentSegmentInProgress, true)}
      />

      {isMultipleChoice ? (
        <SegmentMultipleChoice waitSegment={segment} />
      ) : (
        <SegmentField
          title='Answer'
          info=''
          value={answer}
          onChange={e => setAnswer(e.target.value)}
          onBlur={handleSaveAnswer}
          disabled={isParentSegmentInProgress}
          tooltipNote={getTooltipNote(mediaType, isParentSegmentInProgress, true)}
        />
      )}
    </Stack>
  );
};
