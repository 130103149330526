import React, { useRef, useState, FormEvent } from 'react';
import { Box, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { isEmpty } from 'lodash';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import {
  EquationMultipleChoiceOption,
  LessonSegment,
  MultipleChoiceOption,
  NextLessonSegmentForm,
  NextLessonSegmentResponse,
} from 'controllers/types';
import debounce from 'lodash/debounce';
import { SimpleEquation } from '../Equation';
import { AudioRecorder } from './StudentInputs/AudioRecorder';
import { MultipleChoice } from './StudentInputs/MultipleChoice';
import SubmitIcon from '../../assets/submit_arrow.svg';
import { EquationMultipleChoice } from './StudentInputs/EquationMultipleChoice';
import { KyronEvents } from '../utils/KyronEvents';
import { TextInput } from './StudentInputs/TextInput';
import { usePanelAreaContext } from './PanelAreaContext';
import { StudentChat } from './StudentChat/StudentChat';

const DEFAULT_MULTIPLE_CHOICES = ['A', 'B', 'C', 'D', "I don't know"];
function generateDefaultMultipleChoices(): MultipleChoiceOption[] {
  return DEFAULT_MULTIPLE_CHOICES.map(
    (choice, index) =>
      ({
        key: index,
        label: choice,
      }) as MultipleChoiceOption,
  );
}

function generateDefaultEquationMultipleChoices(): EquationMultipleChoiceOption[] {
  return DEFAULT_MULTIPLE_CHOICES.map(
    (choice, index) =>
      ({
        key: index + 1,
        value: [
          {
            displayType: 'text',
            value: choice,
          },
        ],
      }) as EquationMultipleChoiceOption,
  );
}

const SimpleFractionWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '25px',
});

const QUESTION_INPUT_TEXT_SELECTED_KEY = 'question-input__text-selected';

type QuestionAndStudentInputsProps = {
  cancelWaitTimeout: () => void;
  chatId: number;
  handleNextLessonSegment: (
    lessonInstanceForm: NextLessonSegmentForm,
  ) => Promise<NextLessonSegmentResponse | undefined>;
  lessonSegment: LessonSegment;
  segmentInstanceId: number;
  sessionId: number;
};

type SimpleEquationRef = React.ElementRef<typeof SimpleEquation>;

export function QuestionAndStudentInputs({
  lessonSegment,
  handleNextLessonSegment,
  chatId,
  sessionId,
  segmentInstanceId,
  cancelWaitTimeout,
}: QuestionAndStudentInputsProps) {
  const fractionRef = useRef<SimpleEquationRef>(null);
  const [previousMCSelections, setPreviousMCSelections] = useState({});
  const { panelAreaOpen } = usePanelAreaContext();
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  // USER PREFERENCE STATES -- should be in sync with session storage
  const [hasUserSelectedTextInStorage, setHasUserSelectedTextInStorage] = useState(
    sessionStorage.getItem(QUESTION_INPUT_TEXT_SELECTED_KEY) === 'true' || false,
  );
  const [noInputRetry, setNoInputRetry] = useState<number>();
  // END USER PREFERENCE STATES -------------------------------------

  if (!lessonSegment) {
    return null;
  }

  const debouncedMultipleChoice = debounce(
    selection => {
      KyronEvents.sendEvent('Multiplechoice Selection', {
        session_id: sessionId,
        segment_id: lessonSegment.id,
        response: selection,
      });
      cancelWaitTimeout();

      const intentInstance = {
        lesson_segment_instance_id: segmentInstanceId,
        session_id: sessionId,
        multiple_choice: selection,
      };
      const newMCSelection = { [`${lessonSegment.id}-${selection}`]: true };
      setPreviousMCSelections(prev => ({ ...prev, ...newMCSelection }));
      return handleNextLessonSegment(intentInstance);
    },
    500,
    { leading: true, trailing: false },
  );

  const debouncedEquationMultipleChoice = debounce(
    equationMultipleChoice => {
      KyronEvents.sendEvent('Equation Multiplechoice Selection', {
        session_id: sessionId,
        segment_id: lessonSegment.id,
        response: equationMultipleChoice,
      });
      cancelWaitTimeout();

      const intentInstance = {
        lesson_segment_instance_id: segmentInstanceId,
        session_id: sessionId,
        equation_multiple_choice: equationMultipleChoice,
      };
      return handleNextLessonSegment(intentInstance);
    },
    500,
    { leading: true, trailing: false },
  );

  const debouncedStudentUtterance = debounce(
    async audio => {
      KyronEvents.sendEvent('Audio Utterance', {
        session_id: sessionId,
        segment_id: lessonSegment.id,
        response: 'no transcript',
      });
      cancelWaitTimeout();

      const intentInstance: NextLessonSegmentForm = {
        lesson_segment_instance_id: segmentInstanceId,
        session_id: sessionId,
        audio,
        no_input_retry: noInputRetry,
      };
      const response = await handleNextLessonSegment(intentInstance);
      setNoInputRetry(response?.no_input_retry);
    },
    500,
    { leading: true, trailing: false },
  );

  const debouncedStudentText = debounce(
    text => {
      KyronEvents.sendEvent('Text Input', {
        session_id: sessionId,
        segment_id: lessonSegment.id,
        response: text,
      });
      cancelWaitTimeout();

      const intentInstance = {
        lesson_segment_instance_id: segmentInstanceId,
        session_id: sessionId,
        text,
      };
      return handleNextLessonSegment(intentInstance);
    },
    500,
    { leading: true, trailing: false },
  );

  const debouncedEquation = debounce(
    equation => {
      KyronEvents.sendEvent('Equation Input', {
        session_id: sessionId,
        segment_id: lessonSegment.id,
        response: equation,
      });
      cancelWaitTimeout();

      const intentInstance = {
        lesson_segment_instance_id: segmentInstanceId,
        session_id: sessionId,
        equation,
      };
      return handleNextLessonSegment(intentInstance);
    },
    500,
    { leading: true, trailing: false },
  );

  function handleFinishChat(chatStatus: string) {
    KyronEvents.sendEvent('OpenEnded Completed', {
      session_id: sessionId,
      segment_id: lessonSegment.id,
      chat_id: chatId,
    });
    cancelWaitTimeout();

    const intentInstance = {
      lesson_segment_instance_id: segmentInstanceId,
      session_id: sessionId,
      open_ended: chatStatus,
    };
    return handleNextLessonSegment(intentInstance);
  }

  const handleSelectedChange = () => {
    const newValue = !hasUserSelectedTextInStorage;
    setHasUserSelectedTextInStorage(newValue);
    // this is a user preference we wanna keep in session storage
    sessionStorage.setItem(QUESTION_INPUT_TEXT_SELECTED_KEY, newValue.toString());
  };

  const renderQuestion = () => {
    if (!lessonSegment.question && !lessonSegment.question_json) {
      return null;
    }

    if (lessonSegment.segment_type !== 'wait') {
      return null;
    }

    // The open ended component contains the question in it, so don't render it here.
    if (lessonSegment.acceptable_inputs?.includes('open_ended')) {
      return null;
    }

    return (
      <Box sx={{ px: 2, py: 1, bgcolor: 'surface.main', borderRadius: 2 }}>
        {!lessonSegment.question_json || isEmpty(lessonSegment.question_json) ? (
          <Typography>{lessonSegment.question}</Typography>
        ) : (
          <Box>
            <SimpleEquation size='small' data={lessonSegment.question_json} ref={fractionRef} />
          </Box>
        )}
      </Box>
    );
  };

  const renderInputs = () => {
    const hasAudio = lessonSegment.acceptable_inputs?.includes('audio');
    const hasText = lessonSegment.acceptable_inputs?.includes('text');

    if (!hasAudio && !hasText) {
      return null;
    }

    return (
      <Stack direction='row' justifyContent='center' spacing={2} onClick={e => e.stopPropagation()}>
        {hasAudio && (
          <AudioRecorder textSelected={hasUserSelectedTextInStorage} handleUtterance={debouncedStudentUtterance} />
        )}
        {hasText && (
          <TextInput
            textSelected={hasUserSelectedTextInStorage}
            handleSelectedChange={handleSelectedChange}
            handleText={debouncedStudentText}
          />
        )}
      </Stack>
    );
  };

  const renderMultipleChoice = () => {
    if (!lessonSegment.acceptable_inputs?.includes('multiple_choice')) {
      return null;
    }

    let choices = lessonSegment.multiplechoices;
    if (isEmpty(choices)) {
      choices = generateDefaultMultipleChoices();
    }

    return (
      <Box onClick={e => e.stopPropagation()}>
        <MultipleChoice
          handleMultipleChoice={debouncedMultipleChoice}
          multipleChoiceOptions={choices}
          prevSelected={previousMCSelections}
          segmentId={lessonSegment.id}
        />
      </Box>
    );
  };

  const renderEquations = () => {
    if (!lessonSegment.acceptable_inputs?.includes('equation')) {
      return null;
    }

    const equationDisplayInput = lessonSegment.equation_display_input;
    if (!equationDisplayInput || isEmpty(equationDisplayInput)) {
      return null;
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.stopPropagation();
      // Checking to see if the SimpleEquation form has all the fields filled out.
      const isValidFraction = e.currentTarget?.parentNode?.querySelector('form')?.reportValidity();
      if (isValidFraction && fractionRef.current) {
        debouncedEquation(fractionRef.current.getData());
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <SimpleFractionWrapper>
          <SimpleEquation data={equationDisplayInput} ref={fractionRef} />
          <IconButton
            data-testid='student-fractions-input-button'
            aria-label='submit fractions input'
            type='submit'
            size='large'
          >
            <img alt='Submit' src={SubmitIcon} style={{ width: '30px', height: 'auto' }} />
          </IconButton>
        </SimpleFractionWrapper>
      </form>
    );
  };

  const renderEquationMultipleChoice = () => {
    if (!lessonSegment.acceptable_inputs?.includes('equation_multiple_choice')) {
      return null;
    }

    let choices = lessonSegment.equation_multiple_choices;
    if (isEmpty(choices)) {
      choices = generateDefaultEquationMultipleChoices();
    }

    return (
      <EquationMultipleChoice
        equationMultipleChoiceOptions={choices}
        handleEquationMultipleChoice={debouncedEquationMultipleChoice}
      />
    );
  };

  function getPadding() {
    if (isMobile) {
      return 1;
    }

    if (panelAreaOpen) {
      return 2;
    }

    return 20;
  }

  return (
    <Box
      data-testid='interaction-area'
      sx={{
        maxWidth: '1280px',
        margin: '0 auto',
        paddingBottom: isMobile ? '8px' : '36px',
        height: '100%',
        transition: 'padding 0.225s ease-in-out',
        px: getPadding(),
        overflowY: 'auto',
      }}
    >
      {lessonSegment.acceptable_inputs?.includes('open_ended') ? (
        <Stack gap={2} justifyContent='end' height='100%'>
          <StudentChat chatId={chatId} finishChat={handleFinishChat} question={lessonSegment.question} />
        </Stack>
      ) : (
        <Stack gap={2} justifyContent='end' minHeight='100%'>
          {/* inputs */}
          {renderQuestion()}
          {renderInputs()}
          {renderEquations()}
          {renderEquationMultipleChoice()}
          {renderMultipleChoice()}
        </Stack>
      )}
    </Box>
  );
}
