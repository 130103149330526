import React from 'react';
import { Stack, TextField, Typography, Select, MenuItem } from '@mui/material';
import { GenerateQuestionsPayload } from 'controllers/react-query/lessonGenerationHooks';
import { Row } from 'components/Row/Row';
import { useFeatures } from 'components/FeaturesContext';
import { capitalize } from 'lodash';
import { ActiveStorageBlob } from '../../Uploaders/types';
import { ACCEPT_ALL_TEXT_FILES, FileUploader, UploadButton } from '../../Uploaders/FileUploader';
import { FileList } from '../../Uploaders/FileList';
import { FeelingLuckyButton } from '../../Studio/components/FeelingLuckyButton';
import OpenBook from '../../../assets/open-book.svg';
import Test from '../../../assets/test.svg';

type Props = {
  promptParams: GenerateQuestionsPayload;
  setPromptParams: React.Dispatch<React.SetStateAction<GenerateQuestionsPayload>>;
  shouldShowAdditionalMaterialUpload?: boolean;
  shouldHideLuckyButton?: boolean;
};

export function CreateLessonForm({
  promptParams,
  setPromptParams,
  shouldShowAdditionalMaterialUpload = false,
  shouldHideLuckyButton = false,
}: Props) {
  const { show_template_dropdown: showTemplateDropdown } = useFeatures();

  const handleAdditionalMaterialRemove = (signedIdToRemove: string) => {
    setPromptParams(prevParams => ({
      ...prevParams,
      signedMaterialIds: prevParams.signedMaterialIds.filter(signedId => signedId !== signedIdToRemove),
    }));
  };

  const handleAdditionalMaterialAttach = (blobs: ActiveStorageBlob[]) => {
    // create an array of signed_ids from the blobs, skip if signed_id is not present
    const newMaterialIds = blobs.reduce(
      (acc, curr) => (curr?.signed_id ? [...acc, curr.signed_id] : acc),
      [] as string[],
    );
    if (!newMaterialIds.length) return;
    // new materials IDs should be appended to the existing ones
    setPromptParams(p => ({ ...p, signedMaterialIds: [...p.signedMaterialIds, ...newMaterialIds] }));
  };

  return (
    <Stack sx={{ gap: 3, position: 'relative' }}>
      {!shouldHideLuckyButton && (
        <Row sx={{ justifyContent: 'center' }}>
          <FeelingLuckyButton setPromptParams={setPromptParams} />
        </Row>
      )}
      {showTemplateDropdown && (
        <Stack spacing='2px'>
          <Typography variant='labelLarge' component='label' htmlFor='activity-template'>
            Activity Template
          </Typography>
          <Typography variant='bodyMedium' color='text.secondary'>
            What type of activity would you like to create?
          </Typography>
          <Select
            value={promptParams.activityTemplate}
            onChange={e =>
              setPromptParams(prevParams => ({ ...prevParams, activityTemplate: e.target.value as string }))
            }
            fullWidth
            required
            renderValue={value => capitalize(value)}
            id='activity-template'
          >
            <MenuItem value='lesson' sx={{ whiteSpace: 'wrap' }}>
              <Row gap={2} py={1} maxWidth={780}>
                <img src={OpenBook} alt='Lesson' width={40} />
                <Stack>
                  <Typography variant='labelLarge'>Lesson</Typography>
                  <Typography variant='bodyMedium' color='text.secondary'>
                    Teach learners new concepts through lectures, questions and discussions. Our AI-facilitated lesson
                    discussions encourage learners to think through open-ended questions.
                  </Typography>
                </Stack>
              </Row>
            </MenuItem>
            <MenuItem value='assessment' sx={{ whiteSpace: 'wrap' }}>
              <Row gap={2} py={1} maxWidth={780}>
                <img src={Test} alt='Assessment' width={40} />
                <Stack>
                  <Typography variant='labelLarge'>Assessment</Typography>
                  <Typography variant='bodyMedium' color='text.secondary'>
                    Test learners&apos; understanding with discussion-based assessments. Our AI-facilitated assessment
                    discussions gage learners&apos; understanding without guiding them to the correct answer.
                  </Typography>
                </Stack>
              </Row>
            </MenuItem>
          </Select>
        </Stack>
      )}
      <Stack spacing='2px'>
        <Typography variant='labelLarge' component='label' htmlFor='lesson-title'>
          Title
        </Typography>
        <Typography variant='bodyMedium' color='text.secondary'>
          What would you like to teach?{' '}
        </Typography>
        <TextField
          value={promptParams.lessonTitle}
          onChange={e => setPromptParams(prevParams => ({ ...prevParams, lessonTitle: e.target.value }))}
          fullWidth
          placeholder='How to make the perfect cappuccino.'
          autoFocus
          required
          id='lesson-title'
        />
      </Stack>
      <Stack spacing='2px'>
        <Typography variant='labelLarge' component='label' htmlFor='lesson-audience'>
          Audience
        </Typography>
        <Typography variant='bodyMedium' color='text.secondary'>
          Who is this lesson intended for?
        </Typography>
        <TextField
          value={promptParams.audience}
          multiline
          minRows={3}
          onChange={e => setPromptParams(prevParams => ({ ...prevParams, audience: e.target.value }))}
          fullWidth
          required
          placeholder='Intelligent cats who live in single family homes, own an espresso machine, have opposable thumbs, and know the basics of making coffee.'
          id='lesson-audience'
        />
      </Stack>
      <Stack spacing='2px'>
        <Typography variant='labelLarge' component='label' htmlFor='lesson-objectives'>
          Learning Objectives
        </Typography>
        <Typography variant='bodyMedium' color='text.secondary'>
          What would you like your audience to be able to do by the end of the lesson?
        </Typography>
        <TextField
          value={promptParams.lessonObjective}
          multiline
          minRows={3}
          onChange={e => setPromptParams(prevParams => ({ ...prevParams, lessonObjective: e.target.value }))}
          fullWidth
          required
          placeholder='Students will know the best approaches to making a cappuccino, including coffee grind, type of milk, steaming techniques, and pouring techniques.'
          id='lesson-objectives'
        />
      </Stack>
      {shouldShowAdditionalMaterialUpload && (
        <Stack spacing='2px'>
          <Typography variant='labelLarge' component='label' htmlFor='lesson-objectives'>
            Upload additional materials
          </Typography>
          <Typography variant='bodyMedium' color='text.secondary'>
            Do you have additional learning context like a text file? Upload it to provide more context and make your
            lesson more relevant.
          </Typography>
          <FileUploader acceptTypes={ACCEPT_ALL_TEXT_FILES} afterUpload={handleAdditionalMaterialAttach}>
            <FileList onRemove={handleAdditionalMaterialRemove} />
            <UploadButton />
          </FileUploader>
        </Stack>
      )}
    </Stack>
  );
}
