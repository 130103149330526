import React, { ReactElement } from 'react';
import { DetailedSegmentType } from 'controllers/types';
import {
  QuestionAnswerOutlined,
  SpeakerNotesOutlined,
  HelpCenterOutlined,
  QuizOutlined,
  ForumOutlined,
} from '@mui/icons-material';

export const SEGMENT_TYPE_ICON: Record<DetailedSegmentType, ReactElement> = {
  step: <SpeakerNotesOutlined />,
  question: <HelpCenterOutlined />,
  wait: <QuestionAnswerOutlined />,
  multiple_choice: <QuizOutlined />,
  conversation: <ForumOutlined />,
};
