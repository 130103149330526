import { useTitle } from 'components/StudioLayout/StudioLayout';
import { UserContext } from 'components/UserContext';
import { useUpdateOrganization } from 'controllers/react-query/organizationHooks';
import { OrganizationPayload } from 'controllers/types';
import { useSnackbar } from 'notistack';
import React, { useContext } from 'react';
import { SettingsForm } from './SettingsForm';

export function Settings() {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const { mutate: updateOrganization } = useUpdateOrganization(user?.active_organization?.id);

  useTitle('Settings');

  function handleSubmit(o: OrganizationPayload) {
    updateOrganization(o, {
      onSuccess: newOrganization => {
        enqueueSnackbar(`${newOrganization.name} was successfully updated`, { variant: 'success' });
      },
      onError: error => {
        enqueueSnackbar(`Failed to update organization: ${error.message}`, { variant: 'error' });
      },
    });
  }

  return user?.active_organization && <SettingsForm organization={user.active_organization} onSubmit={handleSubmit} />;
}
