import { Box, Fade, IconButton, Stack, SvgIconTypeMap, SxProps } from '@mui/material';
import React, { useContext } from 'react';
import { UserContext } from 'components/UserContext';
import { NavItemButton, NavItemButtonProps } from 'components/NavItemButton/NavItemButton';
import {
  MenuBook,
  People,
  School,
  Settings,
  Face,
  IntegrationInstructions,
  Help,
  Speed,
  AppRegistration,
  Edit,
  DataUsage,
} from '@mui/icons-material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { OrganizationSelect } from 'components/OrganizationSelect/OrganizationSelect';
import { Link } from 'react-router-dom';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Row } from 'components/Row/Row';
import KyronLogoWithText from '../../assets/kyron_logo.svg';
import KyronLogoIcon from '../../assets/kyronlogo.svg';
import { KyronTooltip } from '../KyronTooltip';

export const STUDIO_NAV_WIDTH = 200;
export const STUDIO_NAV_WIDTH_COLLAPSED = 52;
export const TRANSITION_STYLE = '0.2s ease-in-out';

const getAnimationStyles = (collapsed: boolean) => ({
  transition: `width ${TRANSITION_STYLE}`,
  width: `${collapsed ? STUDIO_NAV_WIDTH_COLLAPSED : STUDIO_NAV_WIDTH}px`,
});

type Props = {
  sx?: SxProps;
  collapsed: boolean;
  onCollapse: () => void;
  isMobile?: boolean;
};

type NavItem =
  | (Omit<NavItemButtonProps, 'icon'> & {
      icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & { muiName: string };
    })
  | { spacing: number };

const commonNavItems: NavItem[] = [
  { to: '/studio/library', label: 'Library', icon: MenuBook },
  { to: '/studio/classrooms', label: 'Classrooms', icon: School },
];

const orgAdminNavItems: NavItem[] = [
  { to: '/studio/members', label: 'Members', icon: People },
  { to: '/studio/settings', label: 'Settings', icon: Settings },
  { to: '/studio/lti_platforms', label: 'LTI Platforms', icon: IntegrationInstructions },
];

const platformAdminNavItems: NavItem[] = [
  { to: '/studio/tutors', label: 'Tutors', icon: Face },
  { to: '/studio/internal_lesson_editor', label: 'Internal Lesson Editor', icon: Edit },
  { to: '/studio/misconception_editor', label: 'Misconception Editor', icon: Help },
  { to: '/studio/course_editor', label: 'Course Editor', icon: AppRegistration },
  { to: '/studio/unit_editor', label: 'Unit Editor', icon: Edit },
  { to: '/studio/test_runner', label: 'Test Runner', icon: Speed },
  { to: '/studio/organization_analytics', label: 'Organization Analytics', icon: DataUsage },
  { to: '/studio/student_dashboard', label: 'Student Dashboard', icon: People },
];

export function StudioNavigation({ sx, collapsed, onCollapse, isMobile }: Props) {
  const { user, isOrgAdmin, isPlatformAdmin } = useContext(UserContext);

  const navItems: NavItem[] = [
    ...commonNavItems,
    ...(isOrgAdmin ? orgAdminNavItems : []),
    { spacing: 16 }, // add 16px spacing between platform admin links and other links
    ...(isPlatformAdmin ? platformAdminNavItems : []),
  ];

  return isMobile ? (
    <Row
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: 'surfaceContainer.main',
        height: '80px',
        px: 1,
        zIndex: 99,
        typography: 'labelSmall',
        gap: 2,
      }}
    >
      <NavItemButton to='/studio/library' label='Library' icon={<MenuBook />} vertical />
      <NavItemButton to='/studio/classrooms' label='Classrooms' icon={<School />} vertical />
      {isOrgAdmin && <NavItemButton to='/studio/settings' label='Settings' icon={<Settings />} vertical />}
    </Row>
  ) : (
    <Stack spacing={5} padding='22px 8px' sx={{ ...sx, ...getAnimationStyles(collapsed) }} data-testid='studio-sidebar'>
      <KyronLogoLink collapsed={collapsed} />

      {user ? <OrganizationSelect isIconButton={collapsed} /> : null}

      <Stack
        spacing={1}
        component='nav'
        sx={{ typography: 'labelMedium', color: 'text.secondary', overflowX: 'hidden', overflowY: 'auto' }}
      >
        {/* NAVIGATION LINKS */}
        {navItems.map((props, idx) => {
          // eslint-disable-next-line react/no-array-index-key
          if ('spacing' in props) return <Box key={idx} sx={{ height: props.spacing }} />;

          const { icon: Icon, label, ...itemProps } = props;
          return (
            <NavItemButton
              collapsed={collapsed}
              key={label}
              label={label}
              icon={<Icon fontSize='small' />}
              {...itemProps}
              dense
            />
          );
        })}
      </Stack>

      <CollapseButton collapsed={collapsed} onCollapse={onCollapse} />
    </Stack>
  );
}

function KyronLogoLink({ collapsed }: { collapsed: boolean }) {
  return (
    <Link to='/' style={{ display: 'flex', position: 'relative', justifyContent: 'center' }} data-testid='app-bar-logo'>
      <Fade in={collapsed}>
        <img alt='Kyron Learning' src={KyronLogoIcon} height='24' />
      </Fade>
      <Fade in={!collapsed} style={{ position: 'absolute' }}>
        <img alt='Kyron Learning' src={KyronLogoWithText} height='24' />
      </Fade>
    </Link>
  );
}

function CollapseButton({ collapsed, onCollapse }: { collapsed: boolean; onCollapse: () => void }) {
  return (
    <KyronTooltip title={collapsed ? 'Expand Menu' : 'Collapse Menu'} placement='right' arrow>
      <Box
        data-testid='collapse-button'
        sx={{
          position: 'absolute',
          bottom: 80,
          left: (collapsed ? STUDIO_NAV_WIDTH_COLLAPSED : STUDIO_NAV_WIDTH) - 14,
          zIndex: 99999,
          transition: `left ${TRANSITION_STYLE}`,
        }}
        display='flex'
        justifyContent='flex-end'
      >
        <IconButton
          onClick={onCollapse}
          sx={{ width: 24, height: 24, background: t => t.palette.surfaceContainer.main }}
        >
          <KeyboardArrowLeftIcon fontSize='small' sx={{ transform: `rotate(${collapsed ? 180 : 0}deg)` }} />
        </IconButton>
      </Box>
    </KyronTooltip>
  );
}
