import React, { useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import { Button, IconButton, Stack, SxProps, Tooltip, Typography, useTheme } from '@mui/material';

type Props = {
  /**
   * This is the value that will be copied to the clipboard on click
   */
  value: string;
  /**
   * Label of the button. If not defined then the button will be icon only.
   */
  label?: string;
  isMenuItem?: boolean;
  onAnimationEnd?: () => void;
};

export function CopyButton({ value, label, isMenuItem, onAnimationEnd }: Props) {
  const theme = useTheme();
  if (!value) console.error('CopyButton requires value prop');

  const [isCopied, setIsCopied] = React.useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  useEffect(manageAnimations, [isCopied, onAnimationEnd]);
  function manageAnimations() {
    // This effect to show a green check mark and a tooltip stying "Copied!" for 2 seconds
    // Tooltip has to close before message changes back to default so that user doesn't see that
    // Hence, two distinct timeouts
    let timeoutIDTooltip: NodeJS.Timeout;
    let timeoutIDMessage: NodeJS.Timeout;
    if (isCopied) {
      timeoutIDTooltip = setTimeout(() => {
        setTooltipOpen(false);
      }, 800);
      timeoutIDMessage = setTimeout(() => {
        setIsCopied(false);
        if (onAnimationEnd) onAnimationEnd();
      }, 1200);
    }
    return () => {
      // Clear timeout if component happens to unmount before timeout ends
      clearTimeout(timeoutIDTooltip);
      clearTimeout(timeoutIDMessage);
    };
  }

  const handleCopy = () => {
    if (isCopied) return;
    setIsCopied(true);
    setTooltipOpen(true);
  };

  // extra guard against sloppy developer
  if (!value) return null;

  const dynamicIcon = isCopied ? <DoneIcon color='success' fontSize='small' /> : <ContentCopyIcon fontSize='small' />;
  const transparency = '55';
  const successColor = theme.palette.success.main + transparency;
  const dynamicStyles: SxProps =
    isCopied && !isMenuItem
      ? {
          boxShadow: `0 0 4px 0px ${successColor}, inset 0 0 2px ${successColor} !important`,
        }
      : {};

  const renderButton = () => {
    // Preferred this way of swapping text to keep the width of the component intact when text changes to prevent layout shift
    const dynamicLabel = (
      <Stack sx={{ position: 'relative' }}>
        <span style={{ opacity: isCopied ? 0 : 1 }}>{label}</span>
        <span style={{ opacity: isCopied ? 1 : 0, position: 'absolute' }}>Copied!</span>
      </Stack>
    );

    if (isMenuItem && label) {
      return (
        <Typography
          component='span'
          sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'flex-start' }}
        >
          {dynamicIcon}
          {dynamicLabel}
        </Typography>
      );
    }

    if (label) {
      return (
        <Button
          variant='outlined'
          startIcon={dynamicIcon}
          sx={{ borderRadius: '8px', width: '160px', ...dynamicStyles }}
          color={isCopied ? 'success' : 'info'}
        >
          {dynamicLabel}
        </Button>
      );
    }

    return (
      <Tooltip open={tooltipOpen} title={isCopied ? 'Copied!' : 'Copy'} placement='top'>
        <IconButton sx={{ p: 0.5, m: 1 }}>{dynamicIcon}</IconButton>
      </Tooltip>
    );
  };

  return (
    <CopyToClipboard text={value} onCopy={handleCopy}>
      {renderButton()}
    </CopyToClipboard>
  );
}
