import React, { useContext } from 'react';

import {
  SkipPrevious as BackIcon,
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon,
  VolumeUp as VolumeIcon,
  VolumeOff as VolumeMutedIcon,
  InfoOutlined as InfoIcon,
  Fullscreen,
} from '@mui/icons-material';
import { IconButton, Tooltip, Box, Typography, Slider, Stack, SxProps } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { LessonSegment } from 'controllers/types';
import { UserContext, UserCtx } from 'components/UserContext';
import { convertSecondsToMmSs } from 'components/utils/duration';

const styles: Record<string, SxProps> = {
  VolumeIcon: {
    paddingTop: '10px',
    paddingBottom: '10px',
    zIndex: 0,
  },
  VolumeScrubber: {
    color: '#A21A1A',
    position: 'absolute',
    zIndex: '1',
    top: '-6.5vw',
    margin: '0 auto',
    left: 0,
    right: 0,
    height: '6.5vw',
    '& input[type="range"]': {
      WebkitAppearance: 'slider-vertical',
    },
  },
  VolumeSliderDiv: {
    position: 'relative',
  },
  ScrubberSliderGrid: {
    padding: 0,
  },
  FakeScrubberSlider: {
    border: '1px solid #235E83',
  },
  ScrubberSlider: {
    borderRadius: 0,
    padding: 0,
    '& svg': {
      fontSize: '20vw',
    },
    width: '100%',
    height: 4,
    color: '#235E83',
    '@media (pointer: coarse)': {
      padding: 0,
    },
    '&:hover': {
      '& .MuiSlider-track, & .MuiSlider-rail': {
        paddingTop: '5px',
      },
    },
    '& .MuiSlider-thumb': {
      height: 0,
      width: 0,
      margin: 0,
      padding: 0,
      visibility: 'hidden',
    },
    '& .MuiSlider-valueLabel': {
      marginTop: -1,
      visibility: 'visible',
    },
  },
  ScrubberTimerBox: {
    display: 'flex',
    alignItems: 'center',
    ml: '5%',
  },
  BottomControlsDiv: {
    padding: '0 20px',
    bottom: 0,
    position: 'absolute',
    zIndex: 10,
    background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.4) 100%)',
    width: '100%',
  },
  IconButtons: {
    fontSize: { xs: 18, sm: 28 },
    height: '100%',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '> p': {
      fontSize: { xs: 14, sm: 16 },
    },
  },
  VideoControls: {
    margin: '0 10px',
    alignItems: 'center',
  },
};

type VideoControls2Props = {
  controlsVisible: boolean;
  playing: boolean;
  volume: number;
  volumeHover: boolean;
  setVolumeHover: (hover: boolean) => void;
  volumeMuted: boolean;
  setVolumeMuted: (muted: boolean) => void;
  volumePressed: boolean;
  setVolumePressed: (pressed: boolean) => void;
  playbackRate: number;
  scrubberHover: boolean;
  setScrubberHover: (scrubberHover: boolean) => void;
  setScrubberPressed: (scrubberPressed: boolean) => void;
  videoDuration: number;
  played: number;
  runningLessonSegment: LessonSegment;
  infoVisible: boolean;
  handleScrubberCommitted: () => void;
  handleSetPlaybackRate: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleVolumeChange: (event: Event, value: number | number[]) => void;
  handleVolumeOnChangeCommitted: () => void;
  handlePlayPause: (e: React.MouseEvent<HTMLElement>) => void;
  handleBack: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleVolumeIconClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleSeekChange: (event: Event, value: number | number[]) => void;
  handleSeekMouseDown: () => void;
  handleInfoClick: () => void;
  handleFullScreen: () => void;
};

export const VideoPlayerControls = ({
  controlsVisible,
  playing,
  handlePlayPause,
  handleBack,
  handleVolumeIconClick,
  volumeHover,
  setVolumeHover,
  volumeMuted,
  setVolumeMuted,
  volumePressed,
  setVolumePressed,
  handleVolumeChange,
  handleVolumeOnChangeCommitted,
  volume,
  playbackRate,
  handleSetPlaybackRate,
  scrubberHover,
  setScrubberHover,
  setScrubberPressed,
  handleScrubberCommitted,
  handleSeekChange,
  handleSeekMouseDown,
  videoDuration,
  played,
  runningLessonSegment,
  handleInfoClick,
  handleFullScreen,
  infoVisible,
}: VideoControls2Props) => {
  const user: UserCtx = useContext(UserContext);

  if (!controlsVisible) return <div />;

  const isWaitSegment = runningLessonSegment && runningLessonSegment.segment_type === 'wait';

  const renderFullscreenIcon = () => (
    <Box marginLeft='auto'>
      {/* Fullscreen button */}
      <Tooltip title='Fullscreen'>
        <IconButton
          sx={styles.IconButtons}
          onClick={event => {
            event.stopPropagation();
            handleFullScreen();
          }}
        >
          <Fullscreen fontSize='inherit' />
        </IconButton>
      </Tooltip>
    </Box>
  );

  if (isWaitSegment) {
    return (
      <Box sx={styles.BottomControlsDiv}>
        <Stack direction='row' justifyContent='space-between'>
          {renderFullscreenIcon()}
        </Stack>
      </Box>
    );
  }
  return (
    <Box sx={styles.BottomControlsDiv}>
      <Stack>
        {/* Scrubber slider */}
        <Grid2 container xs={12} justifyContent='center'>
          {isWaitSegment ? (
            <Box sx={styles.FakeScrubberSlider} />
          ) : (
            <Slider
              sx={styles.ScrubberSlider}
              valueLabelDisplay={scrubberHover ? 'on' : 'off'}
              valueLabelFormat={convertSecondsToMmSs}
              onMouseOver={() => setScrubberHover(true)}
              onMouseOut={() => setScrubberHover(false)}
              size='small'
              min={0}
              max={videoDuration}
              value={Math.floor(played - runningLessonSegment.start_time)}
              onMouseDown={() => {
                handleSeekMouseDown();
                setScrubberPressed(true);
              }}
              onChange={handleSeekChange}
              onChangeCommitted={handleScrubberCommitted}
              defaultValue={0}
              onClick={e => e.stopPropagation()}
            />
          )}
        </Grid2>
        <Stack direction='row' justifyContent='space-between'>
          <Grid2 container columnSpacing={1} sx={styles.VideoControls}>
            {/* small Play/Pause button in bottom left */}
            <Grid2>
              <Tooltip title={playing ? 'Pause' : 'Play'}>
                <IconButton sx={styles.IconButtons} aria-label='play or pause' onClick={handlePlayPause}>
                  {!playing ? <PlayArrowIcon fontSize='inherit' /> : <PauseIcon fontSize='inherit' />}
                </IconButton>
              </Tooltip>
            </Grid2>
            <Grid2>
              <Tooltip title='Back to Start'>
                <IconButton sx={styles.IconButtons} aria-label='back to start' onClick={e => handleBack(e)}>
                  <BackIcon fontSize='inherit' />
                </IconButton>
              </Tooltip>
            </Grid2>
            <Grid2>
              <Box
                sx={styles.VolumeSliderDiv}
                onClick={e => e.stopPropagation()}
                onMouseEnter={() => setVolumeHover(true)}
                onMouseLeave={() => (!volumePressed ? setVolumeHover(false) : {})}
              >
                <Tooltip title={volumeMuted || volume === 0 ? 'Unmute' : 'Mute'}>
                  <IconButton
                    sx={() => ({ ...styles.IconButtons, ...styles.VolumeIcon })}
                    onClick={e => handleVolumeIconClick(e)}
                  >
                    {volumeMuted || volume === 0 ? (
                      <VolumeMutedIcon fontSize='inherit' />
                    ) : (
                      <VolumeIcon fontSize='inherit' />
                    )}
                  </IconButton>
                </Tooltip>
                {volumeHover && (
                  <Slider
                    sx={() => ({ ...styles.BottomIconsElements, ...styles.VolumeScrubber })}
                    orientation='vertical'
                    min={0}
                    max={100}
                    defaultValue={100}
                    value={volume * 100}
                    onChange={handleVolumeChange}
                    onMouseDown={() => {
                      setVolumeMuted(false);
                      setVolumePressed(true);
                    }}
                    onChangeCommitted={handleVolumeOnChangeCommitted}
                  />
                )}
              </Box>
            </Grid2>
            <Grid2>
              {/* Playback Rate selector */}
              <Tooltip title='Playback Rate'>
                <IconButton
                  sx={{ ...styles.IconButtons, fontWeight: '700' }}
                  onClick={handleSetPlaybackRate}
                  value={playbackRate}
                  defaultValue={1}
                >
                  <Typography>{playbackRate}X</Typography>
                </IconButton>
              </Tooltip>
            </Grid2>
            {user.isPlatformAdmin && (
              <Grid2>
                {/* Info button */}
                <Tooltip title='Info'>
                  <IconButton
                    sx={styles.IconButtons}
                    onClick={event => {
                      event.stopPropagation();
                      handleInfoClick();
                    }}
                  >
                    <InfoIcon fontSize='inherit' />
                  </IconButton>
                </Tooltip>
              </Grid2>
            )}
          </Grid2>
          {renderFullscreenIcon()}
        </Stack>
        {/* Video and segment names */}
        {infoVisible && (
          <Box mx='20px' mb='10px'>
            <Typography>{runningLessonSegment.name}</Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
};
