// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`LessonBuilder renders the component 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: 10;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  height: 64px;
  background-color: #FCFAFD;
}

.emotion-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 64px;
  padding: 0 16px;
  background-color: #FFFFFF;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  margin-right: auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%;
}

.emotion-3 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-4 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-5 {
  margin: 0;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  color: #1C1B1F;
}

.emotion-6 {
  margin-top: 12px;
}

.emotion-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
  margin-left: auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-8 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-8::-moz-focus-inner {
  border-style: none;
}

.emotion-8.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-8 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-8:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-8:hover {
    background-color: transparent;
  }
}

.emotion-8.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-9 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-family: Open Sans;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #FFFFFF;
  background-color: #bdbdbd;
  width: 48px;
  height: 48px;
  background-color: #193615;
}

<header
    class="MuiBox-root emotion-0"
    data-testid="app-bar"
  >
    <div
      class="MuiStack-root emotion-1"
    >
      <div
        class="MuiStack-root emotion-2"
      >
        <div>
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-3"
            data-testid="ArrowBackIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z"
            />
          </svg>
          <span
            class="MuiTouchRipple-root emotion-4"
          />
        </div>
        <h2
          class="MuiTypography-root MuiTypography-titleLarge emotion-5"
          data-testid="app-bar-title"
        >
          Create new lesson
        </h2>
      </div>
      <div
        class="MuiBox-root emotion-6"
      />
      <div
        class="MuiStack-root emotion-7"
      >
        <button
          aria-haspopup="true"
          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-8"
          data-testid="account-menu-button"
          tabindex="0"
          type="button"
        >
          <div
            class="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault emotion-9"
            data-testid="initials-avatar"
          >
            T
          </div>
          <span
            class="MuiTouchRipple-root emotion-4"
          />
        </button>
      </div>
    </div>
  </header>
  .emotion-0 {
  height: 64px;
}

<div
    class="MuiBox-root emotion-0"
    data-comment="header padding"
  />
  .emotion-0 {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width:600px) {
  .emotion-0 {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width:900px) {
  .emotion-0 {
    max-width: 900px;
  }
}

@media (min-width:0px) {
  .emotion-0 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

@media (min-width:600px) {
  .emotion-0 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.emotion-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 24px;
  background-color: #FFFFFF;
  border-radius: 24px;
  padding: 24px;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.9px 1.9px 3px -0.4px rgba(162, 147, 169, 0.34),3.5px 3.6px 5.6px -0.7px rgba(162, 147, 169, 0.34),5.8px 5.9px 9.3px -1.1px rgba(162, 147, 169, 0.34),9.2px 9.4px 14.8px -1.4px rgba(162, 147, 169, 0.34),14.4px 14.6px 23.1px -1.8px rgba(162, 147, 169, 0.34),21.9px 22.2px 35.1px -2.1px rgba(162, 147, 169, 0.34),32.3px 32.8px 51.8px -2.5px rgba(162, 147, 169, 0.34);
}

.emotion-2 {
  margin: 0;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.emotion-3 {
  margin: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1C1B1FB3;
}

.emotion-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.emotion-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.emotion-6 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #FFFFFF;
  background-color: #4B35FF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34),2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34),6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34);
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  background: linear-gradient(to bottom right, #4B35FF, #A911DF, #FF1F00);
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
  width: 300px;
  height: 48px;
}

.emotion-6::-moz-focus-inner {
  border-style: none;
}

.emotion-6.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-6 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-6:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(52, 37, 178);
  box-shadow: none;
}

@media (hover: none) {
  .emotion-6:hover {
    background-color: #4B35FF;
  }
}

.emotion-6:active {
  box-shadow: none;
}

.emotion-6.Mui-focusVisible {
  box-shadow: none;
}

.emotion-6.Mui-disabled {
  color: #1C1B1F61;
  box-shadow: none;
  background-color: #1C1B1F1F;
}

.emotion-6:hover {
  box-shadow: none;
}

.emotion-6.Mui-focusVisible {
  box-shadow: none;
}

.emotion-6:active {
  box-shadow: none;
}

.emotion-6.Mui-disabled {
  box-shadow: none;
}

.emotion-6.Mui-disabled {
  background: linear-gradient(to bottom right, #4B35FF, #A911DF, #FF1F00);
  box-shadow: none;
  color: #FFFFFF99;
}

.emotion-7 {
  display: inherit;
  margin-right: 8px;
  margin-left: -4px;
}

.emotion-7>*:nth-of-type(1) {
  font-size: 20px;
}

.emotion-8 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-9 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.emotion-10>:not(style):not(style) {
  margin: 0;
}

.emotion-10>:not(style)~:not(style) {
  margin-top: 2px;
}

.emotion-11 {
  margin: 0;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.emotion-13 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  width: 100%;
}

.emotion-14 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 8px;
}

.emotion-14.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-14:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-14:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-14.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-14.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-14.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-15 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}

.emotion-15::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-15::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-15:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-15::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-15:focus {
  outline: 0;
}

.emotion-15:invalid {
  box-shadow: none;
}

.emotion-15::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-15::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-15::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-15:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-15::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-15:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-15:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-15:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-15:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-15.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-15:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-15:-webkit-autofill {
  border-radius: inherit;
}

.emotion-16 {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.emotion-17 {
  float: unset;
  width: auto;
  overflow: hidden;
  padding: 0;
  line-height: 11px;
  -webkit-transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.emotion-22 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 4px 0 5px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  padding: 16.5px 14px;
}

.emotion-22.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-22:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-22:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-22.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-22.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-22.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-23 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 0;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: auto;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  resize: none;
  padding-top: 0;
  padding: 0;
}

.emotion-23::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-23::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-23:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-23::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-23:focus {
  outline: 0;
}

.emotion-23:invalid {
  box-shadow: none;
}

.emotion-23::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-23::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-23::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-23:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-23::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-23:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-23:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-23:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-23:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-23.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-23:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-23:-webkit-autofill {
  border-radius: inherit;
}

.emotion-39 {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
}

.emotion-40 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 600px;
  gap: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-41 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(75, 53, 255, 0.5);
  color: #4B35FF;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  min-width: 156px;
}

.emotion-41::-moz-focus-inner {
  border-style: none;
}

.emotion-41.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-41 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-41:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(75, 53, 255, 0.04);
  border: 1px solid #4B35FF;
}

@media (hover: none) {
  .emotion-41:hover {
    background-color: transparent;
  }
}

.emotion-41.Mui-disabled {
  color: #1C1B1F61;
  border: 1px solid #1C1B1F1F;
}

.emotion-41:hover {
  box-shadow: none;
}

.emotion-41.Mui-focusVisible {
  box-shadow: none;
}

.emotion-41:active {
  box-shadow: none;
}

.emotion-41.Mui-disabled {
  box-shadow: none;
}

.emotion-44 {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
}

.emotion-46 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.emotion-48 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #FFFFFF;
  background-color: #4B35FF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34),2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34),6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34);
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.emotion-48::-moz-focus-inner {
  border-style: none;
}

.emotion-48.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-48 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-48:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(52, 37, 178);
  box-shadow: none;
}

@media (hover: none) {
  .emotion-48:hover {
    background-color: #4B35FF;
  }
}

.emotion-48:active {
  box-shadow: none;
}

.emotion-48.Mui-focusVisible {
  box-shadow: none;
}

.emotion-48.Mui-disabled {
  color: #1C1B1F61;
  box-shadow: none;
  background-color: #1C1B1F1F;
}

.emotion-48:hover {
  box-shadow: none;
}

.emotion-48.Mui-focusVisible {
  box-shadow: none;
}

.emotion-48:active {
  box-shadow: none;
}

.emotion-48.Mui-disabled {
  box-shadow: none;
}

<div
    class="MuiContainer-root MuiContainer-maxWidthMd emotion-0"
  >
    <div
      class="MuiStack-root emotion-1"
    >
      <h4
        class="MuiTypography-root MuiTypography-headlineSmall emotion-2"
      >
        Create new lesson
      </h4>
      <p
        class="MuiTypography-root MuiTypography-bodyMedium emotion-3"
      >
        Use AI to generate a new interactive Kyron lesson. Fill in the fields below and we will take care of the rest.
      </p>
      <div
        class="MuiStack-root emotion-4"
      >
        <div
          class="MuiStack-root emotion-5"
        >
          <button
            class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation emotion-6"
            tabindex="0"
            type="button"
          >
            <span
              class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-7"
            >
              <svg
                aria-hidden="true"
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-8"
                data-testid="AutoAwesomeIcon"
                focusable="false"
                viewBox="0 0 24 24"
              >
                <path
                  d="m19 9 1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25z"
                />
              </svg>
            </span>
            Feeling lucky?
            <span
              class="MuiTouchRipple-root emotion-9"
            />
          </button>
        </div>
        <div
          class="MuiStack-root emotion-10"
        >
          <label
            class="MuiTypography-root MuiTypography-labelLarge emotion-11"
            for="lesson-title"
          >
            Title
          </label>
          <p
            class="MuiTypography-root MuiTypography-bodyMedium emotion-3"
          >
            What would you like to teach? 
          </p>
          <div
            class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root emotion-13"
          >
            <div
              class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth Mui-focused MuiInputBase-formControl emotion-14"
            >
              <input
                aria-invalid="false"
                class="MuiInputBase-input MuiOutlinedInput-input emotion-15"
                id="lesson-title"
                placeholder="How to make the perfect cappuccino."
                required=""
                type="text"
                value=""
              />
              <fieldset
                aria-hidden="true"
                class="MuiOutlinedInput-notchedOutline emotion-16"
              >
                <legend
                  class="emotion-17"
                >
                  <span
                    class="notranslate"
                  >
                    ​
                  </span>
                </legend>
              </fieldset>
            </div>
          </div>
        </div>
        <div
          class="MuiStack-root emotion-10"
        >
          <label
            class="MuiTypography-root MuiTypography-labelLarge emotion-11"
            for="lesson-audience"
          >
            Audience
          </label>
          <p
            class="MuiTypography-root MuiTypography-bodyMedium emotion-3"
          >
            Who is this lesson intended for?
          </p>
          <div
            class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root emotion-13"
          >
            <div
              class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-multiline emotion-22"
            >
              <textarea
                aria-invalid="false"
                class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline emotion-23"
                id="lesson-audience"
                placeholder="Intelligent cats who live in single family homes, own an espresso machine, have opposable thumbs, and know the basics of making coffee."
                required=""
                style="height: 0px; overflow: hidden;"
              />
              <textarea
                aria-hidden="true"
                class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline emotion-23"
                readonly=""
                style="visibility: hidden; position: absolute; overflow: hidden; height: 0px; top: 0px; left: 0px; transform: translateZ(0); padding-top: 0px; padding-bottom: 0px; width: 100%;"
                tabindex="-1"
              />
              <fieldset
                aria-hidden="true"
                class="MuiOutlinedInput-notchedOutline emotion-16"
              >
                <legend
                  class="emotion-17"
                >
                  <span
                    class="notranslate"
                  >
                    ​
                  </span>
                </legend>
              </fieldset>
            </div>
          </div>
        </div>
        <div
          class="MuiStack-root emotion-10"
        >
          <label
            class="MuiTypography-root MuiTypography-labelLarge emotion-11"
            for="lesson-objectives"
          >
            Learning Objectives
          </label>
          <p
            class="MuiTypography-root MuiTypography-bodyMedium emotion-3"
          >
            What would you like your audience to be able to do by the end of the lesson?
          </p>
          <div
            class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root emotion-13"
          >
            <div
              class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-multiline emotion-22"
            >
              <textarea
                aria-invalid="false"
                class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline emotion-23"
                id="lesson-objectives"
                placeholder="Students will know the best approaches to making a cappuccino, including coffee grind, type of milk, steaming techniques, and pouring techniques."
                required=""
                style="height: 0px; overflow: hidden;"
              />
              <textarea
                aria-hidden="true"
                class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline emotion-23"
                readonly=""
                style="visibility: hidden; position: absolute; overflow: hidden; height: 0px; top: 0px; left: 0px; transform: translateZ(0); padding-top: 0px; padding-bottom: 0px; width: 100%;"
                tabindex="-1"
              />
              <fieldset
                aria-hidden="true"
                class="MuiOutlinedInput-notchedOutline emotion-16"
              >
                <legend
                  class="emotion-17"
                >
                  <span
                    class="notranslate"
                  >
                    ​
                  </span>
                </legend>
              </fieldset>
            </div>
          </div>
        </div>
        <div
          class="MuiStack-root emotion-10"
        >
          <label
            class="MuiTypography-root MuiTypography-labelLarge emotion-11"
            for="lesson-objectives"
          >
            Upload additional materials
          </label>
          <p
            class="MuiTypography-root MuiTypography-bodyMedium emotion-3"
          >
            Do you have additional learning context like a text file? Upload it to provide more context and make your lesson more relevant.
          </p>
          <ul
            class="MuiList-root MuiList-padding emotion-39"
          />
          <div
            class="MuiStack-root emotion-40"
          >
            <label
              class="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation upload-button emotion-41"
              role="button"
              tabindex="0"
            >
              <span
                class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-7"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-8"
                  data-testid="UploadFileIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8zm4 18H6V4h7v5h5zM8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"
                  />
                </svg>
              </span>
              Upload file
              <input
                accept=".doc, .docx, .txt, .pdf"
                class="emotion-44"
                data-testid="file-uploader-input"
                type="file"
              />
              <span
                class="MuiTouchRipple-root emotion-9"
              />
            </label>
          </div>
        </div>
      </div>
      <div
        class="MuiStack-root emotion-46"
      >
        <div>
          Cancel
          <span
            class="MuiTouchRipple-root emotion-9"
          />
        </div>
        <button
          aria-label="Create Lesson Button"
          class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation Mui-disabled MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation emotion-48"
          disabled=""
          tabindex="-1"
          type="button"
        >
          Create Lesson
        </button>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
