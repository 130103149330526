// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`LoadingIndicator matches snapshot when loading is false 1`] = `<DocumentFragment />`;

exports[`LoadingIndicator matches snapshot with custom size 1`] = `
<DocumentFragment>
  @keyframes animation-0 {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animation-1 {
  0% {
    stroke-dasharray: 1px,200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -125px;
  }
}

.emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-1 {
  display: inline-block;
  color: #4B35FF;
  -webkit-animation: animation-0 1.4s linear infinite;
  animation: animation-0 1.4s linear infinite;
}

.emotion-2 {
  display: block;
}

.emotion-3 {
  stroke: currentColor;
  stroke-dasharray: 80px,200px;
  stroke-dashoffset: 0;
  -webkit-animation: animation-1 1.4s ease-in-out infinite;
  animation: animation-1 1.4s ease-in-out infinite;
}

.emotion-4 {
  margin: 0;
  font: inherit;
  font-size: 1em;
}

<div
    class="MuiStack-root emotion-0"
  >
    <span
      class="MuiCircularProgress-root MuiCircularProgress-indeterminate MuiCircularProgress-colorPrimary emotion-1"
      role="progressbar"
      style="width: 50px; height: 50px;"
    >
      <svg
        class="MuiCircularProgress-svg emotion-2"
        viewBox="22 22 44 44"
      >
        <circle
          class="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate emotion-3"
          cx="44"
          cy="44"
          fill="none"
          r="20.2"
          stroke-width="3.6"
        />
      </svg>
    </span>
    <span
      class="MuiTypography-root MuiTypography-inherit emotion-4"
    />
  </div>
</DocumentFragment>
`;

exports[`LoadingIndicator matches snapshot with custom styles 1`] = `
<DocumentFragment>
  @keyframes animation-0 {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animation-1 {
  0% {
    stroke-dasharray: 1px,200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -125px;
  }
}

.emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: blue;
}

.emotion-1 {
  display: inline-block;
  color: #4B35FF;
  -webkit-animation: animation-0 1.4s linear infinite;
  animation: animation-0 1.4s linear infinite;
}

.emotion-2 {
  display: block;
}

.emotion-3 {
  stroke: currentColor;
  stroke-dasharray: 80px,200px;
  stroke-dashoffset: 0;
  -webkit-animation: animation-1 1.4s ease-in-out infinite;
  animation: animation-1 1.4s ease-in-out infinite;
}

.emotion-4 {
  margin: 0;
  font: inherit;
  font-size: 1em;
}

<div
    class="MuiStack-root emotion-0"
  >
    <span
      class="MuiCircularProgress-root MuiCircularProgress-indeterminate MuiCircularProgress-colorPrimary emotion-1"
      role="progressbar"
      style="width: 1.2em; height: 1.2em;"
    >
      <svg
        class="MuiCircularProgress-svg emotion-2"
        viewBox="22 22 44 44"
      >
        <circle
          class="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate emotion-3"
          cx="44"
          cy="44"
          fill="none"
          r="20.2"
          stroke-width="3.6"
        />
      </svg>
    </span>
    <span
      class="MuiTypography-root MuiTypography-inherit emotion-4"
    />
  </div>
</DocumentFragment>
`;

exports[`LoadingIndicator matches snapshot with default props 1`] = `
<DocumentFragment>
  @keyframes animation-0 {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animation-1 {
  0% {
    stroke-dasharray: 1px,200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -125px;
  }
}

.emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-1 {
  display: inline-block;
  color: #4B35FF;
  -webkit-animation: animation-0 1.4s linear infinite;
  animation: animation-0 1.4s linear infinite;
}

.emotion-2 {
  display: block;
}

.emotion-3 {
  stroke: currentColor;
  stroke-dasharray: 80px,200px;
  stroke-dashoffset: 0;
  -webkit-animation: animation-1 1.4s ease-in-out infinite;
  animation: animation-1 1.4s ease-in-out infinite;
}

.emotion-4 {
  margin: 0;
  font: inherit;
  font-size: 1em;
}

<div
    class="MuiStack-root emotion-0"
  >
    <span
      class="MuiCircularProgress-root MuiCircularProgress-indeterminate MuiCircularProgress-colorPrimary emotion-1"
      role="progressbar"
      style="width: 1.2em; height: 1.2em;"
    >
      <svg
        class="MuiCircularProgress-svg emotion-2"
        viewBox="22 22 44 44"
      >
        <circle
          class="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate emotion-3"
          cx="44"
          cy="44"
          fill="none"
          r="20.2"
          stroke-width="3.6"
        />
      </svg>
    </span>
    <span
      class="MuiTypography-root MuiTypography-inherit emotion-4"
    />
  </div>
</DocumentFragment>
`;

exports[`LoadingIndicator matches snapshot with message 1`] = `
<DocumentFragment>
  @keyframes animation-0 {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animation-1 {
  0% {
    stroke-dasharray: 1px,200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -125px;
  }
}

.emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-1 {
  display: inline-block;
  color: #4B35FF;
  -webkit-animation: animation-0 1.4s linear infinite;
  animation: animation-0 1.4s linear infinite;
}

.emotion-2 {
  display: block;
}

.emotion-3 {
  stroke: currentColor;
  stroke-dasharray: 80px,200px;
  stroke-dashoffset: 0;
  -webkit-animation: animation-1 1.4s ease-in-out infinite;
  animation: animation-1 1.4s ease-in-out infinite;
}

.emotion-4 {
  margin: 0;
  font: inherit;
  font-size: 1em;
}

<div
    class="MuiStack-root emotion-0"
  >
    <span
      class="MuiCircularProgress-root MuiCircularProgress-indeterminate MuiCircularProgress-colorPrimary emotion-1"
      role="progressbar"
      style="width: 1.2em; height: 1.2em;"
    >
      <svg
        class="MuiCircularProgress-svg emotion-2"
        viewBox="22 22 44 44"
      >
        <circle
          class="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate emotion-3"
          cx="44"
          cy="44"
          fill="none"
          r="20.2"
          stroke-width="3.6"
        />
      </svg>
    </span>
    <span
      class="MuiTypography-root MuiTypography-inherit emotion-4"
    >
      Loading...
    </span>
  </div>
</DocumentFragment>
`;
