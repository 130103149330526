import React, { useState } from 'react';
import { Typography, Tabs, Tab, Box, Stack, Grid, IconButton, Button, Drawer } from '@mui/material';
import { MoreHoriz, Assignment as AssignmentIcon } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { useParams, useNavigate } from 'react-router-dom';
import { useTitle, useActions, useBreadcrumbs } from 'components/StudioLayout/StudioLayout';
import { useGetLessonCollectionAssignments } from 'controllers/react-query/assignmentHooks';
import { useClassroomQuery, useTeachersQuery, useDeleteClassroom } from 'controllers/react-query/classroomHooks';
import { useStudentsInClassroom } from 'controllers/react-query/classroomMisconceptionReportHooks';
import { Assignment } from 'controllers/types';
import { KyronAvatar } from 'components/KyronAvatar/KyronAvatar';
import { useSnackbar } from 'notistack';
import { KyronMenu, KyronMenuItem } from 'components/Studio/components/KyronMenu';
import { Row } from 'components/Row/Row';
import { LessonAssignmentList } from 'components/LessonAssignmentList/LessonAssignmentList';
import { ReauthenticateDialog } from 'components/ReauthenticateDialog';
import { AssignmentCard } from './AssignmentCard';
import { StudentsInClassroomGrid } from './StudentsInClassroomGrid';

enum TabOptions {
  LESSONS,
  STUDENTS,
}

export function AssignmentsPage() {
  const { classroomId } = useParams();
  const [currentTab, setCurrentTab] = React.useState(TabOptions.LESSONS);
  const { isFetching, isError, data } = useGetLessonCollectionAssignments(classroomId);
  const {
    isFetching: isFetchingStudents,
    isError: isErrorStudents,
    data: studentsInClassroom,
  } = useStudentsInClassroom(classroomId);
  const { data: classroomData } = useClassroomQuery(classroomId);
  const { data: teacherData } = useTeachersQuery(classroomId);
  const { mutate: deleteClassroom } = useDeleteClassroom();
  const handleTabChange = (_: unknown, newValue: TabOptions) => {
    setCurrentTab(newValue);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showAssignmentDrawer, setShowAssignmentDrawer] = useState(false);
  const [showReauthenticateDialog, setShowReauthenticateDialog] = useState(false);

  const moreActionsButton = (
    <IconButton onClick={handleOpenMenu} aria-haspopup='true' data-testid='more-actions'>
      <MoreHoriz />
    </IconButton>
  );

  const classroomActions = (
    <Row gap={1}>
      <Button
        variant='contained'
        color='primary'
        startIcon={<AssignmentIcon />}
        onClick={() => setShowAssignmentDrawer(true)}
      >
        Assign a Lesson
      </Button>
      {moreActionsButton}
    </Row>
  );

  useTitle(classroomData?.name || '');
  useActions(classroomActions);
  useBreadcrumbs([{ label: 'Classrooms', to: '/studio/classrooms' }]);

  const handleDeleteClassroom = () => {
    deleteClassroom(
      {
        classroom_id: classroomId,
      },
      {
        onSuccess: () => {
          navigate('/studio/classrooms');
        },
        onError: () => {
          enqueueSnackbar('Error deleting classroom', { variant: 'error' });
        },
      },
    );
  };

  const teachers = teacherData && (
    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
      {teacherData.map(teacher => (
        <Grid item xs='auto' key={teacher.id}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <KyronAvatar image={teacher.avatar_url} label={teacher.name} />
            <Typography variant='bodyLarge' sx={{ marginBottom: 1 }}>
              {teacher.name}
            </Typography>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );

  const assignmentsByUnit = (
    <Grid container spacing={2}>
      {data?.map((assignment: Assignment) => (
        <Grid item xs={6} key={assignment.id}>
          <AssignmentCard assignment={assignment} classroomId={classroomId || ''} />
        </Grid>
      ))}
    </Grid>
  );

  const menuItems: KyronMenuItem[] = [
    {
      label: 'Unsync this classroom',
      onClick: handleDeleteClassroom,
      color: 'error',
    },
  ];

  const studentsTabLabel = `${studentsInClassroom?.length || 0} Student${studentsInClassroom?.length === 1 ? '' : 's'}`;

  return (
    <>
      {teachers}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab label='Lessons' value={TabOptions.LESSONS} />
          <Tab label={studentsTabLabel} value={TabOptions.STUDENTS} />
        </Tabs>
      </Box>
      {currentTab === TabOptions.LESSONS && (
        <>
          {isFetching && <Typography variant='headlineMedium'>Loading...</Typography>}
          {isError && <Typography variant='headlineMedium'>Error loading assignments.</Typography>}
          {isEmpty(data) ? (
            <Typography variant='headlineMedium'>No assignments found for this classroom.</Typography>
          ) : (
            assignmentsByUnit
          )}
        </>
      )}
      {currentTab === TabOptions.STUDENTS && (
        <StudentsInClassroomGrid isFetching={isFetchingStudents} isError={isErrorStudents} data={studentsInClassroom} />
      )}

      <KyronMenu anchorEl={anchorEl} onClose={handleCloseMenu} items={menuItems} />
      <Drawer
        anchor='right'
        open={showAssignmentDrawer}
        onClose={() => setShowAssignmentDrawer(false)}
        PaperProps={{
          sx: {
            width: '600px',
          },
        }}
        // This is a workaround to remove the backdrop from the drawer while
        // still allowing the drawer to close when the user clicks outside of it
        ModalProps={{
          slotProps: {
            backdrop: { sx: { background: 'none' } },
          },
        }}
      >
        <LessonAssignmentList
          closeDrawer={() => setShowAssignmentDrawer(false)}
          setShowReauthenticateDialog={setShowReauthenticateDialog}
        />
      </Drawer>
      <ReauthenticateDialog
        open={showReauthenticateDialog}
        onClose={() => setShowReauthenticateDialog(false)}
        currentLocation={window.location.href}
      />
    </>
  );
}
