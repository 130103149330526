import { Box, Button, GlobalStyles, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { AppBar } from 'components/AppBar/AppBar';
import { Row } from 'components/Row/Row';
import { Breadcrumb, StudioBreadcrumbs } from 'components/StudioBreadcrumbs/StudioBreadcrumbs';
import {
  STUDIO_NAV_WIDTH,
  STUDIO_NAV_WIDTH_COLLAPSED,
  StudioNavigation,
  TRANSITION_STYLE,
} from 'components/StudioNavigation/StudioNavigation';
import React, { useEffect, useLayoutEffect } from 'react';
import { Link, Outlet, useLocation, useOutletContext } from 'react-router-dom';
import { OrganizationSelect } from 'components/OrganizationSelect/OrganizationSelect';
import { STUDIO_NAV_COLLAPSED_KEY } from '../../constants';

const getViewStyles = (collapsed: boolean) => {
  const navWidth = `${collapsed ? STUDIO_NAV_WIDTH_COLLAPSED : STUDIO_NAV_WIDTH}px`;

  return {
    header: {
      backgroundColor: 'background.default',
      left: {
        xs: 0,
        sm: navWidth,
      },
    },
    navigation: {
      backgroundColor: 'background.paper',
      position: 'fixed',
      bottom: 0,
      top: 0,
      width: navWidth,
    },
    content: {
      paddingBottom: {
        xs: 10,
        sm: 0,
      },
      paddingLeft: {
        xs: 0,
        sm: navWidth,
      },
      transition: `padding-left ${TRANSITION_STYLE}`,
    },
    wrapper: {
      padding: {
        xs: 2,
        sm: 5,
      },
    },
  };
};

const mobileGlobalStyles = <GlobalStyles styles={{ '.intercom-launcher': { display: 'none' } }} />;

export function StudioLayout() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [title, setTitle] = React.useState<string | null>(null);
  const [breadcrumbs, setBreadcrumbs] = React.useState<Breadcrumb[]>([]);
  const [actions, setActions] = React.useState<React.ReactNode>(null);

  const menuCollapsed = sessionStorage.getItem(STUDIO_NAV_COLLAPSED_KEY) === 'true';
  const [collapsed, setCollapsed] = React.useState(menuCollapsed || false);

  const handleCollapse = () => {
    setCollapsed(prevVal => {
      const newValue = !prevVal;
      // store this in session storage for persisting user preferences
      sessionStorage.setItem(STUDIO_NAV_COLLAPSED_KEY, newValue.toString());
      return newValue;
    });
  };

  const location = useLocation();

  // Clear layout chrome on every route change. Most routes will set values
  // for these properties but they should be blank for any route that doesn't.
  useLayoutEffect(() => {
    setTitle(null);
    setActions(null);
    setBreadcrumbs([]);
  }, [location.pathname]);

  const styles = getViewStyles(collapsed);

  return (
    <>
      {isMobile && mobileGlobalStyles}

      <AppBar
        showLogo={false}
        sx={styles.header}
        actions={
          !isMobile && (
            <Button to='/' component={Link} variant='outlined'>
              Back to Public Catalog
            </Button>
          )
        }
        navButton={isMobile && <OrganizationSelect isIconButton />}
      />

      <StudioNavigation sx={styles.navigation} collapsed={collapsed} onCollapse={handleCollapse} isMobile={isMobile} />

      <Box component='main' sx={styles.content}>
        <Box sx={styles.wrapper}>
          <Stack spacing={3} m='0 auto' maxWidth='1600px'>
            <Row>
              <Stack gap='2px' sx={{ flex: 1 }}>
                {breadcrumbs ? <StudioBreadcrumbs breadcrumbs={breadcrumbs} /> : null}
                <Typography variant='headlineMedium'>{title}</Typography>
              </Stack>
              <Row>{actions}</Row>
            </Row>

            <Stack>
              <Outlet context={{ setTitle, setActions, setBreadcrumbs }} />
            </Stack>
          </Stack>
        </Box>
      </Box>
    </>
  );
}

type StudioContextType = {
  setTitle: React.Dispatch<React.SetStateAction<string | null>>;
  setActions: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  setBreadcrumbs: React.Dispatch<React.SetStateAction<Breadcrumb[] | null>>;
};

/**
 * Sets the title of the Studio page.
 */
export const useTitle = (title: string) => {
  const { setTitle } = useOutletContext<StudioContextType>();

  useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);
};

/**
 * Sets the primary actions of the studio page.
 */
export const useActions = (actions: JSX.Element) => {
  const { setActions } = useOutletContext<StudioContextType>();

  useEffect(() => {
    setActions(actions);
    // On most studio pages, `actions` is a function that returns a React
    // element that doesn't appear to change but is actually constantly
    // re-generated every time the component changes. Calling `setActions` can
    // cause the component to re-render which creates an infinite loop of
    // re-renders.
    //
    // As a compromise, we will re-render if the actions key changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions?.key]);
};

/**
 * Sets the breadcrumbs of the studio page.
 */
export const useBreadcrumbs = (breadcrumbs: Breadcrumb[]) => {
  const { setBreadcrumbs } = useOutletContext<StudioContextType>();

  useEffect(() => {
    setBreadcrumbs(breadcrumbs);
    // Similar to above, we only want to re-render if the breadcrumbs change

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs?.toString()]);
};
