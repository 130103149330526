import React, { createContext, useContext, useMemo, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  PaletteMode,
  ThemeProvider,
} from '@mui/material';
import { CloseSharp } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { getTheme } from '../theme/kyronTheme';

type ModalContextType = {
  openModal: (modalComponent: ModalConfig) => void;
  closeModal: () => void;
};

export type ModalConfig = {
  id: string;
  title?: string | null;
  content: string | React.ReactNode;
  action?: React.ReactNode;
  dismissible?: boolean;
  fullWidth?: boolean;
  themeMode?: PaletteMode;
  onConfirm?: () => void;
};

const ModalContext = createContext<ModalContextType | null>(null);

/**
 * ModalProvider is a context provider that allows to open and close modals from anywhere in the app.
 * Use the useModal hook to access the context and open/close modals.
 */
export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState<ModalConfig | null>(null);
  const { id, title, content = 'Content', action, dismissible = true, fullWidth, onConfirm } = modalConfig || {};
  const theme = getTheme(modalConfig?.themeMode || 'light');

  const openModal = (mc: ModalConfig) => {
    setModalOpen(true);
    setModalConfig(mc);
  };

  const closeModal = () => setModalOpen(false);

  // Cleans the modalConfig after the modal is fully closed
  const cleanupAfterClose = () => setModalConfig(null);

  const renderCloseButtonIfNeeded = (shouldShow: boolean) =>
    shouldShow ? (
      <IconButton onClick={closeModal} data-testid='close-button'>
        <CloseSharp />
      </IconButton>
    ) : null;

  const renderConfirmationModalActions = () => (
    <DialogActions>
      <Button onClick={closeModal} variant='text'>
        Cancel
      </Button>
      <Button
        onClick={() => {
          if (onConfirm) {
            onConfirm();
            closeModal();
          }
        }}
        color='error'
      >
        Confirm
      </Button>
    </DialogActions>
  );

  const ctx: ModalContextType = useMemo(
    () => ({
      openModal,
      closeModal,
    }),
    [],
  );

  const renderTitle = () => {
    if (typeof title === 'string' && title.length > 0) {
      return <DialogTitle id={`dialog-title-for-${id}`}>{title}</DialogTitle>;
    }
  };

  const modalNode = (
    <ThemeProvider theme={theme}>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={fullWidth ? 'xl' : undefined}
        id={id}
        key={id}
        data-testid={id}
        open={modalOpen && Boolean(modalConfig)}
        onClose={dismissible ? closeModal : undefined}
        aria-labelledby={`dialog-title-for-${id}`}
        aria-describedby={`dialog-description-for-${id}`}
        TransitionProps={{ onExited: cleanupAfterClose }} // To avoid wiping out modal contents before it's fully closed
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px', width: '100%' }}>
          {renderTitle()}
          <Box sx={{ placeSelf: 'center' }}>{renderCloseButtonIfNeeded(dismissible && !onConfirm)}</Box>
        </Box>
        <DialogContent id={`dialog-description-for-${id}`} sx={{ pb: 2 }}>
          {content}
        </DialogContent>
        {action && <DialogActions>{action}</DialogActions>}
        {onConfirm ? renderConfirmationModalActions() : null}
      </Dialog>
    </ThemeProvider>
  );

  return (
    <ModalContext.Provider value={ctx}>
      {children}
      {modalNode}
    </ModalContext.Provider>
  );
};

/**
 * useModal is a hook that allows to open and close modals from anywhere in the app.
 * Usage example:
 * ```tsx
 * const { openModal, closeModal } = useModal();
 * openModal({
 *   id: 'my-modal',
 *   title: 'My Modal',
 *   content: 'This is the content of my modal',
 *   action: (
 *     <>
 *       <Button onClick={closeModal}>Close</Button>
 *       <Button onClick={doSomething}>Do something</Button>
 *     </>
 *   ),
 * });
 * ```
 */
export const useModal = () => {
  const ctx = useContext(ModalContext);
  if (!ctx) throw new Error('useModal must be used within a ModalProvider');
  return ctx;
};
