// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Player when using as component renders the component 1`] = `
<DocumentFragment>
  .emotion-0 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.emotion-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-right: 0;
  -webkit-transition: padding-right 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transition: padding-right 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.emotion-3 {
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;
  -webkit-transition: flex .4s ease-in-out;
  transition: flex .4s ease-in-out;
}

.emotion-4 {
  -webkit-flex: 0 0 64px;
  -ms-flex: 0 0 64px;
  flex: 0 0 64px;
  min-height: 0;
  -webkit-transition: flex .4s ease-in-out;
  transition: flex .4s ease-in-out;
}

.emotion-5 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 8px;
  position: absolute;
  right: 0;
  bottom: 0;
  padding-left: 16px;
  padding-right: 16px;
  height: 64px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 16px;
}

.emotion-5 .MuiToggleButtonGroup-grouped.Mui-selected+.MuiToggleButtonGroup-grouped.Mui-selected {
  border-left: 0;
  margin-left: 0;
}

.emotion-5 .MuiToggleButtonGroup-firstButton,
.emotion-5 .MuiToggleButtonGroup-middleButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.emotion-5 .MuiToggleButtonGroup-lastButton,
.emotion-5 .MuiToggleButtonGroup-middleButton {
  margin-left: -1px;
  border-left: 1px solid transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.emotion-5 .MuiToggleButtonGroup-lastButton.Mui-disabled,
.emotion-5 .MuiToggleButtonGroup-middleButton.Mui-disabled {
  border-left: 1px solid transparent;
}

.emotion-6 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  border-radius: 8px;
  padding: 11px;
  border: 1px solid #D6D5DD;
  color: rgba(0, 0, 0, 0.54);
  border-radius: 100px!important;
  border: 1px solid #4B35FF!important;
  color: #4B35FF;
}

.emotion-6::-moz-focus-inner {
  border-style: none;
}

.emotion-6.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-6 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-6.Mui-disabled {
  color: #1C1B1F61;
  border: 1px solid #1C1B1F1F;
}

.emotion-6:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(28, 27, 31, 0.04);
}

@media (hover: none) {
  .emotion-6:hover {
    background-color: transparent;
  }
}

.emotion-6.Mui-selected {
  color: #1C1B1F;
  background-color: rgba(28, 27, 31, 0.08);
}

.emotion-6.Mui-selected:hover {
  background-color: rgba(28, 27, 31, 0.12);
}

@media (hover: none) {
  .emotion-6.Mui-selected:hover {
    background-color: rgba(28, 27, 31, 0.08);
  }
}

.emotion-6:hover,
.emotion-6:focus {
  background-color: #EDEAEF;
}

.emotion-6.Mui-selected {
  background-color: #4B35FF;
  color: #FFFFFF;
}

.emotion-6.Mui-selected:hover,
.emotion-6.Mui-selected:focus {
  background-color: rgb(52, 37, 178);
}

.emotion-7 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-8 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-12 {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 360px;
}

.emotion-12 .MuiDrawer-paper {
  width: 360px;
  padding: 0 16px 0 0;
  bgcolor: transparent;
  border: none;
  overflow: hidden;
  position: absolute;
  height: calc(100% - 64px);
}

.emotion-13 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  background-image: none;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  z-index: 1200;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  outline: 0;
  right: 0;
  border-left: 1px solid #D6D5DD;
  padding: 12px;
  width: 320px;
}

.emotion-14 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -webkit-print-color-scheme: light;
  color-scheme: light;
  color: #1C1B1F;
  background-color: #FFFFFF;
  height: 100%;
  background-color: transparent;
}

@media print {
  .emotion-14 {
    background-color: #fff;
  }
}

.emotion-14 *,
.emotion-14 *::before,
.emotion-14 *::after {
  box-sizing: inherit;
}

.emotion-14 strong,
.emotion-14 b {
  font-weight: 700;
}

.emotion-15 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #FCFAFD;
  border-radius: 16px;
  height: 100%;
  overflow: hidden;
}

.emotion-16 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px;
}

.emotion-16>:not(style):not(style) {
  margin: 0;
}

.emotion-16>:not(style)~:not(style) {
  margin-left: 8px;
}

.emotion-17 {
  margin: 0;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.emotion-18 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-18::-moz-focus-inner {
  border-style: none;
}

.emotion-18.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-18 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-18:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-18:hover {
    background-color: transparent;
  }
}

.emotion-18.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-19 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}

.emotion-21 {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

<div
    class="MuiBox-root emotion-0"
  >
    <div
      class="fullscreen"
    >
      <div
        class="MuiBox-root emotion-1"
      >
        <div
          class="MuiBox-root emotion-2"
        >
          <div
            class="MuiBox-root emotion-3"
          />
          <div
            class="MuiBox-root emotion-4"
          />
        </div>
      </div>
    </div>
    <div
      aria-label="toggle panel"
      class="MuiToggleButtonGroup-root emotion-5"
      role="group"
    >
      <button
        aria-label="lesson info panel"
        aria-pressed="false"
        class="MuiButtonBase-root MuiToggleButtonGroup-grouped MuiToggleButtonGroup-groupedHorizontal MuiToggleButton-root MuiToggleButton-sizeMedium MuiToggleButton-standard MuiToggleButtonGroup-firstButton emotion-6"
        data-mui-internal-clone-element="true"
        data-testid="toggle-info-panel"
        tabindex="0"
        type="button"
        value="info"
        variant="iconButton"
      >
        <svg
          aria-hidden="true"
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-7"
          data-testid="InfoOutlinedIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path
            d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8"
          />
        </svg>
        <span
          class="MuiTouchRipple-root emotion-8"
        />
      </button>
      <button
        aria-label="transcript panel"
        aria-pressed="false"
        class="MuiButtonBase-root MuiToggleButtonGroup-grouped MuiToggleButtonGroup-groupedHorizontal MuiToggleButton-root MuiToggleButton-sizeMedium MuiToggleButton-standard MuiToggleButtonGroup-lastButton emotion-6"
        data-mui-internal-clone-element="true"
        data-testid="toggle-transcript-panel"
        tabindex="0"
        type="button"
        value="transcript"
        variant="iconButton"
      >
        <svg
          aria-hidden="true"
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-7"
          data-testid="TextSnippetIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path
            d="m20.41 8.41-4.83-4.83c-.37-.37-.88-.58-1.41-.58H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V9.83c0-.53-.21-1.04-.59-1.42M7 7h7v2H7zm10 10H7v-2h10zm0-4H7v-2h10z"
          />
        </svg>
        <span
          class="MuiTouchRipple-root emotion-8"
        />
      </button>
    </div>
    <div
      class="MuiDrawer-root MuiDrawer-docked emotion-12"
    >
      <div
        class="MuiPaper-root MuiPaper-elevation MuiPaper-elevation0 MuiDrawer-paper MuiDrawer-paperAnchorRight MuiDrawer-paperAnchorDockedRight emotion-13"
        style="visibility: hidden; webkit-transform: translateX(1024px); transform: translateX(1024px);"
      >
        <div
          class="MuiScopedCssBaseline-root emotion-14"
        >
          <div
            class="MuiStack-root emotion-15"
            data-testid="panel-area-container-box"
          >
            <div
              class="MuiStack-root emotion-16"
            >
              <h3
                class="MuiTypography-root MuiTypography-titleMedium emotion-17"
              />
              <button
                aria-label="Close Panel"
                class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-18"
                tabindex="0"
                type="button"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-19"
                  data-testid="CloseIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                  />
                </svg>
                <span
                  class="MuiTouchRipple-root emotion-8"
                />
              </button>
            </div>
            <div
              class="MuiBox-root emotion-21"
            >
              <div
                class="MuiBox-root emotion-22"
              />
            </div>
            <div
              class="MuiBox-root emotion-22"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
