/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import { KyronClient } from './KyronClient';

declare global {
  interface Window {
    kyronEventOptions: object;
  }
}

window.kyronEventOptions = {};

export const KyronEvents = {
  sendEvent: (e: string, o = {}) => {
    const options: { [key: string]: string } = { ...o, ...window.kyronEventOptions };
    const formData = new FormData();
    for (const property in options) {
      formData.append(property, options[property]);
    }
    formData.append('name', e);

    const client = new KyronClient();
    return client.submitJsonDataWithError('/api/v1/main/log_event.json', 'POST', formData);
  },
  resetOptions: () => {
    window.kyronEventOptions = {};
  },
  addOptions: (o = {}) => {
    window.kyronEventOptions = { ...o, ...window.kyronEventOptions };
  },
};
