// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Grid displays correctly renders the grid correctly 1`] = `
<DocumentFragment>
  .emotion-0 {
  height: 75vh;
}

.emotion-1 {
  height: 100%;
  width: 100%;
  --ag-header-background-color: #FCFAFD;
  --ag-header-foreground-color: #1C1B1F;
  --ag-borders-critical: none;
  --ag-border-color: #D6D5DD;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;
}

.emotion-3 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-family: Open Sans;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 40px;
  height: 40px;
}

.emotion-4 {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
}

.emotion-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.emotion-6 {
  margin: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.emotion-7 {
  margin: 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #1C1B1FB3;
}

.emotion-8 {
  margin: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1C1B1FB3;
}

.emotion-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-11 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
  color: #BA1A1A;
}

.emotion-12 {
  margin: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #BA1A1A;
}

.emotion-14 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-family: Open Sans;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #FFFFFF;
  background-color: #bdbdbd;
  width: 40px;
  height: 40px;
  background-color: #0d6366;
}

<div
    class="MuiBox-root emotion-0"
  >
    <div
      aria-label="data-rows"
      class="ag-theme-material emotion-1"
    >
      <div
        style="height: 100%;"
      >
        <div
          class="ag-measurement-container"
        >
          <div />
          <div />
          <div />
        </div>
        <div
          aria-atomic="true"
          aria-live="polite"
          aria-relevant="additions text"
          class="ag-aria-description-container"
        />
        <!-- AG Grid -->
        <div
          class="ag-root-wrapper ag-ltr ag-layout-normal"
          grid-id="1"
          role="presentation"
        >
          <div
            class="ag-root-wrapper-body ag-focus-managed ag-layout-normal"
            role="presentation"
          >
            <div
              class="ag-tab-guard ag-tab-guard-top"
              role="presentation"
              tabindex="0"
            />
            <!-- AG Grid Body -->
            <div
              aria-colcount="4"
              aria-rowcount="3"
              class="ag-root ag-unselectable ag-layout-normal"
              role="treegrid"
            >
              <div
                class="ag-header ag-pivot-off ag-header-allow-overflow"
                role="presentation"
                style="height: 1px; min-height: 1px;"
              >
                <div
                  aria-hidden="true"
                  class="ag-pinned-left-header ag-hidden"
                  role="rowgroup"
                  style="width: 0px; min-width: 0px; max-width: 0px;"
                />
                <div
                  class="ag-header-viewport "
                  role="presentation"
                >
                  <div
                    class="ag-header-container"
                    role="rowgroup"
                    style="width: 856px;"
                  >
                    <div
                      aria-rowindex="1"
                      class="ag-header-row ag-header-row-column"
                      role="row"
                      style="height: 0px; top: 0px; width: 856px;"
                    >
                      <div
                        aria-colindex="1"
                        aria-sort="none"
                        class="ag-header-cell ag-column-first ag-header-cell-sortable ag-focus-managed"
                        col-id="student"
                        role="columnheader"
                        style="top: 0px; height: 0px; width: 256px; left: 0px;"
                        tabindex="-1"
                      >
                        <div
                          aria-hidden="false"
                          class="ag-header-cell-resize"
                          role="presentation"
                        />
                        <div
                          aria-hidden="true"
                          class="ag-labeled ag-label-align-right ag-checkbox ag-input-field ag-header-select-all ag-hidden"
                          role="presentation"
                        >
                          
                
                          <div
                            aria-hidden="true"
                            class="ag-input-field-label ag-label ag-hidden ag-checkbox-label"
                            data-ref="eLabel"
                            id="ag-5-label"
                            role="presentation"
                          />
                          
                
                          <div
                            class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                            data-ref="eWrapper"
                            role="presentation"
                          >
                            
                    
                            <input
                              aria-label="Press Space to toggle all rows selection (unchecked)"
                              class="ag-input-field-input ag-checkbox-input"
                              data-ref="eInput"
                              id="ag-5-input"
                              tabindex="-1"
                              type="checkbox"
                            />
                            
                
                          </div>
                          
            
                        </div>
                        <div
                          class="ag-header-cell-comp-wrapper"
                          role="presentation"
                        >
                          <div
                            class="ag-cell-label-container"
                            role="presentation"
                          >
                            
        
        
        
                            <div
                              class="ag-header-cell-label"
                              data-ref="eLabel"
                              role="presentation"
                            >
                              
            
                              <span
                                class="ag-header-cell-text"
                                data-ref="eText"
                              >
                                Student
                              </span>
                              
            
                              <span
                                aria-hidden="true"
                                class="ag-header-icon ag-header-label-icon ag-filter-icon ag-hidden"
                                data-ref="eFilter"
                              >
                                <span
                                  class="ag-icon ag-icon-filter"
                                  role="presentation"
                                  unselectable="on"
                                />
                              </span>
                              
            
                              <!--AG-SORT-INDICATOR-->
                              <span
                                class="ag-sort-indicator-container"
                                data-ref="eSortIndicator"
                              >
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-order ag-hidden"
                                  data-ref="eSortOrder"
                                />
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-ascending-icon ag-hidden"
                                  data-ref="eSortAsc"
                                >
                                  <span
                                    class="ag-icon ag-icon-asc"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-descending-icon ag-hidden"
                                  data-ref="eSortDesc"
                                >
                                  <span
                                    class="ag-icon ag-icon-desc"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-mixed-icon ag-hidden"
                                  data-ref="eSortMixed"
                                >
                                  <span
                                    class="ag-icon ag-icon-none"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-none-icon ag-hidden"
                                  data-ref="eSortNone"
                                >
                                  <span
                                    class="ag-icon ag-icon-none"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
    
                              </span>
                              
        
                            </div>
                            
    
                          </div>
                        </div>
                      </div>
                      <div
                        aria-colindex="2"
                        aria-sort="none"
                        class="ag-header-cell ag-header-cell-sortable ag-focus-managed"
                        col-id="last_active"
                        role="columnheader"
                        style="top: 0px; height: 0px; width: 200px; left: 256px;"
                        tabindex="-1"
                      >
                        <div
                          aria-hidden="false"
                          class="ag-header-cell-resize"
                          role="presentation"
                        />
                        <div
                          aria-hidden="true"
                          class="ag-labeled ag-label-align-right ag-checkbox ag-input-field ag-header-select-all ag-hidden"
                          role="presentation"
                        >
                          
                
                          <div
                            aria-hidden="true"
                            class="ag-input-field-label ag-label ag-hidden ag-checkbox-label"
                            data-ref="eLabel"
                            id="ag-6-label"
                            role="presentation"
                          />
                          
                
                          <div
                            class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                            data-ref="eWrapper"
                            role="presentation"
                          >
                            
                    
                            <input
                              aria-label="Press Space to toggle all rows selection (unchecked)"
                              class="ag-input-field-input ag-checkbox-input"
                              data-ref="eInput"
                              id="ag-6-input"
                              tabindex="-1"
                              type="checkbox"
                            />
                            
                
                          </div>
                          
            
                        </div>
                        <div
                          class="ag-header-cell-comp-wrapper"
                          role="presentation"
                        >
                          <div
                            class="ag-cell-label-container"
                            role="presentation"
                          >
                            
        
        
        
                            <div
                              class="ag-header-cell-label"
                              data-ref="eLabel"
                              role="presentation"
                            >
                              
            
                              <span
                                class="ag-header-cell-text"
                                data-ref="eText"
                              >
                                Last Active
                              </span>
                              
            
                              <span
                                aria-hidden="true"
                                class="ag-header-icon ag-header-label-icon ag-filter-icon ag-hidden"
                                data-ref="eFilter"
                              >
                                <span
                                  class="ag-icon ag-icon-filter"
                                  role="presentation"
                                  unselectable="on"
                                />
                              </span>
                              
            
                              <!--AG-SORT-INDICATOR-->
                              <span
                                class="ag-sort-indicator-container"
                                data-ref="eSortIndicator"
                              >
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-order ag-hidden"
                                  data-ref="eSortOrder"
                                />
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-ascending-icon ag-hidden"
                                  data-ref="eSortAsc"
                                >
                                  <span
                                    class="ag-icon ag-icon-asc"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-descending-icon ag-hidden"
                                  data-ref="eSortDesc"
                                >
                                  <span
                                    class="ag-icon ag-icon-desc"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-mixed-icon ag-hidden"
                                  data-ref="eSortMixed"
                                >
                                  <span
                                    class="ag-icon ag-icon-none"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-none-icon ag-hidden"
                                  data-ref="eSortNone"
                                >
                                  <span
                                    class="ag-icon ag-icon-none"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
    
                              </span>
                              
        
                            </div>
                            
    
                          </div>
                        </div>
                      </div>
                      <div
                        aria-colindex="3"
                        aria-sort="none"
                        class="ag-header-cell ag-header-cell-sortable ag-focus-managed"
                        col-id="lessons_attempted"
                        role="columnheader"
                        style="top: 0px; height: 0px; width: 200px; left: 456px;"
                        tabindex="-1"
                      >
                        <div
                          aria-hidden="false"
                          class="ag-header-cell-resize"
                          role="presentation"
                        />
                        <div
                          aria-hidden="true"
                          class="ag-labeled ag-label-align-right ag-checkbox ag-input-field ag-header-select-all ag-hidden"
                          role="presentation"
                        >
                          
                
                          <div
                            aria-hidden="true"
                            class="ag-input-field-label ag-label ag-hidden ag-checkbox-label"
                            data-ref="eLabel"
                            id="ag-7-label"
                            role="presentation"
                          />
                          
                
                          <div
                            class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                            data-ref="eWrapper"
                            role="presentation"
                          >
                            
                    
                            <input
                              aria-label="Press Space to toggle all rows selection (unchecked)"
                              class="ag-input-field-input ag-checkbox-input"
                              data-ref="eInput"
                              id="ag-7-input"
                              tabindex="-1"
                              type="checkbox"
                            />
                            
                
                          </div>
                          
            
                        </div>
                        <div
                          class="ag-header-cell-comp-wrapper"
                          role="presentation"
                        >
                          <div
                            class="ag-cell-label-container"
                            role="presentation"
                          >
                            
        
        
        
                            <div
                              class="ag-header-cell-label"
                              data-ref="eLabel"
                              role="presentation"
                            >
                              
            
                              <span
                                class="ag-header-cell-text"
                                data-ref="eText"
                              >
                                Lessons Attempted
                              </span>
                              
            
                              <span
                                aria-hidden="true"
                                class="ag-header-icon ag-header-label-icon ag-filter-icon ag-hidden"
                                data-ref="eFilter"
                              >
                                <span
                                  class="ag-icon ag-icon-filter"
                                  role="presentation"
                                  unselectable="on"
                                />
                              </span>
                              
            
                              <!--AG-SORT-INDICATOR-->
                              <span
                                class="ag-sort-indicator-container"
                                data-ref="eSortIndicator"
                              >
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-order ag-hidden"
                                  data-ref="eSortOrder"
                                />
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-ascending-icon ag-hidden"
                                  data-ref="eSortAsc"
                                >
                                  <span
                                    class="ag-icon ag-icon-asc"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-descending-icon ag-hidden"
                                  data-ref="eSortDesc"
                                >
                                  <span
                                    class="ag-icon ag-icon-desc"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-mixed-icon ag-hidden"
                                  data-ref="eSortMixed"
                                >
                                  <span
                                    class="ag-icon ag-icon-none"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-none-icon ag-hidden"
                                  data-ref="eSortNone"
                                >
                                  <span
                                    class="ag-icon ag-icon-none"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
    
                              </span>
                              
        
                            </div>
                            
    
                          </div>
                        </div>
                      </div>
                      <div
                        aria-colindex="4"
                        aria-sort="none"
                        class="ag-header-cell ag-column-last ag-header-cell-sortable ag-focus-managed"
                        col-id="lessons_with_misconceptions"
                        role="columnheader"
                        style="top: 0px; height: 0px; width: 200px; left: 656px;"
                        tabindex="-1"
                      >
                        <div
                          aria-hidden="false"
                          class="ag-header-cell-resize"
                          role="presentation"
                        />
                        <div
                          aria-hidden="true"
                          class="ag-labeled ag-label-align-right ag-checkbox ag-input-field ag-header-select-all ag-hidden"
                          role="presentation"
                        >
                          
                
                          <div
                            aria-hidden="true"
                            class="ag-input-field-label ag-label ag-hidden ag-checkbox-label"
                            data-ref="eLabel"
                            id="ag-8-label"
                            role="presentation"
                          />
                          
                
                          <div
                            class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                            data-ref="eWrapper"
                            role="presentation"
                          >
                            
                    
                            <input
                              aria-label="Press Space to toggle all rows selection (unchecked)"
                              class="ag-input-field-input ag-checkbox-input"
                              data-ref="eInput"
                              id="ag-8-input"
                              tabindex="-1"
                              type="checkbox"
                            />
                            
                
                          </div>
                          
            
                        </div>
                        <div
                          class="ag-header-cell-comp-wrapper"
                          role="presentation"
                        >
                          <div
                            class="ag-cell-label-container"
                            role="presentation"
                          >
                            
        
        
        
                            <div
                              class="ag-header-cell-label"
                              data-ref="eLabel"
                              role="presentation"
                            >
                              
            
                              <span
                                class="ag-header-cell-text"
                                data-ref="eText"
                              >
                                Lessons with Misconceptions
                              </span>
                              
            
                              <span
                                aria-hidden="true"
                                class="ag-header-icon ag-header-label-icon ag-filter-icon ag-hidden"
                                data-ref="eFilter"
                              >
                                <span
                                  class="ag-icon ag-icon-filter"
                                  role="presentation"
                                  unselectable="on"
                                />
                              </span>
                              
            
                              <!--AG-SORT-INDICATOR-->
                              <span
                                class="ag-sort-indicator-container"
                                data-ref="eSortIndicator"
                              >
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-order ag-hidden"
                                  data-ref="eSortOrder"
                                />
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-ascending-icon ag-hidden"
                                  data-ref="eSortAsc"
                                >
                                  <span
                                    class="ag-icon ag-icon-asc"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-descending-icon ag-hidden"
                                  data-ref="eSortDesc"
                                >
                                  <span
                                    class="ag-icon ag-icon-desc"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-mixed-icon ag-hidden"
                                  data-ref="eSortMixed"
                                >
                                  <span
                                    class="ag-icon ag-icon-none"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
        
                                <span
                                  aria-hidden="true"
                                  class="ag-sort-indicator-icon ag-sort-none-icon ag-hidden"
                                  data-ref="eSortNone"
                                >
                                  <span
                                    class="ag-icon ag-icon-none"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
    
                              </span>
                              
        
                            </div>
                            
    
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  aria-hidden="true"
                  class="ag-pinned-right-header ag-hidden"
                  role="rowgroup"
                  style="width: 0px; min-width: 0px; max-width: 0px;"
                />
              </div>
              <!-- AG Pinned Top -->
              <div
                class="ag-floating-top ag-selectable"
                role="presentation"
                style="height: 0px; min-height: 0; display: none; overflow-y: hidden;"
              >
                <!-- AG Row Container topLeft -->
                <div
                  aria-hidden="true"
                  class="ag-pinned-left-floating-top ag-hidden"
                  role="rowgroup"
                  style="width: 0px; max-width: 0px; min-width: 0px;"
                />
                <!-- AG Row Container topCenter -->
                <div
                  class="ag-floating-top-viewport"
                  role="presentation"
                >
                  <div
                    class="ag-floating-top-container"
                    role="rowgroup"
                    style="width: 856px;"
                  />
                </div>
                <!-- AG Row Container topRight -->
                <div
                  aria-hidden="true"
                  class="ag-pinned-right-floating-top ag-hidden"
                  role="rowgroup"
                  style="width: 0px; max-width: 0px; min-width: 0px;"
                />
                <!-- AG Row Container topFullWidth -->
                <div
                  class="ag-floating-top-full-width-container"
                  role="rowgroup"
                />
              </div>
              <div
                class="ag-body ag-layout-normal"
                role="presentation"
              >
                <!-- AG Middle -->
                <div
                  class="ag-body-viewport ag-row-no-animation ag-layout-normal ag-selectable"
                  role="presentation"
                >
                  <!-- AG Row Container left -->
                  <div
                    aria-hidden="true"
                    class="ag-pinned-left-cols-container ag-hidden"
                    role="rowgroup"
                    style="height: 84px; width: 0px; max-width: 0px; min-width: 0px;"
                  />
                  <!-- AG Row Container center -->
                  <div
                    class="ag-center-cols-viewport"
                    role="presentation"
                    style="height: 84px;"
                  >
                    <div
                      class="ag-center-cols-container"
                      role="rowgroup"
                      style="width: 856px; height: 84px;"
                    >
                      <div
                        aria-rowindex="2"
                        class="ag-row-even ag-row-no-focus ag-row ag-row-level-0 ag-row-position-absolute ag-row-first ag-row-not-inline-editing"
                        role="row"
                        row-id="0"
                        row-index="0"
                        style="transform: translateY(0px); height: 42px;"
                      >
                        <div
                          aria-colindex="1"
                          class="ag-cell ag-cell-not-inline-editing ag-cell-auto-height ag-column-first"
                          col-id="student"
                          role="gridcell"
                          style="left: 0px; width: 256px;"
                          tabindex="-1"
                        >
                          <div
                            class="ag-cell-wrapper"
                            role="presentation"
                          >
                            <span
                              class="ag-cell-value"
                              id="cell-student-0"
                              role="presentation"
                            >
                              <div
                                class="MuiStack-root emotion-2"
                              >
                                <div
                                  class="MuiAvatar-root MuiAvatar-circular emotion-3"
                                  data-testid="image-avatar"
                                >
                                  <img
                                    alt="student 1"
                                    class="MuiAvatar-img emotion-4"
                                    loading="lazy"
                                    src="https://example.com/avatar.png"
                                  />
                                </div>
                                <div
                                  class="MuiStack-root emotion-5"
                                >
                                  <p
                                    class="MuiTypography-root MuiTypography-bodyMedium emotion-6"
                                  >
                                    student 1
                                  </p>
                                  <p
                                    class="MuiTypography-root MuiTypography-bodySmall emotion-7"
                                  >
                                    student1@test.com
                                  </p>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div
                          aria-colindex="2"
                          class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height"
                          col-id="last_active"
                          role="gridcell"
                          style="left: 256px; width: 200px; align-items: center; display: flex; overflow: hidden; text-overflow: ellipsis;"
                          tabindex="-1"
                        >
                          <div
                            class="ag-cell-wrapper"
                            role="presentation"
                          >
                            <span
                              class="ag-cell-value"
                              id="cell-last_active-1"
                              role="presentation"
                            >
                              <p
                                class="MuiTypography-root MuiTypography-bodyMedium emotion-8"
                              >
                                Active 2 years ago
                              </p>
                            </span>
                          </div>
                        </div>
                        <div
                          aria-colindex="3"
                          class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height"
                          col-id="lessons_attempted"
                          role="gridcell"
                          style="left: 456px; width: 200px; align-items: center; display: flex; overflow: hidden; text-overflow: ellipsis;"
                          tabindex="-1"
                        >
                          <div
                            class="ag-cell-wrapper"
                            role="presentation"
                          >
                            <span
                              class="ag-cell-value"
                              id="cell-lessons_attempted-2"
                              role="presentation"
                            >
                              <p
                                class="MuiTypography-root MuiTypography-bodyMedium emotion-8"
                              >
                                2 lessons attempted
                              </p>
                            </span>
                          </div>
                        </div>
                        <div
                          aria-colindex="4"
                          class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height ag-column-last"
                          col-id="lessons_with_misconceptions"
                          role="gridcell"
                          style="left: 656px; width: 200px; align-items: center; display: flex; overflow: hidden; text-overflow: ellipsis;"
                          tabindex="-1"
                        >
                          <div
                            class="ag-cell-wrapper"
                            role="presentation"
                          >
                            <span
                              class="ag-cell-value"
                              id="cell-lessons_with_misconceptions-3"
                              role="presentation"
                            >
                              <div
                                class="MuiStack-root emotion-10"
                              >
                                <svg
                                  aria-hidden="true"
                                  class="MuiSvgIcon-root MuiSvgIcon-colorError MuiSvgIcon-fontSizeSmall emotion-11"
                                  data-testid="WarningAmberIcon"
                                  focusable="false"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    d="M12 5.99 19.53 19H4.47zM12 2 1 21h22z"
                                  />
                                  <path
                                    d="M13 16h-2v2h2zm0-6h-2v5h2z"
                                  />
                                </svg>
                                <p
                                  class="MuiTypography-root MuiTypography-bodyMedium emotion-12"
                                >
                                  1 lesson with misconceptions
                                </p>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        aria-rowindex="3"
                        class="ag-row-odd ag-row-no-focus ag-row ag-row-level-0 ag-row-position-absolute ag-row-last ag-row-not-inline-editing"
                        role="row"
                        row-id="1"
                        row-index="1"
                        style="transform: translateY(42px); height: 42px;"
                      >
                        <div
                          aria-colindex="1"
                          class="ag-cell ag-cell-not-inline-editing ag-cell-auto-height ag-column-first"
                          col-id="student"
                          role="gridcell"
                          style="left: 0px; width: 256px;"
                          tabindex="-1"
                        >
                          <div
                            class="ag-cell-wrapper"
                            role="presentation"
                          >
                            <span
                              class="ag-cell-value"
                              id="cell-student-4"
                              role="presentation"
                            >
                              <div
                                class="MuiStack-root emotion-2"
                              >
                                <div
                                  class="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault emotion-14"
                                  data-testid="initials-avatar"
                                >
                                  s
                                </div>
                                <div
                                  class="MuiStack-root emotion-5"
                                >
                                  <p
                                    class="MuiTypography-root MuiTypography-bodyMedium emotion-6"
                                  >
                                    student 2
                                  </p>
                                  <p
                                    class="MuiTypography-root MuiTypography-bodySmall emotion-7"
                                  >
                                    student2@test.com
                                  </p>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div
                          aria-colindex="2"
                          class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height"
                          col-id="last_active"
                          role="gridcell"
                          style="left: 256px; width: 200px; align-items: center; display: flex; overflow: hidden; text-overflow: ellipsis;"
                          tabindex="-1"
                        >
                          <div
                            class="ag-cell-wrapper"
                            role="presentation"
                          >
                            <span
                              class="ag-cell-value"
                              id="cell-last_active-5"
                              role="presentation"
                            >
                              <p
                                class="MuiTypography-root MuiTypography-bodyMedium emotion-8"
                              >
                                Active 2 years ago
                              </p>
                            </span>
                          </div>
                        </div>
                        <div
                          aria-colindex="3"
                          class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height"
                          col-id="lessons_attempted"
                          role="gridcell"
                          style="left: 456px; width: 200px; align-items: center; display: flex; overflow: hidden; text-overflow: ellipsis;"
                          tabindex="-1"
                        >
                          <div
                            class="ag-cell-wrapper"
                            role="presentation"
                          >
                            <span
                              class="ag-cell-value"
                              id="cell-lessons_attempted-6"
                              role="presentation"
                            >
                              <p
                                class="MuiTypography-root MuiTypography-bodyMedium emotion-8"
                              >
                                2 lessons attempted
                              </p>
                            </span>
                          </div>
                        </div>
                        <div
                          aria-colindex="4"
                          class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height ag-column-last"
                          col-id="lessons_with_misconceptions"
                          role="gridcell"
                          style="left: 656px; width: 200px; align-items: center; display: flex; overflow: hidden; text-overflow: ellipsis;"
                          tabindex="-1"
                        >
                          <div
                            class="ag-cell-wrapper"
                            role="presentation"
                          >
                            <span
                              class="ag-cell-value"
                              id="cell-lessons_with_misconceptions-7"
                              role="presentation"
                            >
                              <div
                                class="MuiStack-root emotion-10"
                              >
                                <svg
                                  aria-hidden="true"
                                  class="MuiSvgIcon-root MuiSvgIcon-colorError MuiSvgIcon-fontSizeSmall emotion-11"
                                  data-testid="WarningAmberIcon"
                                  focusable="false"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    d="M12 5.99 19.53 19H4.47zM12 2 1 21h22z"
                                  />
                                  <path
                                    d="M13 16h-2v2h2zm0-6h-2v5h2z"
                                  />
                                </svg>
                                <p
                                  class="MuiTypography-root MuiTypography-bodyMedium emotion-12"
                                >
                                  1 lesson with misconceptions
                                </p>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- AG Row Container right -->
                  <div
                    aria-hidden="true"
                    class="ag-pinned-right-cols-container ag-hidden"
                    role="rowgroup"
                    style="height: 84px; width: 0px; max-width: 0px; min-width: 0px;"
                  />
                  <!-- AG Row Container fullWidth -->
                  <div
                    class="ag-full-width-container"
                    role="rowgroup"
                    style="height: 84px;"
                  />
                </div>
                <!-- AG Fake Vertical Scroll -->
                <div
                  aria-hidden="true"
                  class="ag-body-vertical-scroll"
                >
                  
            
                  <div
                    class="ag-body-vertical-scroll-viewport"
                    data-ref="eViewport"
                  >
                    
                
                    <div
                      class="ag-body-vertical-scroll-container"
                      data-ref="eContainer"
                      style="height: 84px;"
                    />
                    
            
                  </div>
                  
        
                </div>
              </div>
              <!-- AG Sticky Top -->
              <div
                class="ag-sticky-top ag-selectable"
                role="presentation"
                style="height: 0px; top: 0px; width: 100%;"
              >
                <!-- AG Row Container stickyTopLeft -->
                <div
                  aria-hidden="true"
                  class="ag-pinned-left-sticky-top ag-hidden"
                  role="rowgroup"
                  style="width: 0px; max-width: 0px; min-width: 0px;"
                />
                <!-- AG Row Container stickyTopCenter -->
                <div
                  class="ag-sticky-top-viewport"
                  role="presentation"
                >
                  <div
                    class="ag-sticky-top-container"
                    role="rowgroup"
                    style="width: 856px;"
                  />
                </div>
                <!-- AG Row Container stickyTopRight -->
                <div
                  aria-hidden="true"
                  class="ag-pinned-right-sticky-top ag-hidden"
                  role="rowgroup"
                  style="width: 0px; max-width: 0px; min-width: 0px;"
                />
                <!-- AG Row Container stickyTopFullWidth -->
                <div
                  class="ag-sticky-top-full-width-container"
                  role="rowgroup"
                />
              </div>
              <div
                class="ag-sticky-bottom ag-selectable"
                role="presentation"
                style="height: 0px; bottom: 0px; width: 100%;"
              >
                <!-- AG Row Container stickyBottomLeft -->
                <div
                  aria-hidden="true"
                  class="ag-pinned-left-sticky-bottom ag-hidden"
                  role="rowgroup"
                  style="width: 0px; max-width: 0px; min-width: 0px;"
                />
                <!-- AG Row Container stickyBottomCenter -->
                <div
                  class="ag-sticky-bottom-viewport"
                  role="presentation"
                >
                  <div
                    class="ag-sticky-bottom-container"
                    role="rowgroup"
                    style="width: 856px;"
                  />
                </div>
                <!-- AG Row Container stickyBottomRight -->
                <div
                  aria-hidden="true"
                  class="ag-pinned-right-sticky-bottom ag-hidden"
                  role="rowgroup"
                  style="width: 0px; max-width: 0px; min-width: 0px;"
                />
                <!-- AG Row Container stickyBottomFullWidth -->
                <div
                  class="ag-sticky-bottom-full-width-container"
                  role="rowgroup"
                />
              </div>
              <!-- AG Pinned Bottom -->
              <div
                class="ag-floating-bottom ag-selectable"
                role="presentation"
                style="height: 0px; min-height: 0; display: none; overflow-y: hidden;"
              >
                <!-- AG Row Container bottomLeft -->
                <div
                  aria-hidden="true"
                  class="ag-pinned-left-floating-bottom ag-hidden"
                  role="rowgroup"
                  style="width: 0px; max-width: 0px; min-width: 0px;"
                />
                <!-- AG Row Container bottomCenter -->
                <div
                  class="ag-floating-bottom-viewport"
                  role="presentation"
                >
                  <div
                    class="ag-floating-bottom-container"
                    role="rowgroup"
                    style="width: 856px;"
                  />
                </div>
                <!-- AG Row Container bottomRight -->
                <div
                  aria-hidden="true"
                  class="ag-pinned-right-floating-bottom ag-hidden"
                  role="rowgroup"
                  style="width: 0px; max-width: 0px; min-width: 0px;"
                />
                <!-- AG Row Container bottomFullWidth -->
                <div
                  class="ag-floating-bottom-full-width-container"
                  role="rowgroup"
                />
              </div>
              <!-- AG Fake Horizontal Scroll -->
              <div
                aria-hidden="true"
                class="ag-body-horizontal-scroll"
              >
                
            
                <div
                  class="ag-horizontal-left-spacer"
                  data-ref="eLeftSpacer"
                  style="width: 0px; max-width: 0px; min-width: 0px;"
                />
                
            
                <div
                  class="ag-body-horizontal-scroll-viewport"
                  data-ref="eViewport"
                >
                  
                
                  <div
                    class="ag-body-horizontal-scroll-container"
                    data-ref="eContainer"
                    style="width: 856px;"
                  />
                  
            
                </div>
                
            
                <div
                  class="ag-horizontal-right-spacer"
                  data-ref="eRightSpacer"
                  style="width: 0px; max-width: 0px; min-width: 0px;"
                />
                
        
              </div>
              <!-- AG Overlay Wrapper -->
              <div
                class="ag-overlay ag-hidden"
                role="presentation"
              >
                
                
                <div
                  class="ag-overlay-panel"
                  role="presentation"
                >
                  
                    
                  <div
                    class="ag-overlay-wrapper ag-layout-normal ag-overlay-no-rows-wrapper"
                    data-ref="eOverlayWrapper"
                    role="presentation"
                  />
                  
                
                </div>
                
            
              </div>
            </div>
            <div
              class="ag-tab-guard ag-tab-guard-bottom"
              role="presentation"
              tabindex="0"
            />
          </div>
          <div
            class="ag-paging-panel ag-unselectable ag-focus-managed"
            id="ag-9"
          >
            <div
              class="ag-tab-guard ag-tab-guard-top"
              role="presentation"
              tabindex="0"
            />
            
                
            <!--AG-PAGE-SIZE-SELECTOR-->
            <span
              class="ag-paging-page-size"
              data-ref="pageSizeComp"
            >
              <div
                class="ag-picker-field ag-labeled ag-label-align-left ag-select"
                role="presentation"
              >
                
                
                <div
                  aria-hidden="false"
                  class="ag-label"
                  data-ref="eLabel"
                  id="ag-13-label"
                />
                
                    
                <div
                  aria-controls="ag-select-list-14"
                  aria-expanded="false"
                  aria-label="Page Size"
                  class="ag-wrapper ag-picker-field-wrapper ag-picker-collapsed"
                  data-ref="eWrapper"
                  role="combobox"
                  tabindex="0"
                >
                  
                    
                  <div
                    class="ag-picker-field-display"
                    data-ref="eDisplayField"
                    id="ag-13-display"
                  >
                    100
                  </div>
                  
                    
                  <div
                    aria-hidden="true"
                    class="ag-picker-field-icon"
                    data-ref="eIcon"
                  >
                    <span
                      class="ag-icon ag-icon-small-down"
                      role="presentation"
                      unselectable="on"
                    />
                  </div>
                  
                
                </div>
                
            
              </div>
            </span>
            
                
            <span
              class="ag-paging-row-summary-panel"
              role="status"
            >
              
                    
              <span
                class="ag-paging-row-summary-panel-number"
                data-ref="lbFirstRowOnPage"
                id="ag-9-first-row"
              >
                1
              </span>
              
                    
              <span
                id="ag-9-to"
              >
                to
              </span>
              
                    
              <span
                class="ag-paging-row-summary-panel-number"
                data-ref="lbLastRowOnPage"
                id="ag-9-last-row"
              >
                2
              </span>
              
                    
              <span
                id="ag-9-of"
              >
                of
              </span>
              
                    
              <span
                class="ag-paging-row-summary-panel-number"
                data-ref="lbRecordCount"
                id="ag-9-row-count"
              >
                2
              </span>
              
                
            </span>
            
                
            <span
              class="ag-paging-page-summary-panel"
              role="presentation"
            >
              
                    
              <div
                aria-disabled="true"
                aria-label="First Page"
                class="ag-button ag-paging-button ag-disabled"
                data-ref="btFirst"
                role="button"
                tabindex="0"
              >
                <span
                  class="ag-icon ag-icon-first"
                  role="presentation"
                  unselectable="on"
                />
              </div>
              
                    
              <div
                aria-disabled="true"
                aria-label="Previous Page"
                class="ag-button ag-paging-button ag-disabled"
                data-ref="btPrevious"
                role="button"
                tabindex="0"
              >
                <span
                  class="ag-icon ag-icon-previous"
                  role="presentation"
                  unselectable="on"
                />
              </div>
              
                    
              <span
                class="ag-paging-description"
                role="status"
              >
                
                        
                <span
                  id="ag-9-start-page"
                >
                  Page
                </span>
                
                        
                <span
                  class="ag-paging-number"
                  data-ref="lbCurrent"
                  id="ag-9-start-page-number"
                >
                  1
                </span>
                
                        
                <span
                  id="ag-9-of-page"
                >
                  of
                </span>
                
                        
                <span
                  class="ag-paging-number"
                  data-ref="lbTotal"
                  id="ag-9-of-page-number"
                >
                  1
                </span>
                
                    
              </span>
              
                    
              <div
                aria-disabled="true"
                aria-label="Next Page"
                class="ag-button ag-paging-button ag-disabled"
                data-ref="btNext"
                role="button"
                tabindex="0"
              >
                <span
                  class="ag-icon ag-icon-next"
                  role="presentation"
                  unselectable="on"
                />
              </div>
              
                    
              <div
                aria-disabled="true"
                aria-label="Last Page"
                class="ag-button ag-paging-button ag-disabled"
                data-ref="btLast"
                role="button"
                tabindex="0"
              >
                <span
                  class="ag-icon ag-icon-last"
                  role="presentation"
                  unselectable="on"
                />
              </div>
              
                
            </span>
            
            
            <div
              class="ag-tab-guard ag-tab-guard-bottom"
              role="presentation"
              tabindex="0"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
