// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`OrganizationSelect should only render an icon button with tooltip if isIconButton 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 5px;
  font-size: 1.125rem;
  color: inherit;
}

.emotion-0::-moz-focus-inner {
  border-style: none;
}

.emotion-0.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-0 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-0:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-0:hover {
    background-color: transparent;
  }
}

.emotion-0.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-0:focus,
.emotion-0:hover {
  background-color: #F1ECF4;
  color: #47464F;
}

.emotion-0.active {
  background-color: #F3DBF8;
  color: #340042;
}

.emotion-0.active:focus,
.emotion-0.active:hover {
  background-color: #E1CDE6;
  color: #340042;
}

.emotion-1 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  font-size: 1.25em;
}

.emotion-2 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

<button
    aria-haspopup="true"
    aria-label="Change Organization"
    class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall emotion-0"
    data-mui-internal-clone-element="true"
    data-testid="organization-select-icon-button"
    tabindex="0"
    type="button"
  >
    <svg
      aria-hidden="true"
      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-1"
      data-testid="CorporateFareIcon"
      focusable="false"
      viewBox="0 0 24 24"
    >
      <path
        d="M12 7V3H2v18h20V7zm-2 12H4v-2h6zm0-4H4v-2h6zm0-4H4V9h6zm0-4H4V5h6zm10 12h-8V9h8zm-2-8h-4v2h4zm0 4h-4v2h4z"
      />
    </svg>
    <span
      class="MuiTouchRipple-root emotion-2"
    />
  </button>
</DocumentFragment>
`;
