import React, { useRef, useEffect } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import { styled } from '@mui/material/styles';
import 'ag-grid-community/styles/ag-theme-material.css';
import { ColDef, RowStyle, IRowNode, RowClickedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

type DataGridProps<TData> = {
  rows?: TData[];
  columns: ColDef[];
  options?: ColDef;
  onRowClicked?: (event: RowClickedEvent) => void;
  noRowsText?: string;
  getRowStyle?: (params: { data: TData }) => RowStyle | undefined;
  headerHeight?: number;
  filterText?: string;
  isExternalFilterPresent?: boolean;
  doesExternalFilterPass?: (node: IRowNode<TData>) => boolean;
};

const KyronDataGrid = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  '--ag-header-background-color': theme.palette.surface?.main,
  '--ag-header-foreground-color': theme.palette.text.primary,
  '--ag-borders-critical': 'none',
  '--ag-border-color': theme.palette.divider,
}));

const DataGrid = <TData extends object>({
  rows,
  columns,
  options = { resizable: true, flex: 1, sortable: true },
  onRowClicked = () => {},
  noRowsText = 'No data to display',
  getRowStyle = () => undefined,
  headerHeight = 32,
  filterText = '',
  isExternalFilterPresent = false,
  doesExternalFilterPass = () => true,
}: DataGridProps<TData>) => {
  const gridRef = useRef<AgGridReact>(null);

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.api?.setGridOption('quickFilterText', filterText);
      gridRef.current.api?.onFilterChanged();
    }
  }, [filterText]);

  return (
    <KyronDataGrid className='ag-theme-material' aria-label='data-rows'>
      <AgGridReact
        ref={gridRef}
        rowData={rows}
        headerHeight={headerHeight}
        columnDefs={columns}
        isExternalFilterPresent={() => isExternalFilterPresent}
        doesExternalFilterPass={doesExternalFilterPass}
        suppressMovableColumns
        enableCellTextSelection
        defaultColDef={options}
        onRowClicked={onRowClicked}
        pagination
        overlayNoRowsTemplate={`<div>${noRowsText}</div>`}
        getRowStyle={p => (p.data ? getRowStyle({ data: p.data }) : undefined)}
      />
    </KyronDataGrid>
  );
};

export { DataGrid };
