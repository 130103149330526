// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`LanguageAndVoiceSettings renders correctly 1`] = `
<DocumentFragment>
  @keyframes animation-0 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-0 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-0 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-y: scroll;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
}

.emotion-0>:not(style):not(style) {
  margin: 0;
}

.emotion-0>:not(style)~:not(style) {
  margin-top: 16px;
}

.emotion-1 {
  margin: 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #1C1B1FB3;
}

.emotion-2 {
  margin: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.emotion-3 {
  margin: 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.emotion-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
}

.emotion-5 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  min-width: 120px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.emotion-6 {
  color: #1C1B1FB3;
  line-height: 1.4375em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(14px, 9px) scale(1);
  -moz-transform: translate(14px, 9px) scale(1);
  -ms-transform: translate(14px, 9px) scale(1);
  transform: translate(14px, 9px) scale(1);
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
}

.emotion-6.Mui-focused {
  color: #4B35FF;
}

.emotion-6.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-6.Mui-error {
  color: #BA1A1A;
}

.emotion-7 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 8px;
}

.emotion-7.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-7:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-7:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-7.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-7.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-7.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-8 {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 8px;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding-top: 1px;
  padding: 8.5px 14px;
}

.emotion-8:focus {
  border-radius: 8px;
}

.emotion-8::-ms-expand {
  display: none;
}

.emotion-8.Mui-disabled {
  cursor: default;
}

.emotion-8[multiple] {
  height: auto;
}

.emotion-8:not([multiple]) option,
.emotion-8:not([multiple]) optgroup {
  background-color: #FCFAFD;
}

.emotion-8.emotion-8.emotion-8 {
  padding-right: 32px;
}

.emotion-8.MuiSelect-select {
  height: auto;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.emotion-8::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-8::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-8:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-8::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-8:focus {
  outline: 0;
}

.emotion-8:invalid {
  box-shadow: none;
}

.emotion-8::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-8.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-8:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-8:-webkit-autofill {
  border-radius: inherit;
}

.emotion-9 {
  bottom: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  box-sizing: border-box;
}

.emotion-10 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  position: absolute;
  right: 7px;
  top: calc(50% - .5em);
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
}

.emotion-10.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-11 {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.emotion-12 {
  float: unset;
  width: auto;
  overflow: hidden;
  display: block;
  padding: 0;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 0.01px;
  -webkit-transition: max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  white-space: nowrap;
}

.emotion-12>span {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  opacity: 0;
  visibility: visible;
}

.emotion-13 {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: thin;
}

.emotion-16 {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  max-width: 400px;
  min-height: 190px;
  overflow-y: auto;
  border-radius: 16px;
  border: 1px solid #D6D5DD;
}

.emotion-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  cursor: default;
  padding: 2px 8px;
}

.emotion-17.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-17.Mui-selected {
  background-color: rgba(75, 53, 255, 0.08);
}

.emotion-17.Mui-selected.Mui-focusVisible {
  background-color: rgba(75, 53, 255, 0.2);
}

.emotion-17.Mui-disabled {
  opacity: 0.38;
}

.emotion-18 {
  display: block;
  background-color: rgba(28, 27, 31, 0.11);
  height: auto;
  margin-top: 0;
  margin-bottom: 0;
  transform-origin: 0 55%;
  -webkit-transform: scale(1, 0.60);
  -moz-transform: scale(1, 0.60);
  -ms-transform: scale(1, 0.60);
  transform: scale(1, 0.60);
  border-radius: 8px/13.3px;
  -webkit-animation: animation-0 2s ease-in-out 0.5s infinite;
  animation: animation-0 2s ease-in-out 0.5s infinite;
}

.emotion-18:empty:before {
  content: "\\00a0";
}

.emotion-19 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-20 {
  display: block;
  background-color: rgba(28, 27, 31, 0.11);
  height: 1.2em;
  -webkit-animation: animation-0 2s ease-in-out 0.5s infinite;
  animation: animation-0 2s ease-in-out 0.5s infinite;
  margin-right: 8px;
  border-radius: 48px;
}

.emotion-21 {
  display: block;
  background-color: rgba(28, 27, 31, 0.11);
  height: 1.2em;
  -webkit-animation: animation-0 2s ease-in-out 0.5s infinite;
  animation: animation-0 2s ease-in-out 0.5s infinite;
  border-radius: 48px;
}

<div
    class="MuiStack-root emotion-0"
  >
    <p
      class="MuiTypography-root MuiTypography-bodySmall emotion-1"
    >
      Change the language and voice settings for your lesson.
    </p>
    <label
      class="MuiTypography-root MuiTypography-labelMedium emotion-2"
      for="lesson-language"
    >
      Lesson Language
    </label>
    <p
      class="MuiTypography-root MuiTypography-bodySmall emotion-3"
    >
      Please select the languages for your lesson to be translated into. Translations will be available after publishing this draft.
    </p>
    <div
      class="MuiStack-root emotion-4"
    >
      <div
        class="MuiFormControl-root emotion-5"
      >
        <label
          class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-sizeSmall MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-sizeSmall MuiInputLabel-outlined emotion-6"
          data-shrink="false"
          id="lesson-language-label"
        >
          Language
        </label>
        <div
          class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-sizeSmall  emotion-7"
        >
          <div
            aria-controls=":r0:"
            aria-expanded="false"
            aria-haspopup="listbox"
            aria-labelledby="lesson-language-label lesson-language"
            class="MuiSelect-select MuiSelect-outlined MuiSelect-multiple MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputSizeSmall emotion-8"
            id="lesson-language"
            role="combobox"
            tabindex="0"
          >
            <span
              class="notranslate"
            >
              ​
            </span>
          </div>
          <input
            aria-hidden="true"
            aria-invalid="false"
            class="MuiSelect-nativeInput emotion-9"
            tabindex="-1"
            value=""
          />
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined emotion-10"
            data-testid="ArrowDropDownIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M7 10l5 5 5-5z"
            />
          </svg>
          <fieldset
            aria-hidden="true"
            class="MuiOutlinedInput-notchedOutline emotion-11"
          >
            <legend
              class="emotion-12"
            >
              <span>
                Language
              </span>
            </legend>
          </fieldset>
        </div>
      </div>
    </div>
    <hr
      class="MuiDivider-root MuiDivider-fullWidth emotion-13"
    />
    <label
      class="MuiTypography-root MuiTypography-labelMedium emotion-2"
      for="lesson-voice"
    >
      Lesson Voice
    </label>
    <p
      class="MuiTypography-root MuiTypography-bodySmall emotion-3"
    >
      Select the voice to be used in your lesson.
    </p>
    <ul
      class="MuiList-root MuiList-padding emotion-16"
    >
      <li
        class="MuiListItem-root MuiListItem-gutters emotion-17"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-text MuiSkeleton-pulse emotion-18"
          style="width: 100px; height: 28px;"
        />
        <div
          class="MuiStack-root emotion-19"
        >
          <span
            class="MuiSkeleton-root MuiSkeleton-rectangular MuiSkeleton-pulse emotion-20"
            style="width: 80px; height: 32px;"
          />
          <span
            class="MuiSkeleton-root MuiSkeleton-rectangular MuiSkeleton-pulse emotion-21"
            style="width: 62px; height: 32px;"
          />
        </div>
      </li>
      <li
        class="MuiListItem-root MuiListItem-gutters emotion-17"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-text MuiSkeleton-pulse emotion-18"
          style="width: 100px; height: 28px;"
        />
        <div
          class="MuiStack-root emotion-19"
        >
          <span
            class="MuiSkeleton-root MuiSkeleton-rectangular MuiSkeleton-pulse emotion-20"
            style="width: 80px; height: 32px;"
          />
          <span
            class="MuiSkeleton-root MuiSkeleton-rectangular MuiSkeleton-pulse emotion-21"
            style="width: 62px; height: 32px;"
          />
        </div>
      </li>
      <li
        class="MuiListItem-root MuiListItem-gutters emotion-17"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-text MuiSkeleton-pulse emotion-18"
          style="width: 100px; height: 28px;"
        />
        <div
          class="MuiStack-root emotion-19"
        >
          <span
            class="MuiSkeleton-root MuiSkeleton-rectangular MuiSkeleton-pulse emotion-20"
            style="width: 80px; height: 32px;"
          />
          <span
            class="MuiSkeleton-root MuiSkeleton-rectangular MuiSkeleton-pulse emotion-21"
            style="width: 62px; height: 32px;"
          />
        </div>
      </li>
      <li
        class="MuiListItem-root MuiListItem-gutters emotion-17"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-text MuiSkeleton-pulse emotion-18"
          style="width: 100px; height: 28px;"
        />
        <div
          class="MuiStack-root emotion-19"
        >
          <span
            class="MuiSkeleton-root MuiSkeleton-rectangular MuiSkeleton-pulse emotion-20"
            style="width: 80px; height: 32px;"
          />
          <span
            class="MuiSkeleton-root MuiSkeleton-rectangular MuiSkeleton-pulse emotion-21"
            style="width: 62px; height: 32px;"
          />
        </div>
      </li>
      <li
        class="MuiListItem-root MuiListItem-gutters emotion-17"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-text MuiSkeleton-pulse emotion-18"
          style="width: 100px; height: 28px;"
        />
        <div
          class="MuiStack-root emotion-19"
        >
          <span
            class="MuiSkeleton-root MuiSkeleton-rectangular MuiSkeleton-pulse emotion-20"
            style="width: 80px; height: 32px;"
          />
          <span
            class="MuiSkeleton-root MuiSkeleton-rectangular MuiSkeleton-pulse emotion-21"
            style="width: 62px; height: 32px;"
          />
        </div>
      </li>
    </ul>
  </div>
</DocumentFragment>
`;
