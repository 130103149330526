import React from 'react';
import { Alert, Avatar, Card, CardContent, CardMedia, Typography, Stack, Button } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { LoadingIndicator } from 'components/LoadingIndicator';
import { stringToColor } from 'components/utils/stringToColor';
import { Lesson, Tutor } from 'controllers/types';
import { useTutorsQuery } from 'controllers/react-query';
import backupThumbnail from 'assets/defaultThumbnail.svg';
import { IconText } from 'components/IconText';

const styles = {
  cardContainer: {
    maxWidth: 280,
    bgcolor: 'surfaceContainer.lowest',
    border: 1,
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    ':last-child': { pb: 1 }, // remove extra padding from last child
  },
  cardMedia: {
    height: 140,
    borderRadius: 2,
    ':hover': { opacity: 0.8 },
  },
  clickableText: {
    textDecoration: 'none',
    color: 'inherit',
    ':visited': { color: 'inherit' },
    ':hover': { opacity: 0.8 },
  },
};

function LessonVariantCard({ lesson, tutor }: { lesson: Lesson; tutor?: Tutor }) {
  const updatedDate = dayjs(lesson.updated_at);
  const tutorName = tutor?.display_name || 'Tutor';
  return (
    <Card sx={styles.cardContainer}>
      <CardContent sx={styles.cardContent}>
        <Stack gap={2} flexGrow={1}>
          <Stack direction='row' gap={2} sx={{ alignItems: 'center' }}>
            <Avatar sx={{ bgcolor: stringToColor(tutorName) }}>{tutorName[0]}</Avatar>
            <Stack>
              <Typography variant='labelLarge'>{tutorName}</Typography>
              <Typography>{`Updated ${updatedDate.fromNow()}`}</Typography>
            </Stack>
          </Stack>
          <Link to={`/video_player/${lesson.id}`}>
            <CardMedia
              component='img'
              sx={styles.cardMedia}
              image={lesson.video_thumbnail || backupThumbnail}
              alt='lesson thumbnail'
            />
          </Link>
          <Stack justifyContent='space-between' flexGrow={1}>
            <Typography sx={styles.clickableText} component={Link} to={`/video_player/${lesson.id}`}>
              {lesson.locale?.toUpperCase()} • {lesson.name}
            </Typography>
            <Stack direction='row' justifyContent='flex-end'>
              <Link to={`/studio/internal_lesson_editor/${lesson.id}/edit`}>
                <Button sx={{ width: 'fit-content' }} variant='text' color='primary'>
                  <IconText Icon={EditOutlined}>
                    <Typography variant='labelLarge'>Edit</Typography>
                  </IconText>
                </Button>
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export function AdminLessonVariants({ lessons }: { lessons?: Lesson[] }) {
  const { isLoading, isError, data } = useTutorsQuery();
  const tutors = data?.tutors || [];

  return (
    <Stack gap={2}>
      <Typography variant='headlineSmall'>Lesson Variants</Typography>
      <Typography color='text.secondary'>
        Students can choose between the following variants when they take this lesson. If there is only a single
        variant, students will be taken to that variant immediately upon selecting this lesson.
      </Typography>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Stack gap={2}>
          <Stack direction='row' gap={2} sx={{ overflowX: 'auto' }}>
            {isError ? <Alert severity='error'>Error loading lesson variants</Alert> : null}
            {lessons?.map(lesson => (
              <LessonVariantCard key={lesson.id} lesson={lesson} tutor={tutors.find(t => t.id === lesson.tutor_id)} />
            ))}
          </Stack>
          <Link to='/studio/internal_lesson_editor'>
            <Button sx={{ width: 'fit-content' }} variant='contained' color='primary'>
              + Add Variant
            </Button>
          </Link>
        </Stack>
      )}
    </Stack>
  );
}
