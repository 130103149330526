import React from 'react';
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { useLessonSectionsQuery } from 'controllers/react-query';
import { Editor } from './Editor';
import { LessonSettingsDialog } from '../LessonSettingsDialog/LessonSettingsDialog';
import { GeneralSettings } from '../LessonSettingsDialog/GeneralSettings';
import { MisconceptionDialog } from '../components/MisconceptionDialog';
import { LessonContext } from '../components/LessonContext';
import { LanguageAndVoiceSettings } from '../LessonSettingsDialog/LanguageAndVoiceSettings';
import { SegmentDetail } from './SegmentDetail';

// export for testing
export const RedirectToFirstSegment = () => {
  const { lessonId } = useParams<{ lessonId: string }>();

  const { data } = useLessonSectionsQuery(Number(lessonId));
  const { lesson_sections: lessonSections = [] } = data || {};

  const firstSegmentId = lessonSections[0]?.segments[0]?.id;

  return firstSegmentId ? <Navigate to={`segments/${firstSegmentId}`} replace /> : <SegmentDetail />;
};

export function EditorRoutes() {
  const location = useLocation();
  const previousLocation = location.state?.previousLocation;

  return (
    <>
      <Routes location={previousLocation || location}>
        <Route path='/*' element={<Editor />}>
          <Route index element={<RedirectToFirstSegment />} />
          <Route path='segments/:segmentId' element={<SegmentDetail />} />
          <Route path='settings' element={<LessonSettingsDialog />}>
            <Route index element={<Navigate to='general' replace />} />
            <Route path='general' element={<GeneralSettings />} />
            <Route path='language-and-voice' element={<LanguageAndVoiceSettings />} />
            <Route path='visibility' element={<>Visibility</>} />
            <Route path='misconceptions' element={<MisconceptionDialog />} />
            <Route path='lesson-context' element={<LessonContext />} />
          </Route>
        </Route>
      </Routes>

      {/*
        The block makes it possible to always go back to whatever was the route when the settings dialog is opened.
        So if you're at studio/123/segments/123 and go to settings, the route chages to studio/123/settings/general.
        When the dialog is closed we want the route to go back to studio/123/segments/123.
      */}
      {previousLocation && (
        <Routes>
          <Route path='settings' element={<LessonSettingsDialog />}>
            <Route index element={<Navigate to='general' replace state={{ previousLocation }} />} />
            <Route path='general' element={<GeneralSettings />} />
            <Route path='language-and-voice' element={<LanguageAndVoiceSettings />} />
            <Route path='visibility' element={<>Visibility</>} />
            <Route path='misconceptions' element={<MisconceptionDialog />} />
            <Route path='lesson-context' element={<LessonContext />} />
          </Route>
        </Routes>
      )}
    </>
  );
}
