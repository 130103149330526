import React, { useContext, isValidElement } from 'react';
import { Box, Button, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { AccountMenu } from 'components/AccountMenu/AccountMenu';
import { User } from 'controllers/types';
import { getLoginURLWithRedirect } from 'components/utils/urlUtils';
import { UserContext } from 'components/UserContext';
import { Row } from 'components/Row/Row';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { KyronEvents } from '../utils/KyronEvents';
import KyronLogo from '../../assets/kyron_logo.svg';

export const MAIN_TOOLBAR_HEIGHT = 64;
const SECONDARY_TOOLBAR_HEIGHT = 32;
const HEIGHT_WITH_SECONDARY_TOOLBAR = MAIN_TOOLBAR_HEIGHT + SECONDARY_TOOLBAR_HEIGHT;

const AppBarWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: '0',
  zIndex: '10',
  width: 'fill-available',
});

const MainToolBar = styled(Row)`
  height: ${MAIN_TOOLBAR_HEIGHT}px;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.palette.background.default};
  align-items: flex-start;
`;

const SecondaryToolBar = styled(Box)({ height: SECONDARY_TOOLBAR_HEIGHT, padding: '0 16px' });

type Props = {
  navButton?: React.ReactNode;
  title?: string | React.ReactNode;
  showLogo?: boolean;
  actions?: React.ReactNode;
  mainToolbarSearch?: React.ReactNode;
  sx?: SxProps;
  mainToolBarSx?: SxProps;
  secondaryToolBarContent?: React.ReactNode;
};

export function AppBar({
  navButton,
  title,
  showLogo = true,
  actions,
  mainToolbarSearch,
  sx,
  mainToolBarSx,
  secondaryToolBarContent,
}: Props) {
  const { user } = useContext(UserContext);
  const height = secondaryToolBarContent ? `${HEIGHT_WITH_SECONDARY_TOOLBAR}px` : `${MAIN_TOOLBAR_HEIGHT}px`;
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  const appBarTitle = isValidElement(title) ? (
    title
  ) : (
    <Typography
      variant='titleLarge'
      color='text.primary'
      data-testid='app-bar-title'
      sx={
        isMobile
          ? { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 'calc(100vw - 160px)' }
          : {}
      }
    >
      {title}
    </Typography>
  );

  const appBarLogo = showLogo ? (
    <Link to='/' style={{ display: 'flex' }} data-testid='app-bar-logo'>
      <img alt='Kyron Learning' src={KyronLogo} height='24' />
    </Link>
  ) : null;

  const appBarActions = (u: User) => (
    <>
      {actions}
      <AccountMenu user={u} />
    </>
  );

  const loginButton = (
    <Button
      sx={{ mt: '12px' }}
      component='a'
      href={getLoginURLWithRedirect()}
      onClick={() => {
        KyronEvents.sendEvent('Login');
      }}
      data-testid='login-button'
    >
      Join or Sign In
    </Button>
  );

  return (
    <>
      <AppBarWrapper component='header' data-testid='app-bar' sx={{ height, ...sx }}>
        <MainToolBar sx={mainToolBarSx}>
          <Row gap={1} mr='auto' sx={{ height: '100%' }}>
            {navButton}
            {title ? appBarTitle : appBarLogo}
          </Row>
          <Box mt='12px'>{mainToolbarSearch}</Box>
          <Row gap={2} ml='auto'>
            {user ? appBarActions(user) : loginButton}
          </Row>
        </MainToolBar>
        {secondaryToolBarContent ? <SecondaryToolBar>{secondaryToolBarContent}</SecondaryToolBar> : null}
      </AppBarWrapper>
      {/**
       * Since the header defined above has a fixed positioning, its height is not affecting the elements under it
       * This following box element is an invisible element that has the same height as the header to push the elements
       * below the header down as if the header had a normal positioning.
       * You would think a sticky header would do the same, however, sticky elements are reactive to scrolling, so when user
       * scrolls down, header will move from its space with the whole layout. To try, make above styling sticky and scroll down.
       */}
      <Box sx={{ height }} data-comment='header padding' />
    </>
  );
}
