import React from 'react';
import { Dialog, Divider, Stack, Typography } from '@mui/material';
import { Lesson } from 'controllers/types';
import { LessonSelectionDialogCard } from './LessonSelectionDialogCard';

type LessonSelectionDialogProps = {
  lessons: Lesson[];
};

export function LessonSelectionDialog({ lessons }: LessonSelectionDialogProps) {
  return (
    <Dialog
      open
      sx={{ '& .MuiPaper-root': { p: 3 }, '& .MuiBackdrop-root': { bgcolor: 'transparent' } }}
      data-testid='lesson-selection-dialog'
    >
      <Typography variant='headlineSmall' color='text.primary'>
        Choose a tutor
      </Typography>
      <Typography variant='bodyLarge' color='text.secondary' my={2}>
        This lesson has multiple tutors. Each tutor covers the same material, so choose whichever tutor you prefer. You
        can always switch tutors later.
      </Typography>
      <Stack divider={<Divider />} gap={1}>
        {lessons.map(lesson => (
          <LessonSelectionDialogCard key={lesson.id} lesson={lesson} />
        ))}
      </Stack>
    </Dialog>
  );
}
