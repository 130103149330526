import React from 'react';
import { Divider, Grid, Typography, IconButton, Stack, Tab, Tabs } from '@mui/material';
import { Close, Warning, AutoAwesome, Done } from '@mui/icons-material';
import { StudentWork, OpenEndedMessage } from 'controllers/types';
import { useTranscriptQuery, useLessonInstanceTutorQuery } from 'controllers/react-query';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { CreateMisconceptionLessonButton } from 'components/Teacher/CreateMisconceptionLessonButton';

type Props = {
  assignmentName: string;
  lessonInstanceId: number;
  studentWork?: StudentWork;
  closePanel: () => void;
};

enum TabOptions {
  MISCONCEPTIONS,
  TRANSCRIPT,
}

const styles = {
  header: {
    justifyContent: 'space-between',
    marginBottom: 3,
  },
  usageTable: {
    marginBottom: 3,
  },
  tableItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  tabs: {
    marginBottom: 3,
    justifyContent: 'center',
  },
  misconceptionList: {
    marginBottom: 3,
    justifyContent: 'space-between',
    alignItems: 'top',
  },
};

export const StudentPanel = ({ assignmentName, lessonInstanceId, studentWork, closePanel }: Props) => {
  const [currentTab, setCurrentTab] = React.useState(TabOptions.MISCONCEPTIONS);
  const handleTabChange = (_: unknown, newValue: TabOptions) => {
    setCurrentTab(newValue);
  };
  const { isFetching, isError, data } = useTranscriptQuery(lessonInstanceId);
  const { data: tutorData } = useLessonInstanceTutorQuery(lessonInstanceId);
  const tutorName = tutorData?.display_name || 'Tutor';

  const header = (
    <Grid container direction='row' spacing={2} sx={styles.header}>
      <Grid item container direction='column' spacing={1} xs={10}>
        <Grid item>
          <Typography variant='bodySmall'>{assignmentName}</Typography>
        </Grid>
        <Grid item>
          <Typography variant='titleLarge'>{studentWork?.student}</Typography>
        </Grid>
      </Grid>
      <Grid xs={2} item container justifyContent='flex-end'>
        <Grid item>
          <IconButton onClick={closePanel} data-testid='close-panel'>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );

  const insertDoneIcon = (status: string | undefined) => {
    if (status === 'Finished') {
      return <Done color='success' fontSize='small' />;
    }
  };

  const usageTable = (
    <Grid container direction='column' sx={styles.usageTable}>
      <Grid item container direction='row' sx={styles.tableItem}>
        <Grid item>
          <Typography variant='labelMedium' sx={{ color: 'text.secondary' }}>
            Status
          </Typography>
        </Grid>
        <Grid item>
          <Stack direction='row' alignItems='center'>
            {insertDoneIcon(studentWork?.status)}
            <Typography variant='bodySmall'>{studentWork?.status}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Divider component={Grid} />
      <Grid item container direction='row' sx={styles.tableItem}>
        <Grid item>
          <Typography variant='labelMedium' sx={{ color: 'text.secondary' }}>
            Time Spent
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='bodySmall'>{studentWork?.time_spent}</Typography>
        </Grid>
      </Grid>
      <Divider component={Grid} />
      <Grid item container direction='row' sx={styles.tableItem}>
        <Grid item>
          <Typography variant='labelMedium' sx={{ color: 'text.secondary' }}>
            Finished At
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='bodySmall'>{studentWork?.finished_at || '-'}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const misconceptions = (
    <Grid container direction='column'>
      <Grid item sx={{ marginBottom: 3 }}>
        <Typography variant='bodySmall'>
          Misconceptions are common mistakes that students make when answering questions in a Kyron lesson. They are
          automatically detected by Kyron&apos;s AI and can be used to help guide your instruction.
        </Typography>
      </Grid>
      {studentWork?.misconceptions.length === 0 && (
        <Grid item>
          <Typography variant='titleSmall'>No misconceptions detected for this student.</Typography>
        </Grid>
      )}
      {studentWork?.misconceptions.map(m => (
        <Grid item container direction='row' key={m.code} sx={styles.misconceptionList} spacing={1}>
          <Grid item xs={1}>
            <Warning color='error' sx={{ marginRight: 1 }} />
          </Grid>
          <Grid item container direction='column' xs={11}>
            <Grid item>
              <Typography variant='titleSmall'>{m.name}</Typography>
            </Grid>
            <Grid item>
              <Typography variant='bodyMedium'>{m.description}</Typography>
            </Grid>
            <Grid item>
              <CreateMisconceptionLessonButton misconception={m} />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  const getSpeaker = (speaker: string) => {
    if (speaker === 'Student') return <Typography variant='labelLarge'>{studentWork?.student}</Typography>;
    return (
      <Stack direction='row' alignItems='center' spacing={1}>
        <Typography variant='labelLarge'>A.I. Assistant</Typography>
        <AutoAwesome fontSize='small' sx={{ color: 'primary.main' }} />
      </Stack>
    );
  };

  const renderStudentResponse = (
    response: { input_type: string; messages: string[] | OpenEndedMessage[] },
    index: number,
  ) => {
    if (response.input_type === 'open_ended') {
      const messages = response.messages as OpenEndedMessage[];
      return messages.map((message, i) => (
        // the data structure does not have a unique id for each message, so we use the index as the key
        // eslint-disable-next-line react/no-array-index-key
        <Grid item container key={i + message.message} sx={{ marginBottom: 2 }} direction='column'>
          <Grid item>{getSpeaker(message.speaker)}</Grid>
          <Grid item>
            <Typography variant='bodyMedium'>{message.message}</Typography>
          </Grid>
        </Grid>
      ));
    }
    const messages = response.messages as string[];
    return (
      <Grid item container sx={{ marginBottom: 2 }} direction='column' key={index}>
        <Grid item>
          <Typography variant='labelLarge'>{studentWork?.student}</Typography>
        </Grid>
        <Grid item>
          <Typography variant='bodyMedium'>{messages[0]}</Typography>
        </Grid>
      </Grid>
    );
  };

  const transcript = (
    <>
      {isFetching && <LoadingIndicator data-testid='loading-indicator' />}
      {isError && <Typography variant='titleSmall'>Error loading transcript.</Typography>}
      {data && (
        <Grid container direction='column' spacing={1}>
          {data.transcript.map((segment, index) => {
            if ('teacher_instructions' in segment) {
              return (
                // the data structure does not have a unique id for each message, so we use the index as the key
                // eslint-disable-next-line react/no-array-index-key
                <Grid item container key={index} sx={{ marginBottom: 2 }} direction='column'>
                  <Grid item>
                    <Typography variant='labelLarge'>{tutorName}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='bodyMedium'>{segment.teacher_instructions}</Typography>
                  </Grid>
                </Grid>
              );
            }
            return renderStudentResponse(segment.student_response, index);
          })}
        </Grid>
      )}
    </>
  );

  const misconceptionsLabel =
    studentWork?.misconceptions.length === 1
      ? '1 Misconception'
      : `${studentWork?.misconceptions.length} Misconceptions`;

  return (
    <Stack>
      {!studentWork && <Typography variant='titleSmall'>No student work found.</Typography>}
      {header}
      {usageTable}
      <Tabs value={currentTab} onChange={handleTabChange} sx={styles.tabs} variant='fullWidth'>
        <Tab label={misconceptionsLabel} value={TabOptions.MISCONCEPTIONS} />
        <Tab label='Transcript' value={TabOptions.TRANSCRIPT} data-testid='transcript-tab' />
      </Tabs>
      {currentTab === TabOptions.MISCONCEPTIONS && misconceptions}
      {currentTab === TabOptions.TRANSCRIPT && transcript}
    </Stack>
  );
};
