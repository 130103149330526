import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Stack,
} from '@mui/material';
import { useCreateSection } from 'controllers/react-query';
import { Add } from '@mui/icons-material';
import { KyronEvents } from '../../utils/KyronEvents';
import { useUserContext } from '../../UserContext';

export function AddSectionButton({ onCreateSection, disabled }: { onCreateSection?: () => void; disabled?: boolean }) {
  const { lessonId } = useParams<{ lessonId: string }>();
  const { user } = useUserContext();
  const [topic, setTopic] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [questionType, setQuestionType] = useState('open_ended');
  const { mutate, isPending, error } = useCreateSection(Number(lessonId));
  const navigate = useNavigate();

  function handleSave() {
    mutate(
      { payload: { lessonId: Number(lessonId), questionType, lessonSection: { topic } } },
      {
        onSuccess: newSection => {
          onCreateSection?.();
          navigate(`/studio/${newSection.lesson_id}/segments/${newSection.segments[0].id}`);
          setIsDialogOpen(false);
        },
      },
    );
  }

  return (
    <>
      <Dialog open={isDialogOpen} fullWidth>
        <DialogTitle>Add a new section</DialogTitle>
        <DialogContent>
          <Stack mt={2} gap={2}>
            <TextField
              label='Topic Name'
              value={topic}
              onChange={e => setTopic(e.target.value)}
              fullWidth
              autoFocus
              required
            />
            <Stack>
              <Typography id='create-section-question-type' variant='labelLarge'>
                Content Type
              </Typography>
              <RadioGroup
                aria-labelledby='create-section-question-type'
                value={questionType}
                onChange={e => setQuestionType(e.target.value)}
              >
                <FormControlLabel control={<Radio size='small' />} label='Lecture' value='lecture' />
                <FormControlLabel control={<Radio size='small' />} label='Conversational Question' value='open_ended' />
                <FormControlLabel
                  control={<Radio size='small' />}
                  label='Multiple Choice Question'
                  value='multiple_choice'
                />
              </RadioGroup>
            </Stack>
          </Stack>
          {error && <Alert severity='error'>{`Unable to create section: ${error.message}`}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSave();
              KyronEvents.sendEvent(`${questionType} section Created`, {
                lesson_id: Number(lessonId),
                user_id: user?.id || 'anonymous',
              });
            }}
            disabled={!topic || isPending}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        startIcon={<Add />}
        sx={{ borderRadius: 2 }}
        variant='text'
        onClick={() => setIsDialogOpen(true)}
        disabled={disabled}
      >
        Add section
      </Button>
    </>
  );
}
