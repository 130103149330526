import React from 'react';
import { Chip, Stack } from '@mui/material';
import { Lesson } from 'controllers/types';

enum LABELS {
  published = 'Public',
  unpublished = 'Unpublished Changes',
  draft = 'Draft',
  private = 'Private',
}

type StatusChipProps = {
  label: LABELS;
  color?: string;
};
const StatusChip = ({ label, color }: StatusChipProps) => (
  <Chip label={label} sx={{ bgcolor: color, boxShadow: 1, borderRadius: 1, fontWeight: 'bold' }} />
);

export function LessonStatusTags({ lesson }: { lesson?: Lesson }) {
  if (!lesson) {
    return null;
  }

  const publishedLessonCollection = lesson.published_lesson_collection;

  const isDraft = !publishedLessonCollection;
  const isPublic = publishedLessonCollection?.visibility === 'PUBLIC';
  const isPrivate = publishedLessonCollection?.visibility === 'PRIVATE';
  const hasUnpublishedChanges = Boolean(lesson.has_unpublished_changes);

  return (
    <Stack direction='row' gap={1}>
      {isDraft && <StatusChip label={LABELS.draft} color='surface.main' />}
      {isPublic && <StatusChip label={LABELS.published} color='successContainer.main' />}
      {isPrivate && <StatusChip label={LABELS.private} color='tertiaryContainer.main' />}
      {hasUnpublishedChanges && <StatusChip label={LABELS.unpublished} />}
    </Stack>
  );
}
