import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, DialogActions, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { usePublishLesson, useLessonValidateQuery, useRegenerateAllLessonVideos } from 'controllers/react-query';
import { PublishVisibility, SegmentMedia } from 'controllers/types';
import Grid2 from '@mui/material/Unstable_Grid2';
import { LockOpenOutlined, LockOutlined } from '@mui/icons-material';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { SplitButton } from 'components/SplitButton';
import { useModal } from 'components/utils/ModalContext';
import { useFeatures } from 'components/FeaturesContext';

type VisibilityOptionType = {
  id: number;
  type: PublishVisibility;
  icon: JSX.Element;
  title: string;
  description: string;
};

const VisibilityOptions: VisibilityOptionType[] = [
  {
    id: 1,
    type: PublishVisibility.PUBLIC,
    icon: <LockOpenOutlined />,
    title: 'Public',
    description:
      'The lesson is available publicly in the Marketplace and in your public Channel. You and anyone else can assign this lesson.',
  },
  {
    id: 2,
    type: PublishVisibility.PRIVATE,
    icon: <LockOutlined />,
    title: 'Private',
    description: 'The lesson is available to you and members of your organization to share and assign.',
  },
];

export function PublishVisibilityOptions({
  visibility,
  onUpdate,
}: {
  visibility: PublishVisibility;
  onUpdate: (visibility: PublishVisibility) => void;
}) {
  return (
    <Stack gap={2}>
      {VisibilityOptions.map((option: VisibilityOptionType) => (
        <Grid2
          key={option.id}
          container
          padding={2}
          columnSpacing={2}
          onClick={() => onUpdate(option.type)}
          sx={{
            border: 1,
            borderRadius: 1,
            borderColor: 'divider',
            '&:hover': {
              cursor: 'pointer',
              opacity: 0.6,
              bgcolor: 'primaryContainer.main',
            },
            bgcolor: visibility === option.type ? 'primaryContainer.main' : 'default',
          }}
        >
          <Grid2 xs={1} display='flex' alignItems='center' justifyContent='center' color='primary.main'>
            {option.icon}
          </Grid2>
          <Grid2 xs={11} display='flex'>
            <Stack>
              <Typography variant='titleSmall'>{option.title}</Typography>
              <Typography variant='bodyMedium'>{option.description}</Typography>
            </Stack>
          </Grid2>
        </Grid2>
      ))}
    </Stack>
  );
}

function PublishStudioLessonDialog({ lessonId, onClose }: { lessonId?: number; onClose: () => void }) {
  const {
    data: lessonValidations,
    refetch: refetchValidations,
    isFetching: loadingValidations,
    error: fetchValidationError,
  } = useLessonValidateQuery(lessonId);
  const { isPending: isPublishPending, error: publishLessonError, mutate: publishLesson } = usePublishLesson();
  const [visibility, setVisibility] = useState<PublishVisibility>(PublishVisibility.PUBLIC);

  function publishAndCloseDialog() {
    if (lessonId) {
      publishLesson(
        { lessonId, payload: { visibility } },
        {
          onSuccess: _data => {
            onClose();
          },
        },
      );
    }
  }

  const failingValidations = lessonValidations?.filter(validation => !validation.pass) || [];
  const hasFailingValidations = failingValidations.length > 0;
  useEffect(() => {
    refetchValidations?.();
  }, [refetchValidations]);

  return (
    <>
      <Typography variant='bodyMedium' mb={2}>
        Determine who can see and assign this lesson. You can change this lesson’s visibility at any time from Settings.
      </Typography>
      <PublishVisibilityOptions visibility={visibility} onUpdate={setVisibility} />

      {fetchValidationError && (
        <Box mt={2}>
          <Alert severity='error'>{fetchValidationError.message}</Alert>
        </Box>
      )}

      {publishLessonError && (
        <Box mt={2}>
          <Alert severity='error'>{publishLessonError.message}</Alert>
        </Box>
      )}

      {hasFailingValidations && (
        <Stack gap={1}>
          <Divider sx={{ my: 2 }} />
          <Typography variant='titleMedium'>Validation Errors:</Typography>
          <Typography variant='bodyMedium'>
            We have detected the following errors in your lesson. Please fix them in order to publish.
          </Typography>
          {failingValidations?.map(validation => (
            <Tooltip key={validation.name} title={validation.message}>
              <Alert key={validation.name} severity='error'>
                {validation.name}
              </Alert>
            </Tooltip>
          ))}
        </Stack>
      )}

      <DialogActions sx={{ pr: 0, pb: 1 }}>
        <Button variant='outlined' disabled={isPublishPending} onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={publishAndCloseDialog}
          disabled={isPublishPending || loadingValidations || hasFailingValidations}
          startIcon={isPublishPending || loadingValidations ? <LoadingIndicator spinnerSize={20} /> : null}
        >
          Publish
        </Button>
      </DialogActions>
    </>
  );
}

export function PublishStudioLessonButton({ lessonId, disabled }: { lessonId?: number; disabled?: boolean }) {
  const { mutate: regenerateAllLessonVideos, isPending: isRegenerationPending } = useRegenerateAllLessonVideos();
  const { openModal, closeModal } = useModal();
  const { synthesia_video_generation: synthesiaVideoGeneration, synthesia_use_templates: synthesiaUseTemplates } =
    useFeatures(); // Note(derick): not sure why we have both feature flags, but both are currently needed for synthesia to work
  const isSynthesiaEnabled = synthesiaVideoGeneration && synthesiaUseTemplates;

  function openPublishModal() {
    openModal({
      id: 'publish-lesson-modal',
      title: 'Publish Lesson?',
      content: <PublishStudioLessonDialog lessonId={Number(lessonId)} onClose={() => closeModal()} />,
    });
  }

  if (isSynthesiaEnabled) {
    return (
      <SplitButton
        submitOnClick
        options={[
          {
            label: 'Publish Lesson',
            onSelect: () => openPublishModal(),
          },
          {
            label: 'Regenerate All Videos',
            onSelect: () => {
              if (lessonId) {
                openModal({
                  id: 'regenerate-all-videos-modal',
                  title: 'Regenerate All Videos?',
                  content: (
                    <Typography>
                      Are you sure you want to regenerate all videos for this lesson? This may take a few minutes and
                      cannot be undone.
                    </Typography>
                  ),
                  onConfirm: () => {
                    if (lessonId) {
                      regenerateAllLessonVideos({
                        lessonId,
                        payload: {
                          mediaTypesToSkip: [SegmentMedia.USER_IMAGE_WITH_AUDIO, SegmentMedia.USER_VIDEO],
                          skipExisting: true,
                        },
                      });
                    }
                  },
                });
              }
            },
          },
        ]}
        disabled={disabled || isRegenerationPending}
      />
    );
  }

  return (
    <Button onClick={openPublishModal} disabled={disabled}>
      Publish Lesson
    </Button>
  );
}
