import React, { useState } from 'react';
import { Box, Button, Stack, Pagination, List, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Add } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useActions, useTitle } from 'components/StudioLayout/StudioLayout';
import { useGetLibraryLessonCollections } from 'controllers/react-query';
import { SearchField } from '../../SearchField';
import { NoResources } from '../../NoResources';
import { ListItemSkeleton } from '../components/ListItemSkeleton';
import { LibraryListItem } from './LibraryListItem/LibraryListItem';

const title = 'Library';

const actions = (
  <Button to='/studio/lesson-builder' component={Link} startIcon={<Add />}>
    New Lesson
  </Button>
);

export function Library(): React.JSX.Element {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { isFetching, data, isError } = useGetLibraryLessonCollections({ page, perPage: 25, search });
  const { lesson_collections: lessonCollections, meta } = data || {};
  const totalPages = meta?.total_pages;
  useTitle(title);
  useActions(actions);

  const searchField = (
    <SearchField
      placeholder='Search for lessons'
      sx={{ maxWidth: 400 }}
      searchTerm={search}
      onSearch={setSearch}
      isLoading={isFetching}
      searchError={isError ? 'Failed when searching for lessons' : null}
    />
  );

  const lessonsList = (
    <>
      <Table sx={{ typography: 'bodyMedium' }}>
        <TableHead>
          <TableRow>
            <TableCell aria-label='Loading State'>
              <Box sx={visuallyHidden}>Loading State</Box>
            </TableCell>
            <TableCell>Lesson</TableCell>
            <TableCell>Visibility</TableCell>
            <TableCell>Unpublished changes?</TableCell>
            <TableCell>Last updated</TableCell>
            <TableCell aria-label='Actions'>
              <Box sx={visuallyHidden}>Actions</Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lessonCollections?.map(lessonCollection => (
            <LibraryListItem key={lessonCollection.id} lessonCollection={lessonCollection} />
          ))}
        </TableBody>
      </Table>

      <Pagination
        sx={{ alignSelf: 'center', mt: 2 }}
        count={totalPages}
        shape='rounded'
        page={page}
        onChange={(_, newPage) => setPage(newPage)}
      />
    </>
  );

  const renderMainContent = () => {
    if (isFetching && !search) {
      return (
        <List sx={{ width: '100%' }}>
          <ListItemSkeleton rows={3} loading />
        </List>
      );
    }

    if (isError) {
      throw new Error('Error fetching lessons');
    }

    if (!lessonCollections?.length) {
      return (
        <NoResources
          message='Get started by creating your first lesson'
          action={
            <Button to='/studio/lesson-builder' component={Link} startIcon={<Add />}>
              New Lesson
            </Button>
          }
        />
      );
    }

    return lessonsList;
  };

  return (
    <Stack gap={4} mt={3}>
      {searchField}
      {renderMainContent()}
    </Stack>
  );
}
