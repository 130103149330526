import { CircularProgress } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { AutoAwesome } from '@mui/icons-material';
import { GenerateQuestionsPayload } from '../../../controllers/react-query/lessonGenerationHooks';
import { KyronClient } from '../../utils/KyronClient';
import { SpecialButton } from './SpecialButton';

type FeelingLuckyButtonProps = {
  setPromptParams: Dispatch<SetStateAction<GenerateQuestionsPayload>>;
};

const client = new KyronClient();

export function FeelingLuckyButton({ setPromptParams }: FeelingLuckyButtonProps) {
  const [loading, setLoading] = useState(false);

  const generateTitleAudienceObjectives = () => {
    if (loading) return;
    setLoading(true);

    client
      .getDataWithError<{ title: string; audience: string; objectives: string }>(
        '/api/v1/feeling_lucky/generate_title_audience_objectives',
      )
      .then(data => {
        setPromptParams(prevParams => ({
          ...prevParams,
          lessonTitle: data.title,
          audience: data.audience,
          lessonObjective: data.objectives,
        }));
      })
      .catch(error => {
        console.error('Failed to fetch title, audience, and objectives:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <SpecialButton
      startIcon={loading ? null : <AutoAwesome />}
      onClick={generateTitleAudienceObjectives}
      disabled={loading}
      sx={{ width: '300px', height: '48px' }}
    >
      {loading ? <CircularProgress size={24} color='inherit' /> : 'Feeling lucky?'}
    </SpecialButton>
  );
}
