/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, ChangeEvent, useEffect } from 'react';
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { ACCEPT_VIDEO_TYPES, ACCEPT_IMAGE_TYPES, FileUploader, UploadButton } from 'components/Uploaders/FileUploader';
import { useUpdateSegment } from 'controllers/react-query';
import { LessonSegment, SegmentMedia } from 'controllers/types';
import { enqueueSnackbar } from 'notistack';
import { FilePreview } from 'components/Uploaders/FilePreview';
import { RemoveFileButton } from 'components/Uploaders/RemoveFileButton';
import { ActiveStorageBlob } from 'components/Uploaders/types';
import { VideoRecorderButton } from 'components/Uploaders/VideoRecorderButton';
import { useFeatures } from 'components/FeaturesContext';
import { KyronEvents } from 'components/utils/KyronEvents';
import { useUserContext } from 'components/UserContext';
import { buildSegmentVideoUrlWithDefiniteEnding } from '../../utils/urlUtils';

type Props = {
  getSelection: (selection: SegmentMedia) => void;
  segment: LessonSegment;
  regenerateKyronVideo: (mediaType: SegmentMedia) => Promise<void>;
  sectionId?: number;
  isLoading?: boolean;
  /**
   * Renders elements stacked over each other as opposed to side by side
   */
  columnLayout?: boolean;
};

export const SegmentMediaSelection = ({
  getSelection,
  segment,
  regenerateKyronVideo,
  sectionId,
  isLoading,
  columnLayout = false,
}: Props) => {
  const { show_video_recorder: showVideoRecorder } = useFeatures();
  const features = useFeatures();
  const isSynthesiaEnabled = features.synthesia_use_templates && features.synthesia_video_generation;
  const [$$selection, $$setSelection] = useState<SegmentMedia>(segment?.media_type || SegmentMedia.KYRON_VIDEO);
  const [mediaTypeInProgress, setMediaTypeInProgress] = useState<SegmentMedia | null>(null);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const [attachedVideoId, setAttachedVideoId] = useState<string | null | undefined>();
  const [attachedImageId, setAttachedImageId] = useState<string | null | undefined>();

  const { mutateAsync: updateSegment } = useUpdateSegment({
    segmentId: segment?.id,
    sectionId,
  });

  const [existingVideoFile, setExistingVideoFile] = useState<ActiveStorageBlob | null>(null);
  const [existingImageFile, setExistingImageFile] = useState<ActiveStorageBlob | null>(null);
  const { user } = useUserContext();

  const setExistingFile = (s: LessonSegment) => {
    if (s.media_type === SegmentMedia.USER_VIDEO) {
      const videoURL = buildSegmentVideoUrlWithDefiniteEnding(s);
      setExistingVideoFile({ url: videoURL, media_type: s.media_type });
    }

    if (s.media_type === SegmentMedia.USER_IMAGE_WITH_AUDIO) {
      setExistingImageFile({ url: s.image_url, media_type: s.media_type });
    }
  };

  const clearExistingFiles = () => {
    setExistingVideoFile(null);
    setExistingImageFile(null);
  };

  const attachVideoToSegment = (blobs: ActiveStorageBlob[]) => {
    const blob = blobs[0];
    if (!blob.signed_id) return;

    clearExistingFiles();
    setMediaTypeInProgress(SegmentMedia.USER_VIDEO); // processing starts here
    setIsFormDisabled(true);
    updateSegment({
      lessonId: segment.lesson_id,
      payload: { ...segment, video: blob.signed_id, media_type: SegmentMedia.USER_VIDEO },
    })
      .then(updatedSegment => {
        setExistingFile(updatedSegment);
        setAttachedVideoId(blob.signed_id);
      })
      .catch(() => {
        enqueueSnackbar('Something went wrong when uploading file. Try again.', { variant: 'error' });
      })
      .finally(() => {
        setMediaTypeInProgress(null); // processing ends here
        setIsFormDisabled(false);
      });
  };

  const attachImageToSegment = (blobs: ActiveStorageBlob[]) => {
    const blob = blobs[0];
    if (!blob.signed_id) return;

    clearExistingFiles();
    setMediaTypeInProgress(SegmentMedia.USER_IMAGE_WITH_AUDIO); // processing starts here
    setIsFormDisabled(true);
    updateSegment({
      lessonId: segment.lesson_id,
      payload: { ...segment, image_signed_id: blob.signed_id, media_type: SegmentMedia.USER_IMAGE_WITH_AUDIO },
    })
      .then(updatedSegment => {
        setExistingFile(updatedSegment);
        setAttachedImageId(blob.signed_id);
        KyronEvents.sendEvent('Uploaded Image', {
          user_id: user?.id || 'anonymous',
          lesson_id: segment.lesson_id,
        });
      })
      .catch(() => {
        enqueueSnackbar('Something went wrong when uploading file. Try again.', { variant: 'error' });
      })
      .finally(() => {
        setMediaTypeInProgress(null); // processing ends here
        setIsFormDisabled(false);
      });
  };

  const handleForceRegenerateVideo = async () => {
    setIsFormDisabled(true); // disable the form while we are regenerating the kyron video

    await updateSegment({
      lessonId: segment.lesson_id,
      payload: { ...segment, media_type: SegmentMedia.KYRON_VIDEO, forceRegenerate: true },
    })
      .catch(() => {
        enqueueSnackbar('Something went wrong when regenerating video. Try again.', { variant: 'error' });
      })
      .finally(() => {
        setIsFormDisabled(false);
      });
  };

  const goBackToKyronGeneratedVideo = async (selectedValue?: SegmentMedia) => {
    // If there is not user uploaded file, that means we did not swap the original video yet
    // This can happen when user selects different options in radio button group
    // In this case, we do not need to regenerate the kyron video
    if (!existingVideoFile && !attachedVideoId && !existingImageFile && !attachedImageId) return;

    if ($$selection !== SegmentMedia.KYRON_VIDEO) $$setSelection(SegmentMedia.KYRON_VIDEO);

    // ======= CLEAN UP EXISTING FILE STATE =======
    // we should clean up the existing file state so that upload button is back to its initial state
    setAttachedVideoId(null);
    setExistingVideoFile(null);

    setAttachedImageId(null);
    setExistingImageFile(null);

    // ======= REGENERATE KYRON VIDEO =======
    // regenerate the kyron video and attach it to segment
    setIsFormDisabled(true); // disable the form while we are regenerating the kyron video
    const mediaToUse = selectedValue || SegmentMedia.KYRON_VIDEO; // default to Kyron Video if nothing is selected
    await regenerateKyronVideo(mediaToUse as SegmentMedia);
    setIsFormDisabled(false); // enable it back
  };

  const handleSelect = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    $$setSelection(value as SegmentMedia);
    // in case of null here, kyron video is generated
    if (value === SegmentMedia.KYRON_VIDEO || value == null) goBackToKyronGeneratedVideo(value);
  };

  // Side effect - when internal selection state $$selection changes, broadcast it to parent component
  useEffect(broadcastSelectionChange, [getSelection, $$selection]);
  function broadcastSelectionChange() {
    getSelection($$selection);
  }

  // Side effect - Handle existing file update per changed segment
  useEffect(updateExistingFile, [segment?.media_type]);
  function updateExistingFile() {
    if (segment?.media_type !== SegmentMedia.KYRON_VIDEO) setExistingFile(segment);
  }

  // Side effect - Handle existing selection type
  // This will sync the state of component with the server's state of segment's media_type
  useEffect(updateSelectionPerExistingMedia, [segment?.media_type]);
  function updateSelectionPerExistingMedia() {
    if (segment?.media_type) $$setSelection(segment.media_type);
  }

  return (
    <Box>
      <Typography id='media-selection-radio-buttons-label' variant='titleSmall'>
        Media
      </Typography>
      <FormControl disabled={isFormDisabled || isLoading}>
        <RadioGroup
          aria-labelledby='media-selection-radio-buttons-label'
          value={$$selection}
          name='media-selection-radio-buttons'
          onChange={handleSelect}
        >
          {/* KYRON_VIDEO OPTION */}
          {isSynthesiaEnabled ? (
            <Stack direction={columnLayout ? 'column' : 'row'}>
              <FormControlLabel
                value={SegmentMedia.KYRON_VIDEO}
                componentsProps={{ typography: { variant: 'bodyMedium' } }}
                control={<Radio size='small' inputProps={{ 'aria-label': 'default generated kyron video' }} />}
                label='Slide'
                sx={{ color: 'text.secondary' }}
              />
              <Button
                variant='outlined'
                onClick={handleForceRegenerateVideo}
                startIcon={<Refresh />}
                disabled={isFormDisabled || isLoading || $$selection !== SegmentMedia.KYRON_VIDEO}
              >
                Regenerate video
              </Button>
            </Stack>
          ) : (
            <FormControlLabel
              value={SegmentMedia.KYRON_VIDEO}
              componentsProps={{ typography: { variant: 'bodyMedium' } }}
              control={<Radio size='small' inputProps={{ 'aria-label': 'default generated kyron video' }} />}
              label='Slide'
              sx={{ color: 'text.secondary' }}
            />
          )}

          {/* USER VIDEO OPTION */}
          <Stack direction={columnLayout ? 'column' : 'row'}>
            <FormControlLabel
              value={SegmentMedia.USER_VIDEO}
              componentsProps={{ typography: { variant: 'bodyMedium' } }}
              control={<Radio size='small' inputProps={{ 'aria-label': 'user video' }} />}
              label='Video'
              sx={{ color: 'text.secondary' }}
            />
            <FileUploader
              disabled={$$selection !== SegmentMedia.USER_VIDEO}
              fileTypeName='video'
              afterUpload={attachVideoToSegment}
              isAttachmentBeingProcessed={mediaTypeInProgress === SegmentMedia.USER_VIDEO}
              setFormDisabled={() => setIsFormDisabled(true)}
              acceptTypes={ACCEPT_VIDEO_TYPES}
              existingFile={existingVideoFile}
            >
              <FilePreview />
              <UploadButton buttonLabel={attachedVideoId ? 'Replace video' : 'Choose video'} />
              {showVideoRecorder ? (
                <VideoRecorderButton buttonLabel='Record video' recordingScript={segment.description} />
              ) : null}
              <RemoveFileButton onRemove={goBackToKyronGeneratedVideo} />
            </FileUploader>
          </Stack>

          {/* USER IMAGE OPTION */}
          <Stack direction={columnLayout ? 'column' : 'row'}>
            <FormControlLabel
              value={SegmentMedia.USER_IMAGE_WITH_AUDIO}
              componentsProps={{ typography: { variant: 'bodyMedium' } }}
              control={<Radio size='small' inputProps={{ 'aria-label': 'user image' }} />}
              label='Image'
              sx={{ color: 'text.secondary' }}
            />
            <FileUploader
              disabled={$$selection !== SegmentMedia.USER_IMAGE_WITH_AUDIO}
              fileTypeName='image'
              afterUpload={attachImageToSegment}
              isAttachmentBeingProcessed={mediaTypeInProgress === SegmentMedia.USER_IMAGE_WITH_AUDIO}
              setFormDisabled={() => setIsFormDisabled(true)}
              acceptTypes={ACCEPT_IMAGE_TYPES}
              existingFile={existingImageFile}
            >
              <FilePreview />
              <UploadButton buttonLabel={attachedImageId ? 'Replace image' : 'Choose image'} />
              <RemoveFileButton onRemove={goBackToKyronGeneratedVideo} />
            </FileUploader>
          </Stack>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
