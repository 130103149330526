import { ListItemIcon, ListItemText, MenuItem, MenuItemProps } from '@mui/material';
import React from 'react';

export function KyronMenuItem<C extends React.ElementType>({
  icon,
  label,
  ...menuItemProps
}: MenuItemProps<C, { component?: C; icon: React.ReactNode; label: React.ReactNode }>) {
  return (
    <MenuItem {...menuItemProps}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </MenuItem>
  );
}
