import React, { useState } from 'react';
import { Typography, Box, Drawer, TextField, Chip } from '@mui/material';
import { IRowNode } from 'ag-grid-community';
import { Warning, Timelapse, Done, Search } from '@mui/icons-material';
import { useStudentWorkBreakdown } from 'controllers/react-query/classroomMisconceptionReportHooks';
import { StudentWork, Misconception } from 'controllers/types';
import { DataGrid } from '../common/DataGrid';
import { StudentPanel } from './StudentPanel';
import { LoadingIndicator } from '../LoadingIndicator';
import { Row } from '../Row/Row';

const styles = {
  title: {
    marginBottom: '10px',
    marginTop: '30px',
  },
  studentProgressReport: {
    height: '75vh',
    marginTop: '10px',
  },
  currentSection: {
    backgroundColor: 'primaryContainer.main',
    padding: '10px',
  },
  notCurrentSection: {
    backgroundColor: 'transparent',
    padding: '10px',
  },
  nameStack: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  warningTooltip: {
    marginRight: '10px',
  },
};

type Props = {
  classroomId?: string;
  lessonCollectionId?: string;
  assignmentName: string;
};

type RowContent = Omit<StudentWork, 'student'> & { student: StudentWork };

enum Filter {
  finished = 'Finished',
  inProgress = 'In Progress',
  misconceptions = 'Misconceptions',
}

export const StudentProgressView = ({ classroomId, lessonCollectionId, assignmentName }: Props) => {
  const [shouldShowStudentResponseTable, setShouldShowStudentResponseTable] = useState(false);
  const [lessonInstanceId, setLessonInstanceId] = useState(0);
  const [individualStudentWork, setIndividualStudentWork] = useState<StudentWork | undefined>(undefined);
  const { isFetching, isError, data: studentWorkBreakdown } = useStudentWorkBreakdown(classroomId, lessonCollectionId);
  const textCellStyle = { whiteSpace: 'pre', wordBreak: 'normal', overflow: 'auto' };
  const [filterText, setFilterText] = useState('');
  const [selectedFilter, setSelectedFilter] = useState<Filter | null>(null);

  const filters = [
    { name: Filter.finished, label: 'Finished' },
    { name: Filter.inProgress, label: 'In Progress' },
    { name: Filter.misconceptions, label: 'Misconceptions' },
  ];

  const sensitiveInputRenderer = (params: { value: boolean }) =>
    params.value ? (
      <Warning color='error' sx={{ display: 'flex', justifyContent: 'center' }} data-testid='sensitive-warning' />
    ) : null;

  const statusRenderer = (params: { value: string }) => (
    <Row gap={1}>
      {params.value === 'Finished' ? <Done color='success' /> : <Timelapse color='warning' />}
      <Typography>{params.value}</Typography>
    </Row>
  );

  const studentFormatter = (params: { value: StudentWork }) => params.value.student;

  const studentRenderer = (params: { value: StudentWork }) => {
    const student = params.value;
    return <Typography color={student.has_sensitive_input ? 'error' : 'textPrimary'}>{student.student}</Typography>;
  };

  const misconceptionValueFormatter = (params: { value: Misconception[] }) => {
    const count = params.value.length;
    return `${count} misconception${count === 1 ? '' : 's'}`;
  };

  const studentProgressColumns = [
    {
      field: 'has_sensitive_input',
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      resizable: false,
      cellStyle: { padding: '0px', display: 'flex', justifyContent: 'center' },
      cellRenderer: sensitiveInputRenderer,
    },
    {
      field: 'student',
      headerName: 'Student',
      minWidth: 200,
      cellStyle: textCellStyle,
      valueFormatter: studentFormatter,
      cellRenderer: studentRenderer,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 140,
      cellStyle: textCellStyle,
      cellRenderer: statusRenderer,
    },
    {
      field: 'time_spent',
      headerName: 'Time Spent',
      minWidth: 140,
      cellStyle: textCellStyle,
    },
    {
      field: 'finished_at',
      headerName: 'Finished At',
      minWidth: 100,
      cellStyle: textCellStyle,
    },
    {
      field: 'misconceptions',
      headerName: 'Misconceptions',
      minWidth: 160,
      cellStyle: textCellStyle,
      valueFormatter: misconceptionValueFormatter,
    },
  ];

  const studentProgressRows = studentWorkBreakdown?.map(student => ({
    has_sensitive_input: student.has_sensitive_input,
    student,
    status: student.status,
    time_spent: student.time_spent,
    finished_at: student.finished_at,
    misconceptions: student.misconceptions,
    lesson_instance_id: student.lesson_instance_id,
  }));

  const handleStudentClick = (e: { data: RowContent }) => {
    setShouldShowStudentResponseTable(true);
    setIndividualStudentWork(e.data.student);
    setLessonInstanceId(e.data.student.lesson_instance_id);
  };

  const onFilterTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value);
  };

  const isExternalFilterPresent = selectedFilter !== null;

  const doesExternalFilterPass = (node: IRowNode<RowContent>) => {
    const student = node.data;
    if (!student) {
      return true;
    }
    const { status, misconceptions } = student;
    if (selectedFilter === Filter.finished && status !== 'Finished') return false;
    if (selectedFilter === Filter.inProgress && status !== 'In Progress') return false;
    if (selectedFilter === Filter.misconceptions && misconceptions.length === 0) return false;
    return true;
  };

  return (
    <div>
      {isFetching && <LoadingIndicator message='Loading...' />}
      {isError && <Typography variant='bodyMedium'>There was an error loading student progress.</Typography>}
      {studentWorkBreakdown && (
        <Drawer
          anchor='right'
          data-testid='student-panel'
          open={shouldShowStudentResponseTable}
          onClose={() => setShouldShowStudentResponseTable(false)}
          PaperProps={{
            sx: {
              width: '400px',
              padding: 3,
            },
          }}
          // This is a workaround to remove the backdrop from the drawer while
          // still allowing the drawer to close when the user clicks outside of it
          ModalProps={{
            slotProps: {
              backdrop: { sx: { background: 'none' } },
            },
          }}
        >
          <StudentPanel
            assignmentName={assignmentName}
            lessonInstanceId={lessonInstanceId}
            studentWork={individualStudentWork}
            closePanel={() => setShouldShowStudentResponseTable(false)}
          />
        </Drawer>
      )}
      <Row gap={1}>
        <TextField
          sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}
          label='Search for students'
          variant='outlined'
          onChange={onFilterTextChange}
          size='small'
          InputProps={{
            startAdornment: <Search sx={{ color: 'divider' }} />,
          }}
          value={filterText}
          data-testid='student-search'
        />
        {filters.map(filter => (
          <Chip
            key={filter.name}
            label={filter.label}
            variant={selectedFilter === filter.name ? 'filled' : 'outlined'}
            data-testid={filter.name}
            onClick={() =>
              setSelectedFilter(prev => {
                if (prev === filter.name) return null;
                return filter.name;
              })
            }
          />
        ))}
      </Row>
      <Box sx={styles.studentProgressReport}>
        <DataGrid
          rows={studentProgressRows}
          columns={studentProgressColumns}
          noRowsText='No students have started this lesson yet'
          onRowClicked={handleStudentClick}
          filterText={filterText}
          doesExternalFilterPass={doesExternalFilterPass}
          isExternalFilterPresent={isExternalFilterPresent}
        />
      </Box>
    </div>
  );
};
