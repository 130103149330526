import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import googleClassroomLogo from '../../assets/Google_Classroom_Logo.svg';
import { KyronImage } from '../KyronImage/KyronImage';
import signInBackground from '../../../assets/images/signInBackground.png';

type Props = {
  open: boolean;
  classroomId: string;
  googleClassroomId: string;
  provider: string;
  actAsPage?: boolean;
};

/**
 * Dialog to sign in with Google Account for classroom assignment access
 * If `actAsPage` is true, it will show a background image making modal look like a page,
 * otherwise, it is going to be a modal.
 */
export const SignInDialog = ({ open, classroomId, googleClassroomId, provider, actAsPage = false }: Props) => {
  let authLink = '/users/auth/google_classroom_student';
  authLink += `?classroom_id=${classroomId}&google_classroom_id=${googleClassroomId}&provider=${provider}`;
  authLink += `&redirect_to=${window.location.pathname}`;

  return (
    <>
      {actAsPage && <KyronImage size='100%' src={signInBackground} alt='sign in background' objectFit='cover' />}
      <Dialog open={open} hideBackdrop={actAsPage}>
        <DialogTitle>Welcome to Kyron Learning!</DialogTitle>
        <DialogContent>
          <p>Please sign in with Google to begin this lesson.</p>
        </DialogContent>
        <DialogActions>
          <Button
            component='a'
            href={authLink}
            data-method='post'
            data-testid='sign-in-with-google-classroom'
            startIcon={<img height='24px' src={googleClassroomLogo} alt='Login with Google' />}
          >
            Sign in with Google
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
