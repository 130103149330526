import React, { ChangeEvent, createContext, useContext, useEffect, useState } from 'react';
import { DirectUploadProvider } from '@quorak/react-activestorage-provider';
import { UploadFile } from '@mui/icons-material';
import { CircularProgress, Button, ButtonProps } from '@mui/material';
import { useSnackbar } from 'notistack';
import { VisuallyHiddenInput } from '../VisuallyHiddenInput';
import { Row } from '../Row/Row';
import { ActiveStorageBlob, DerivedRenderProps, DirectUploaderRenderProps } from './types';

export const ACCEPT_ALL_TEXT_FILES = '.doc, .docx, .txt, .pdf';
export const ACCEPT_VIDEO_TYPES = 'video/mp4, video/quicktime, video/webm'; // .mp4, .mov, .webm
export const ACCEPT_IMAGE_TYPES = 'image/png, image/jpeg, image/jpg';

export type FileUploaderProps = {
  children: React.ReactNode;
  multiple?: boolean;
  acceptTypes: string;
  disabled?: boolean;
  afterUpload: (blobs: ActiveStorageBlob[]) => void | React.Dispatch<React.SetStateAction<string[]>>;
  /**
   * If defined, uploader will look at this to determine whether to show file name and remove button
   */
  isAttachmentBeingProcessed?: boolean;
  /**
   * **Exclusively for FileUploader internal state, children should not derive from these**
   */
  existingFile?: ActiveStorageBlob | null;
  /**
   * **Exclusively for FileUploader internal state, children should not derive from these**
   */
  existingFiles?: ActiveStorageBlob[] | null;
  /**
   * When defined, this function will be called to disable the form that this uploader is part of during uploads
   * Note that it will only make a call to disable the form. It is intentionally setup this way because enabling
   * the form might be wanted to be done after afterUpload callback.
   */
  setFormDisabled?: () => void;
  /**
   * The type of file being uploaded. Will be used in the UI to label the buttons and form the ids.
   * Defaults to 'file'
   */
  fileTypeName?: 'file' | 'video' | 'image';
}; // The props that are passed to all children uploader elements

export type UploaderElementProps = Partial<FileUploaderProps & DirectUploaderRenderProps & DerivedRenderProps>;

type FileUploaderContextValue = Partial<
  Omit<
    FileUploaderProps,
    // following props are exclusively for FileUploader internal state,
    // children should not derive from these
    'children' | 'existingFiles' | 'existingFile'
  >
> & {
  /**
   * Files state in FileUploader
   */
  files: ActiveStorageBlob[] | null;
  /**
   * Files state setter in FileUploader
   */
  setFiles: React.Dispatch<React.SetStateAction<ActiveStorageBlob[] | null>>;
  removeFile: (signedId: string) => void;
  isThereAnExistingFile: boolean;
};

// FileUploaderContext is used to pass props to children elements
export const FileUploaderContext = createContext<FileUploaderContextValue | null>(null);

/**
 * FileUploader is a component that allows the user to upload a files. Gives them the ability to preview the file uploaded and remove it.
 *
 * @example
 * The component is used like this:
 * ```
 * <FileUploader {...somePropsThatConcernsMainFunctionality}>
 *   <FilePreview />
 *   <UploadButton buttonLabel={...} />
 *   <RemoveButton  onRemove={...} />
 * </FileUploader>
 * ```
 */
export const FileUploader = (fileUploaderProps: FileUploaderProps) => {
  const {
    acceptTypes,
    disabled: $$disabled = false,
    afterUpload,
    isAttachmentBeingProcessed = false,
    existingFile,
    existingFiles,
    setFormDisabled,
    fileTypeName = 'file',
    multiple = false,
    children,
  } = fileUploaderProps;
  const [$$files, $$setFiles] = useState<ActiveStorageBlob[] | null>(null);
  const isThereAnExistingFile = !!$$files?.length;

  // When there is an existing file passed to FileUploader, update the internal file state
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setExistingFiles, [JSON.stringify(existingFiles), JSON.stringify(existingFile)]);
  function setExistingFiles() {
    // If incoming existing file is empty, then clear the files
    if ($$files && !existingFiles && !existingFile) $$setFiles(null);
    // But if files are already set, and incoming existing file is not empty, don't override files state
    if ($$files) return;
    // If there are existing files, set them
    if (existingFiles) $$setFiles(existingFiles);
    else if (existingFile) $$setFiles([existingFile]);
  }

  const handleAttachment = (blobs: ActiveStorageBlob[]) => {
    if (multiple) {
      $$setFiles(p => [...(p || []), ...blobs]);
    } else {
      $$setFiles(blobs);
    }

    // Invoke afterUpload callback - this achieves the further processing of the files in parent component
    afterUpload(blobs);
  };

  const removeFile = (signedId: string) => {
    // Remove file from the list of files to be attached on lesson for creation
    $$setFiles(p => p?.filter(file => file.signed_id !== signedId) || null);
  };

  return (
    <FileUploaderContext.Provider
      // Doing what ESLint suggest here is too verbose for not very much gain, so I'm disabling it
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        setFormDisabled,
        setFiles: $$setFiles,
        removeFile,
        isAttachmentBeingProcessed,
        isThereAnExistingFile,
        acceptTypes,
        files: $$files,
        fileTypeName,
        multiple,
      }}
    >
      <DirectUploadProvider
        fullAttributes
        onSuccess={handleAttachment}
        render={(props: DirectUploaderRenderProps) => {
          const shouldBeDisabled = !props.ready || $$disabled || isAttachmentBeingProcessed;
          const isUploadInProgress = props?.uploads?.some(upload => upload.state === 'uploading');

          // Cloning all the children to pass props of FileUploader (parent)
          return React.Children.map(children, (child: React.ReactNode) => {
            if (React.isValidElement(child)) {
              // Clone child with parent props, hence, all FileUploader props will be available to children
              return React.cloneElement(child, {
                ...props,
                ...fileUploaderProps,
                shouldBeDisabled,
                isUploadInProgress,
              } as UploaderElementProps);
            }

            return child;
          });
        }}
      />
    </FileUploaderContext.Provider>
  );
};

type UploadButtonProps = {
  buttonLabel?: string;
  onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  variant?: ButtonProps['variant'];
  sx?: ButtonProps['sx'];
};
export function UploadButton({
  // DirectUploaderRenderProps
  handleUpload,
  uploads,
  // DerivedRenderProps
  shouldBeDisabled,
  isUploadInProgress,
  // FileUploaderProps
  multiple,
  buttonLabel = `Upload file`,
  onInputChange,
  variant = 'outlined',
  sx,
}: UploaderElementProps & UploadButtonProps) {
  const uploaderCtx = useContext(FileUploaderContext);
  const { enqueueSnackbar } = useSnackbar();
  const uploadingFileCount = uploads?.length || 1;
  // This is the cumulative progress of all uploads if there are multiple files being uploaded
  // This is just a step towards making the component handle multiple files when needed
  const cumulativeUploadProgress = uploads?.reduce((acc, upload) => acc + (upload?.progress || 0), 0) || 0;
  const cumulativeUploadPercentage = `${Math.round(cumulativeUploadProgress / uploadingFileCount)}%`;

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (onInputChange) onInputChange(e);

    uploaderCtx?.setFormDisabled?.();
    try {
      await handleUpload?.(e.target.files!);
      e.target.value = ''; // without this, input won't trigger onChange event if the same file is selected again
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Something went wrong when uploading file. Try again.', { variant: 'error' });
    }
  };
  const getLabel = () => {
    if (isUploadInProgress) return cumulativeUploadPercentage;
    if (uploaderCtx?.isAttachmentBeingProcessed) return 'Processing...';
    if (uploaderCtx?.isThereAnExistingFile && multiple) return `Upload more ${uploaderCtx?.fileTypeName}s`;
    if (uploaderCtx?.isThereAnExistingFile) return `Replace ${uploaderCtx?.fileTypeName}`;
    if (multiple) return 'Upload files';
    return buttonLabel;
  };

  const getIcon = () => {
    if (isUploadInProgress || uploaderCtx?.isAttachmentBeingProcessed)
      return <CircularProgress size='1em' sx={{ color: t => t.palette.action.disabled }} />;
    return <UploadFile />;
  };

  return (
    <Row maxWidth='600px' gap={2}>
      <Button
        component='label'
        sx={{ minWidth: '156px', ...sx }}
        startIcon={getIcon()}
        disabled={shouldBeDisabled}
        variant={variant}
        className='upload-button'
      >
        {getLabel()}
        <VisuallyHiddenInput
          type='file'
          multiple={multiple}
          accept={uploaderCtx?.acceptTypes}
          disabled={shouldBeDisabled}
          onChange={handleInputChange}
          data-testid='file-uploader-input'
        />
      </Button>
    </Row>
  );
}
