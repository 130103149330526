import React, { useEffect, useRef } from 'react';
import { Alert, Box, Button, ListItemIcon, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import { MoreVert, Cached, DeleteOutlined } from '@mui/icons-material';
import { useSegmentQuery, useUpdateSegment } from 'controllers/react-query';
import { SegmentMedia } from 'controllers/types';
import { Row } from 'components/Row/Row';
import { useSnackbar } from 'notistack';
import { useModal } from 'components/utils/ModalContext';
import { KyronEvents } from 'components/utils/KyronEvents';
import { useDeleteSegment } from 'controllers/react-query/lessonSegmentHooks';
import { useUserContext } from 'components/UserContext';
import { SEGMENT_TYPE_LABEL } from 'enums/segmentTypeLabel';
import { getDetailedSegmentType } from 'components/utils/segmentType';
import { KyronMenu } from '../../KyronMenu';
import { useSectionListEditorContext } from './SectionListEditor';
import { SegmentMediaSelection } from '../components/SegmentMediaSelection';
import { StepSegmentFields, WaitSegmentFields } from './SegmentFields';
import { useWatchSegmentBackgroundTasks } from '../../BackgroundTasks/useProgressBarChannel';
import { SegmentDetailSkeleton } from './SectionListEditorSkeleton';
import { SegmentMediaDisplay } from './SegmentMediaDisplay';

export function SegmentDetail() {
  const { topic, segmentId, segmentType, segmentCountInSection } = useSectionListEditorContext() || {};
  const { data: currentSegment, error, refetch: refetchSegment } = useSegmentQuery(segmentId);

  const { hasActiveBackgroundTask } = useWatchSegmentBackgroundTasks({
    segment: currentSegment,
    onSuccess: refetchSegment,
    onError: err => {
      console.error('Error in video generation', err, currentSegment?.background_tasks);
      refetchSegment();
    },
  });

  const updateSegmentMutation = useUpdateSegment({ segmentId });
  const { mutate: deleteSegment } = useDeleteSegment();

  const videoUrl = currentSegment?.video_url;
  const { enqueueSnackbar } = useSnackbar();
  const { closeModal, openModal } = useModal();
  const isPending = updateSegmentMutation.isPending || hasActiveBackgroundTask;
  const isWaitSegment = segmentType === 'wait';
  const isStepSegment = segmentType === 'step' || segmentType === 'question';
  const { user } = useUserContext();
  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    // Scroll to the top of the page or top of the segment detail on first render
    if (window.scrollY > 200) {
      titleRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scroll(0, 0);
    }
  }, []);

  const updateSegmentAndVideo = async (mediaType: SegmentMedia, description: string) => {
    if (!currentSegment) return;
    await updateSegmentMutation.mutateAsync({
      lessonId: currentSegment.lesson_id,
      payload: { ...currentSegment, description, media_type: mediaType },
    });
    refetchSegment();
  };

  function openDeleteModal() {
    if (!currentSegment) return;
    const handleDelete = () => {
      deleteSegment(
        {
          segmentId,
          lessonId: currentSegment.lesson_id.toString(),
        },
        {
          onSuccess: () => {
            closeModal();
          },
          onError: deleteError => {
            const errorTitle = `Failed to delete segment`;
            console.error(errorTitle, segmentId, deleteError.message);
            enqueueSnackbar(errorTitle, { variant: 'error' });
          },
        },
      );
    };

    openModal({
      id: 'delete-segment',
      title: 'Delete Segment',
      content: 'Are you sure you want to delete this segment?',
      action: (
        <Stack direction='row' spacing={2}>
          <Button variant='outlined' onClick={closeModal}>
            Cancel
          </Button>
          <Button
            color='error'
            data-testid='confirm-delete-segment'
            onClick={() => {
              handleDelete();
              KyronEvents.sendEvent('Segment Deleted', {
                segment_id: segmentId,
                lesson_id: currentSegment.lesson_id,
                user_id: user?.id || 'anonymous',
              });
            }}
          >
            Delete
          </Button>
        </Stack>
      ),
    });
  }

  const editSegmentMenu = () => (
    <Box ml={-5} data-testid='EditSectionMenu'>
      <KyronMenu menuIcon={<MoreVert />}>
        <MenuItem onClick={() => refetchSegment()}>
          <ListItemIcon>
            <Cached fontSize='small' />
          </ListItemIcon>
          <ListItemText>Refresh Segment</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => openDeleteModal()} sx={{ color: 'red' }} disabled={isPending}>
          <ListItemIcon>
            <DeleteOutlined />
          </ListItemIcon>
          <ListItemText>Delete Segment</ListItemText>
        </MenuItem>
      </KyronMenu>
    </Box>
  );

  return (
    <Stack gap={3} sx={{ alignItems: 'stretch', flex: 1, scrollMarginTop: '64px' }} ref={titleRef}>
      {/* Error Display */}
      {error ? <Alert severity='error'>{error.message}</Alert> : null}

      {/* Segment Editor */}
      {currentSegment ? (
        <>
          <Row>
            {/* DeleteSegment is disabled if there is only one segment in the section to avoid empty sections. Hiding the menu in that case as DeleteSegment is currently the only menu item */}
            {segmentCountInSection > 1 && editSegmentMenu()}
            <Stack>
              <Typography variant='labelSmall'>{topic}</Typography>
              <Typography variant='headlineMedium'>
                {SEGMENT_TYPE_LABEL[getDetailedSegmentType(currentSegment)]}
              </Typography>
            </Stack>
          </Row>

          <Row
            direction={{ xs: 'column', md: 'row' }}
            gap={{ xs: 4, md: 2 }}
            sx={{ alignItems: { xs: 'stretch', md: 'flex-start' } }}
          >
            {/* Media Display */}
            <SegmentMediaDisplay isPending={isPending} videoUrl={videoUrl} />

            {/* Media Type */}
            <Box sx={{ flex: '0 0 200px', width: '200px' }}>
              <SegmentMediaSelection
                getSelection={() => {}}
                segment={currentSegment}
                regenerateKyronVideo={mediaType => updateSegmentAndVideo(mediaType, currentSegment.description)}
                columnLayout
                isLoading={isPending}
              />
            </Box>
          </Row>

          <Row direction={{ xs: 'column', md: 'row' }} gap={{ xs: 4, md: 2 }} sx={{ alignItems: 'stretch' }}>
            {/* Segment Fields */}
            <Box sx={{ flex: '1' }}>
              {isStepSegment && <StepSegmentFields segment={currentSegment} isLoading={isPending} />}
              {isWaitSegment && <WaitSegmentFields segment={currentSegment} isLoading={isPending} />}
            </Box>
            {/* Empty box for future segment options */}
            <Box sx={{ flex: '0 0 200px' }} />
          </Row>
        </>
      ) : (
        <SegmentDetailSkeleton />
      )}
    </Stack>
  );
}
