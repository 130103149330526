import { DeleteOutline } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { useContext } from 'react';
import { FileUploaderContext, UploaderElementProps } from './FileUploader';

type RemoveFileButtonProps = { onRemove: () => void };

export function RemoveFileButton({ onRemove, shouldBeDisabled }: UploaderElementProps & RemoveFileButtonProps) {
  const uploaderCtx = useContext(FileUploaderContext);

  return !uploaderCtx?.isAttachmentBeingProcessed && uploaderCtx?.isThereAnExistingFile ? (
    <Button
      data-testid='file-uploader-remove-button'
      startIcon={<DeleteOutline />}
      color='error'
      variant='outlined'
      onClick={() => onRemove?.()}
      disabled={shouldBeDisabled}
      sx={{ maxWidth: '156px' }}
    >
      Remove {uploaderCtx?.fileTypeName}
    </Button>
  ) : null;
}
