import React, { useState } from 'react';
import { Button, IconButton, Link, Menu, Stack, SxProps, Typography } from '@mui/material';
import { logOut } from 'components/utils/logOut';
import { KyronAvatar } from 'components/KyronAvatar/KyronAvatar';
import { User } from 'controllers/types';
import { Close, Logout } from '@mui/icons-material';
import { Row } from 'components/Row/Row';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
  user: User;
  sx?: SxProps;
};

export function AccountMenu({ user, sx }: Props) {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        sx={sx}
        data-testid='account-menu-button'
        onClick={handleClick}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        <KyronAvatar label={user.name} image={user.avatar_url} size={48} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        MenuListProps={{ disablePadding: true }}
        data-testid='account-menu'
      >
        <Stack spacing={2} alignItems='center' sx={{ width: 320, py: 3 }}>
          <Typography variant='bodySmall' color='textSecondary'>
            {user.email}
          </Typography>
          <KyronAvatar label={user.name} image={user.avatar_url} size={120} />
          <Typography variant='titleLarge'>Hi, {user.first_name}!</Typography>
          <Button variant='outlined' onClick={logOut} startIcon={<Logout />} data-testid='sign-out'>
            Sign out
          </Button>
          <Row gap={2}>
            <Link component={RouterLink} to='/terms_of_service' variant='bodySmall' color='text.secondary'>
              Terms
            </Link>
            <Link component={RouterLink} to='/privacy_policy' variant='bodySmall' color='text.secondary'>
              Privacy
            </Link>
          </Row>
        </Stack>
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', top: 12, right: 12 }}
          data-testid='close-menu-button'
        >
          <Close />
        </IconButton>
      </Menu>
    </>
  );
}
