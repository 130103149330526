// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`LessonContext Renders renders data 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-y: scroll;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
}

.emotion-0>:not(style):not(style) {
  margin: 0;
}

.emotion-0>:not(style)~:not(style) {
  margin-top: 16px;
}

.emotion-1 {
  margin: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.emotion-2 {
  margin: 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.emotion-3 {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  max-width: 400px;
}

.emotion-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
}

.emotion-4.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-4.Mui-selected {
  background-color: rgba(75, 53, 255, 0.08);
}

.emotion-4.Mui-selected.Mui-focusVisible {
  background-color: rgba(75, 53, 255, 0.2);
}

.emotion-4.Mui-disabled {
  opacity: 0.38;
}

.emotion-5 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  border: 1px solid #D6D5DD;
  background-image: none;
  border-radius: 24px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 8px;
  width: 100%;
}

.emotion-6 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 2.1875rem;
  color: #49474B;
  margin-left: 8px;
  margin-top: 8px;
}

.emotion-7 {
  padding: 16px;
  width: 100%;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.emotion-7:last-child {
  padding-bottom: 24px;
}

.emotion-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-10 {
  margin: 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #1C1B1FB3;
  margin-left: 8px;
}

.emotion-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px;
}

.emotion-11>:not(style)~:not(style) {
  margin-left: 8px;
}

.emotion-12 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-12::-moz-focus-inner {
  border-style: none;
}

.emotion-12.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-12 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-12:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-12:hover {
    background-color: transparent;
  }
}

.emotion-12.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-13 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-14 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-15 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 5px;
  font-size: 1.125rem;
}

.emotion-15::-moz-focus-inner {
  border-style: none;
}

.emotion-15.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-15 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-15:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-15:hover {
    background-color: transparent;
  }
}

.emotion-15.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

<div
    class="MuiStack-root emotion-0"
  >
    <span
      class="MuiTypography-root MuiTypography-labelMedium emotion-1"
    >
      Uploaded content
    </span>
    <p
      class="MuiTypography-root MuiTypography-bodySmall emotion-2"
    >
      Uploaded content is used by Kyron's AI benders to enrich the lesson generation process.
    </p>
    <ul
      class="MuiList-root MuiList-padding emotion-3"
    >
      <li
        class="MuiListItem-root MuiListItem-gutters emotion-4"
      >
        <div
          class="MuiPaper-root MuiPaper-outlined MuiPaper-rounded MuiCard-root emotion-5"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-colorInfo MuiSvgIcon-fontSizeLarge emotion-6"
            data-testid="InsertDriveFileIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm7 7V3.5L18.5 9z"
            />
          </svg>
          <div
            class="MuiCardContent-root emotion-7"
          >
            <div
              class="MuiStack-root emotion-8"
            >
              <span
                class="MuiTypography-root MuiTypography-labelMedium emotion-1"
              >
                aux_file.pdf
              </span>
              <p
                class="MuiTypography-root MuiTypography-bodySmall emotion-10"
              >
                (0.05 MB)
              </p>
            </div>
          </div>
          <div
            class="MuiCardActions-root MuiCardActions-spacing emotion-11"
          >
            <a
              data-testid="download-file"
              download="aux_file.pdf"
              href="https://example.com/aux_file.pdf"
              rel="noreferrer"
              style="text-decoration: none;"
              target="_blank"
            >
              <button
                aria-label="Download file"
                class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-12"
                data-mui-internal-clone-element="true"
                tabindex="0"
                type="button"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-13"
                  data-testid="DownloadIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M5 20h14v-2H5zM19 9h-4V3H9v6H5l7 7z"
                  />
                </svg>
                <span
                  class="MuiTouchRipple-root emotion-14"
                />
              </button>
            </a>
            <button
              aria-label="See processing reports"
              class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall emotion-15"
              data-mui-internal-clone-element="true"
              data-testid="show-process-reports"
              tabindex="0"
              type="button"
            >
              <svg
                aria-hidden="true"
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-13"
                data-testid="ManageSearchIcon"
                focusable="false"
                viewBox="0 0 24 24"
              >
                <path
                  d="M7 9H2V7h5zm0 3H2v2h5zm13.59 7-3.83-3.83c-.8.52-1.74.83-2.76.83-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5c0 1.02-.31 1.96-.83 2.75L22 17.59zM17 11c0-1.65-1.35-3-3-3s-3 1.35-3 3 1.35 3 3 3 3-1.35 3-3M2 19h10v-2H2z"
                />
              </svg>
              <span
                class="MuiTouchRipple-root emotion-14"
              />
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</DocumentFragment>
`;
