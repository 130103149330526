import React, { useEffect, useRef, useState } from 'react';
import { TextField, IconButton } from '@mui/material';
import { Mic, ArrowUpward, StopCircleOutlined } from '@mui/icons-material';
import { TranscriptionController, AudioMetadata } from 'controllers/transcription/types';
import { TranscriptionControllerFactory } from 'controllers/transcription/transcriptionControllerFactory';
import { KyronEvents } from 'components/utils/KyronEvents';
import { VoiceInputField } from './VoiceInputField';

type MicButtonProps = {
  startRecording: () => void;
};
function MicButton({ startRecording }: MicButtonProps) {
  return (
    <IconButton
      aria-label='Microphone On'
      data-testid='microphone-input'
      color='secondary'
      onClick={startRecording}
      size='small'
    >
      <Mic />
    </IconButton>
  );
}

type SubmitButtonProps = {
  handleSubmit: () => void;
};
function SubmitButton({ handleSubmit }: SubmitButtonProps) {
  return (
    <IconButton
      aria-label='submit response'
      sx={{
        bgcolor: 'primary.main',
        color: 'primary.contrastText',
        '&:hover': {
          bgcolor: 'primary.light',
        },
      }}
      onClick={handleSubmit}
      size='small'
    >
      <ArrowUpward />
    </IconButton>
  );
}

type StopButtonProps = {
  handleStopAssistant: () => void;
  chatId: number;
};
function StopButton({ handleStopAssistant, chatId }: StopButtonProps) {
  return (
    <IconButton
      aria-label='stop assistant button'
      sx={{ color: 'primary.main' }}
      onClick={() => {
        KyronEvents.sendEvent('Stop Assistant', {
          chat_id: chatId,
        });
        handleStopAssistant();
      }}
      size='small'
      data-testid='stop-button'
    >
      <StopCircleOutlined />
    </IconButton>
  );
}

type ChatInputFieldProps = {
  chatId: number;
  currentMessage: string;
  setCurrentMessage: React.Dispatch<React.SetStateAction<string>>;
  sendMessage: (msg: string) => void;
  isAssistantResponding?: boolean;
  handleStopAssistant: () => void;
};
export function ChatInputField({
  chatId,
  currentMessage,
  setCurrentMessage,
  sendMessage,
  isAssistantResponding,
  handleStopAssistant,
}: ChatInputFieldProps) {
  const speechToTextControllerRef = useRef<TranscriptionController | null>(null);
  const [isMicRecording, setIsMicRecording] = useState(false);
  const [audioMetadata, setAudioMetadata] = useState<AudioMetadata | null>(null);

  // Cleanup the audio transcription controller
  useEffect(resetStateAndCleanup, [chatId]);
  function resetStateAndCleanup() {
    setIsMicRecording(false);
    return () => {
      speechToTextControllerRef.current?.pause();
      speechToTextControllerRef.current?.stop();
    };
  }

  // Initialize the audio transcription controller on mount
  useEffect(initializeTranscription, []);
  function initializeTranscription() {
    speechToTextControllerRef.current = TranscriptionControllerFactory.create(
      () => {}, // no need to handle partial transcripts
      () => {}, // no need to handle final transcripts
      setAudioMetadata,
    );
    speechToTextControllerRef.current.initiate(() => {}); // no post-initiate callback needed
  }

  // When the user submits their question, we want to stop transcribing audio
  useEffect(pauseTranscription, [isMicRecording]);
  function pauseTranscription() {
    if (speechToTextControllerRef.current) {
      speechToTextControllerRef.current.pause(!isMicRecording);
    }
  }

  const handleStartRecording = () => {
    // Reset the recorder so we don't include any audio from before they started speaking
    setIsMicRecording(true);
    console.debug('resetting transcription');
    speechToTextControllerRef.current?.reset?.();
  };

  const handleStopRecording = () => {
    setIsMicRecording(false);
    speechToTextControllerRef.current
      ?.awaitTranscription()
      .then((transcription: string | void) => {
        if (transcription) {
          setCurrentMessage(transcription);
        }
      })
      .catch((err: Error) => {
        console.error(err);
      })
      .finally(() => {
        speechToTextControllerRef.current?.pause();
      });
  };

  const submitAndReset = () => {
    sendMessage(currentMessage);
    setCurrentMessage('');
  };

  const handleCancelRecording = () => {
    setIsMicRecording(false);
    speechToTextControllerRef.current?.pause();
    setCurrentMessage('');
  };

  const handleEnterKey = (e: React.KeyboardEvent) => {
    if (e.key !== 'Enter') return;
    if (currentMessage && isAssistantResponding) {
      handleStopAssistant(); // user is trying to interrupt the assistant
    } else if (currentMessage) {
      submitAndReset(); // user is submitting a message
    }
  };

  const getEndOfInputAdornment = () => {
    if (isAssistantResponding) {
      return <StopButton handleStopAssistant={handleStopAssistant} chatId={chatId} />;
    }
    if (currentMessage) {
      return <SubmitButton handleSubmit={submitAndReset} />;
    }
    return <MicButton startRecording={handleStartRecording} />;
  };

  return isMicRecording && audioMetadata ? (
    <VoiceInputField
      audioMetadata={audioMetadata}
      saveRecording={handleStopRecording}
      cancelRecording={handleCancelRecording}
    />
  ) : (
    <TextField
      sx={{ flex: 1 }}
      data-testid='response-input'
      InputProps={{
        sx: { borderRadius: 4 },
        readOnly: false,
        'aria-label': 'student response',
        endAdornment: getEndOfInputAdornment(),
      }}
      placeholder={isMicRecording ? '' : 'Enter your response here'}
      onChange={e => setCurrentMessage(e.target.value)}
      onKeyDown={handleEnterKey}
      value={isMicRecording ? '' : currentMessage}
      autoFocus
    />
  );
}
