import * as React from 'react';
import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Language } from '@mui/icons-material';
import { KyronImage } from 'components/KyronImage/KyronImage';
import { Lesson } from 'controllers/types';
import { styled } from '@mui/material/styles';
import { Locales } from '../utils/locales';
import KyronIcon from '../../assets/kyronicon-small.svg';
import { getSearchParamStr } from '../utils/urlUtils';

const TEACHER_CARD_WIDTH = 256;
const TeacherCard = styled(Stack)(({ theme }) => ({
  borderRadius: 12,
  padding: 8,
  width: TEACHER_CARD_WIDTH,
  flex: '0 0 auto',
  textDecoration: 'none',
  cursor: 'pointer',
  backgroundColor: theme.palette.surfaceContainer.main,
  transition: 'background-color 150ms linear',
  '&:hover': {
    backgroundColor: theme.palette.surfaceContainer.dark,
  },
  [theme.breakpoints.down('md')]: {
    width: TEACHER_CARD_WIDTH * 0.65,
  },
}));

type LessonSelectionCardProps = {
  lesson: Lesson;
  onClose?: () => void;
  changeLesson?: (id: number) => void;
};

export function LessonSelectionCard({ lesson, onClose, changeLesson }: LessonSelectionCardProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const downMediumScreen = useMediaQuery((t: Theme) => t.breakpoints.down('md'));

  return (
    <TeacherCard
      onClick={() => {
        if (changeLesson) {
          changeLesson(lesson.id);
        } else {
          const lessonLink = `/video_player/${lesson.id}${getSearchParamStr(searchParams)}`;
          navigate(lessonLink);
        }
        onClose?.();
      }}
      data-testid={`teacher-selection-card-${lesson.id}`}
    >
      <KyronImage
        size='100%'
        src={lesson.tutor?.picture}
        fallbackSrc={KyronIcon}
        alt={`tutor-thumbnail-${lesson.tutor?.display_name}`}
        dataTestID='thumbnail'
      />
      <Stack padding='16px 8px 8px' sx={{ fontSize: downMediumScreen ? '14px' : '16px', fontWeight: 600 }}>
        <Typography sx={{ fontSize: '1em', lineHeight: '1rem' }}>{lesson.tutor?.display_name}</Typography>
        <Stack direction='row' gap={1} alignItems='center'>
          <Language sx={{ fontSize: '0.8em', lineHeight: '1.2em' }} />
          <Typography sx={{ fontSize: '0.8em', lineHeight: '1.5em' }}>{Locales[lesson.locale]}</Typography>
        </Stack>
      </Stack>
    </TeacherCard>
  );
}
