import { KyronClient } from './KyronClient';
import { KyronEvents } from './KyronEvents';

export function logOut() {
  KyronEvents.sendEvent('User Signout');
  const client = new KyronClient();
  client.deleteData('/users/sign_out').then(() => {
    window.location.reload();
  });
}
