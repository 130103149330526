// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`MisconceptionsView renders the dataGrid 1`] = `
.emotion-0 {
  width: 100%;
  height: 75vh;
  margin-top: 10px;
}

.emotion-1 {
  height: 100%;
  width: 100%;
  --ag-header-foreground-color: rgba(0, 0, 0, 0.87);
  --ag-borders-critical: none;
  --ag-border-color: rgba(0, 0, 0, 0.12);
}

<div
  className="MuiBox-root emotion-0"
  data-testid="misconceptions-table"
>
  <div
    aria-label="data-rows"
    className="ag-theme-material emotion-1"
  >
    <div
      style={
        {
          "height": "100%",
        }
      }
    />
  </div>
</div>
`;

exports[`MisconceptionsView renders the error message 1`] = `
.emotion-0 {
  width: 100%;
  height: 75vh;
  margin-top: 10px;
}

.emotion-1 {
  height: 100%;
  width: 100%;
  --ag-header-foreground-color: rgba(0, 0, 0, 0.87);
  --ag-borders-critical: none;
  --ag-border-color: rgba(0, 0, 0, 0.12);
}

<div
  className="MuiBox-root emotion-0"
  data-testid="misconceptions-table"
>
  <div
    aria-label="data-rows"
    className="ag-theme-material emotion-1"
  >
    <div
      style={
        {
          "height": "100%",
        }
      }
    />
  </div>
</div>
`;

exports[`MisconceptionsView renders the loading overlay 1`] = `
.emotion-0 {
  width: 100%;
  height: 75vh;
  margin-top: 10px;
}

.emotion-1 {
  height: 100%;
  width: 100%;
  --ag-header-foreground-color: rgba(0, 0, 0, 0.87);
  --ag-borders-critical: none;
  --ag-border-color: rgba(0, 0, 0, 0.12);
}

<div
  className="MuiBox-root emotion-0"
  data-testid="misconceptions-table"
>
  <div
    aria-label="data-rows"
    className="ag-theme-material emotion-1"
  >
    <div
      style={
        {
          "height": "100%",
        }
      }
    />
  </div>
</div>
`;

exports[`MisconceptionsView switches from a loading state to a data state 1`] = `
.emotion-0 {
  width: 100%;
  height: 75vh;
  margin-top: 10px;
}

.emotion-1 {
  height: 100%;
  width: 100%;
  --ag-header-foreground-color: rgba(0, 0, 0, 0.87);
  --ag-borders-critical: none;
  --ag-border-color: rgba(0, 0, 0, 0.12);
}

<div
  className="MuiBox-root emotion-0"
  data-testid="misconceptions-table"
>
  <div
    aria-label="data-rows"
    className="ag-theme-material emotion-1"
  >
    <div
      style={
        {
          "height": "100%",
        }
      }
    />
  </div>
</div>
`;
