import React, { useState } from 'react';
import { Typography, Stack, Divider, TextField, Box } from '@mui/material';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { KyronImage } from 'components/KyronImage/KyronImage';
import { ActiveStorageBlob } from 'components/Uploaders/types';
import { FileUploader, UploadButton, ACCEPT_IMAGE_TYPES } from 'components/Uploaders/FileUploader';
import { UpdateBar } from './UpdateBar';
import { useSettingsTitle, useLessonSettingsDialogContext } from './LessonSettingsDialog';
import { useLessonFromParams } from '../../../controllers/react-query';

export const GeneralSettings = () => {
  useSettingsTitle('General');

  const { isFetching, isRefetching, isError, data } = useLessonFromParams();
  const [title, setTitle] = useState(data?.name);
  const [description, setDescription] = useState(data?.description);
  const [thumbnailUrl, setThumbnailUrl] = useState(data?.lesson_collection.custom_thumbnail);
  const [signedId, setSignedId] = useState<string | null>(null);
  const { updateLesson, isUpdatePending } = useLessonSettingsDialogContext();
  const isTitleChanged = title && title !== data?.name;
  const isDescriptionChanged = description && description !== data?.description;
  const isThumbnailChanged = signedId;
  const anyUnsavedChanges = Boolean(isTitleChanged || isDescriptionChanged || isThumbnailChanged);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = e => {
        const newImageUrl = e.target?.result as string;
        setThumbnailUrl(newImageUrl);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const afterUpload = (blobs: ActiveStorageBlob[]) => {
    const blob = blobs[0];
    if (!blob.signed_id) return;
    setSignedId(blob.signed_id);
  };

  const onUpdate = () => {
    updateLesson({
      name: title,
      description,
      lesson_collection: {
        custom_thumbnail: signedId || undefined,
      },
    });
    setSignedId(null);
  };

  return (
    <>
      <Box sx={{ px: 4, pb: 10, pt: 2 }}>
        {isFetching && !isRefetching && <LoadingIndicator message='Loading lesson settings...' />}
        {isError && <Typography variant='bodySmall'>Error loading lesson</Typography>}
        {data && (
          <Stack gap={2}>
            <Stack gap={1}>
              <Typography variant='labelMedium' component='label' htmlFor='lesson-title'>
                Lesson Title
              </Typography>
              <TextField
                variant='outlined'
                id='lesson-title'
                size='small'
                fullWidth
                data-testid='lesson-title'
                multiline
                defaultValue={data.name}
                onChange={e => setTitle(e.target.value)}
                sx={{ backgroundColor: 'background.default' }}
              />
            </Stack>
            <Divider />
            <Stack gap={1}>
              <Typography variant='labelMedium' component='label' htmlFor='lesson-description'>
                Lesson Description
              </Typography>
              <TextField
                variant='outlined'
                id='lesson-description'
                size='small'
                fullWidth
                data-testid='lesson-description'
                multiline
                defaultValue={data.description}
                onChange={e => setDescription(e.target.value)}
                sx={{ backgroundColor: 'background.default' }}
                inputProps={{ maxLength: 255 }}
              />
            </Stack>
            <Divider />
            <Stack gap={1}>
              <Typography variant='labelMedium'>Cover Image</Typography>
              <Typography variant='bodySmall'>
                Upload an image. It will show up in the marketplace and on your assignments.
              </Typography>
              <Box
                sx={{
                  width: '320px',
                  height: '180px',
                  position: 'relative',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  '&:hover .upload-button': {
                    opacity: 1,
                  },
                }}
                border={1}
                borderColor='divider'
                borderRadius={1}
              >
                <KyronImage
                  stretch
                  src={thumbnailUrl || data.lesson_collection?.custom_thumbnail}
                  alt='lesson collection thumbnail'
                  dataTestID='lesson-thumbnail'
                />
                <Stack sx={{ position: 'absolute', alignItems: 'center' }} gap={1}>
                  <FileUploader
                    afterUpload={afterUpload}
                    acceptTypes={ACCEPT_IMAGE_TYPES}
                    existingFile={{ url: thumbnailUrl }}
                  >
                    <UploadButton
                      onInputChange={handleInputChange}
                      buttonLabel='Replace file'
                      variant='raised'
                      sx={{ opacity: 0 }}
                    />
                    {/* TODO: Add remove functionality
                      <RemoveFileButton onRemove={removeImage} variant='raised' />
                    */}
                  </FileUploader>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        )}
      </Box>

      <UpdateBar isPending={isUpdatePending} onUpdate={onUpdate} unsavedChanges={anyUnsavedChanges} />
    </>
  );
};
