import { DetailedSegmentType, LessonSegment, MinimalSegment } from 'controllers/types';

export function getDetailedSegmentType(segment: LessonSegment | MinimalSegment): DetailedSegmentType {
  if (segment.segment_type !== 'wait') return segment.segment_type;

  if (segment.acceptable_inputs.includes('multiple_choice')) {
    return 'multiple_choice';
  }

  if (segment.acceptable_inputs.includes('open_ended')) {
    return 'conversation';
  }

  return 'wait';
}
