import React from 'react';
import { Card, CardContent, CardActions, IconButton, List, ListItem, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Download, ManageSearch, InsertDriveFile } from '@mui/icons-material';
import { useSettingsTitle } from '../LessonSettingsDialog/LessonSettingsDialog';
import { useLessonQuery } from '../../../controllers/react-query';
import { Row } from '../../Row/Row';
import { AuxContent } from '../../../controllers/types';
import { useModal } from '../../utils/ModalContext';
import { KyronTooltip } from '../../KyronTooltip';
import { LoadingIndicator } from '../../LoadingIndicator';

export function LessonContext() {
  useSettingsTitle('Lesson Context');

  const { lessonId } = useParams();
  const { data: lesson, isError, isFetching } = useLessonQuery(Number(lessonId));
  const auxContent = lesson?.aux_content;

  return (
    <Stack spacing={2} sx={{ overflowY: 'scroll', px: 4, pt: 2 }}>
      <Typography variant='labelMedium'>Uploaded content</Typography>
      <Typography variant='bodySmall'>
        Uploaded content is used by Kyron{`'`}s AI benders to enrich the lesson generation process.
      </Typography>

      {isError && (
        <Typography variant='bodySmall' color='error'>
          Failed to fetch uploaded content
        </Typography>
      )}

      {isFetching && (
        <Typography variant='bodySmall'>
          <LoadingIndicator loading={isFetching} message='Loading...' />
        </Typography>
      )}

      {auxContent?.length ? (
        <List sx={{ maxWidth: 400 }}>
          {auxContent.map(auxFile => (
            <ListItem key={auxFile.id}>
              <AuxContentDisplay auxFile={auxFile} />
            </ListItem>
          ))}
        </List>
      ) : null}

      {!auxContent?.length && !isFetching && (
        <Typography variant='bodySmall' color='text.secondary'>
          No file was uploaded for this lesson
        </Typography>
      )}
    </Stack>
  );
}

function AuxContentDisplay({ auxFile }: { auxFile: AuxContent }) {
  const { openModal } = useModal();

  const showProcessReports = () => {
    openModal({
      id: 'process-reports',
      title: 'Process reports',
      content: (
        <Stack spacing={2} sx={{ width: 400, p: 2 }}>
          <Typography variant='bodySmall' component='pre' data-testid='process-reports-text'>
            {JSON.stringify(auxFile.process_reports, null, 2)}
          </Typography>
        </Stack>
      ),
    });
  };

  return (
    <Card variant='outlined' sx={{ display: 'flex', borderRadius: 1, width: '100%' }}>
      <InsertDriveFile fontSize='large' color='info' sx={{ ml: 1, mt: 1 }} />
      <CardContent sx={{ width: '100%', alignSelf: 'center' }}>
        <Row>
          <Typography variant='labelMedium'>{auxFile.filename}</Typography>
          <Typography variant='bodySmall' color='text.secondary' ml={1}>
            ({bytesToMegabytes(auxFile.byte_size)} MB)
          </Typography>
        </Row>
      </CardContent>
      <CardActions>
        <a
          href={auxFile.url}
          download={auxFile.filename}
          target='_blank'
          style={{ textDecoration: 'none' }}
          rel='noreferrer'
          data-testid='download-file'
        >
          <KyronTooltip title='Download file'>
            <IconButton>
              <Download />
            </IconButton>
          </KyronTooltip>
        </a>
        <KyronTooltip title='See processing reports'>
          <IconButton size='small' onClick={showProcessReports} data-testid='show-process-reports'>
            <ManageSearch />
          </IconButton>
        </KyronTooltip>
      </CardActions>
    </Card>
  );
}

function bytesToMegabytes(byteSize: number, decimalPlaces = 2) {
  const megabytes = byteSize / (1024 * 1024);
  return parseFloat(megabytes.toFixed(decimalPlaces));
}
