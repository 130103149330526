import React, { useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  Stack,
  Select,
  MenuItem,
  Typography,
  DialogContent,
  DialogActions,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LessonSection } from 'controllers/types';
import { useCreateLessonSegment } from 'controllers/react-query';
import { KyronEvents } from 'components/utils/KyronEvents';
import { useUserContext } from 'components/UserContext';

type SegmentVariants = 'lecture' | 'multiple_choice' | 'open_ended' | 'question';
type AddSegmentDialogProps = {
  section: LessonSection;
  onClose: () => void;
};

export function AddSegmentDialog({ section, onClose }: AddSegmentDialogProps) {
  const { user } = useUserContext();
  const [segmentVariant, setSegmentVariant] = useState<SegmentVariants>('open_ended');
  const { mutate: createSegment, isPending, error } = useCreateLessonSegment();
  const navigate = useNavigate();

  const { lesson_id: lessonId, id: sectionId } = section;

  function handleCreateSegment() {
    KyronEvents.sendEvent(`${segmentVariant} Segment Created`, {
      lesson_id: lessonId,
      user_id: user?.id || 'anonymous',
    });
    createSegment(
      { lessonId, payload: { sectionId, segmentVariant } },
      {
        onSuccess: newSegment => {
          navigate(`/studio/${newSegment.lesson_id}/segments/${newSegment.id}`);
          onClose();
        },
      },
    );
  }

  return (
    <>
      <DialogContent>
        <Stack gap={2}>
          <Typography>Add media and interactive content to the current section.</Typography>
          <FormControl>
            <InputLabel id='segment-type-label'>Content Type</InputLabel>
            <Select
              labelId='segment-type-label'
              label='Segment Type'
              value={segmentVariant}
              onChange={e => setSegmentVariant(e.target.value as SegmentVariants)}
            >
              <MenuItem value='lecture'>Lecture</MenuItem>
              <MenuItem value='question'>Question</MenuItem>
              <MenuItem value='multiple_choice'>Multiple Choice</MenuItem>
              <MenuItem value='open_ended'>Conversation</MenuItem>
            </Select>
          </FormControl>
          {error && <Alert severity='error'>{error.message}</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant='text'>
          Cancel
        </Button>
        <Button onClick={handleCreateSegment} disabled={isPending}>
          Create
        </Button>
      </DialogActions>
    </>
  );
}
