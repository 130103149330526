// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`LessonAssignmentList renders the drawer correctly 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 16px;
}

.emotion-0>:not(style):not(style) {
  margin: 0;
}

.emotion-0>:not(style)~:not(style) {
  margin-top: 16px;
}

.emotion-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-2 {
  margin: 0;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.emotion-3 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-3::-moz-focus-inner {
  border-style: none;
}

.emotion-3.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-3 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-3:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-3:hover {
    background-color: transparent;
  }
}

.emotion-3.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-4 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-5 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.emotion-6>:not(style):not(style) {
  margin: 0;
}

.emotion-6>:not(style)~:not(style) {
  margin-left: 16px;
}

.emotion-7 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
}

.emotion-7 .MuiOutlinedInput-root {
  border-radius: 30px;
  padding: 0 8px 0 12px;
  height: 40px;
}

.emotion-7 .MuiInputLabel-root {
  line-height: 1.3em;
}

.emotion-7 .MuiOutlinedInput-input:-webkit-autofill {
  padding-left: 1em;
  height: 0.3em;
}

.emotion-7 .MuiFormHelperText-root {
  font-size: 11px;
  margin-top: 0;
}

.emotion-8 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 8px;
  padding-left: 14px;
  padding-right: 14px;
}

.emotion-8.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-8:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-8:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-8.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-8.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-8.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 0.01em;
  max-height: 2em;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 8px;
  position: relative;
  height: 100%;
}

.emotion-11 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  padding-left: 0;
  padding-right: 0;
}

.emotion-11::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-11::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-11:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-11::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-11:focus {
  outline: 0;
}

.emotion-11:invalid {
  box-shadow: none;
}

.emotion-11::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-11::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-11::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-11:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-11::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-11:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-11:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-11:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-11:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-11.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-11:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-11:-webkit-autofill {
  border-radius: inherit;
}

.emotion-12 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 5px;
  font-size: 1.125rem;
  opacity: 0;
  -webkit-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}

.emotion-12::-moz-focus-inner {
  border-style: none;
}

.emotion-12.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-12 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-12:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-12:hover {
    background-color: transparent;
  }
}

.emotion-12.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-13 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}

.emotion-15 {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.emotion-16 {
  float: unset;
  width: auto;
  overflow: hidden;
  padding: 0;
  line-height: 11px;
  -webkit-transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.emotion-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow: scroll;
  border-radius: 8px;
}

.emotion-17>:not(style):not(style) {
  margin: 0;
}

.emotion-17>:not(style)~:not(style) {
  margin-top: 8px;
}

.emotion-18 {
  display: grid;
  place-items: center;
  grid-template-columns: 40px 1fr;
  gap: 8px;
}

.emotion-19 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #4B35FF;
}

.emotion-19::-moz-focus-inner {
  border-style: none;
}

.emotion-19.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-19 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-19:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-19:hover {
    background-color: transparent;
  }
}

.emotion-19:hover {
  background-color: rgba(75, 53, 255, 0.04);
}

@media (hover: none) {
  .emotion-19:hover {
    background-color: transparent;
  }
}

.emotion-19.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-22 {
  width: 94%;
  background-color: #F1ECF4;
  border-radius: 8px;
}

.emotion-23 {
  width: 100%;
  height: 100%;
  padding: 12px;
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-template-rows: 100%;
  gap: 16px;
}

.emotion-24 {
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: white;
}

.emotion-25 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.emotion-25>:not(style):not(style) {
  margin: 0;
}

.emotion-25>:not(style)~:not(style) {
  margin-top: 4px;
}

.emotion-26 {
  margin: 0;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.emotion-27 {
  margin: 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.emotion-39 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.emotion-40 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box;
  min-width: 32px;
  height: 32px;
  padding: 0 6px;
  margin: 0 3px;
  color: #1C1B1F;
  -webkit-transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-40::-moz-focus-inner {
  border-style: none;
}

.emotion-40.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-40 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-40.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-40.Mui-disabled {
  opacity: 0.38;
}

.emotion-40:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-40:hover {
    background-color: transparent;
  }
}

.emotion-40.Mui-selected {
  background-color: rgba(0, 0, 0, 0.08);
}

.emotion-40.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

@media (hover: none) {
  .emotion-40.Mui-selected:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}

.emotion-40.Mui-selected.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.2);
}

.emotion-40.Mui-selected.Mui-disabled {
  opacity: 1;
  color: #1C1B1F61;
  background-color: rgba(0, 0, 0, 0.08);
}

.emotion-40.Mui-selected.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-41 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  font-size: 1.25rem;
  margin: 0 -8px;
}

<div
    class="MuiStack-root emotion-0"
  >
    <div
      class="MuiStack-root emotion-1"
    >
      <h2
        class="MuiTypography-root MuiTypography-titleLarge emotion-2"
      >
        Assign a Lesson
      </h2>
      <button
        class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-3"
        data-testid="close-drawer"
        tabindex="0"
        type="button"
      >
        <svg
          aria-hidden="true"
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-4"
          data-testid="CloseIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
        </svg>
        <span
          class="MuiTouchRipple-root emotion-5"
        />
      </button>
    </div>
    <div
      class="MuiStack-root emotion-6"
    >
      <div
        class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root emotion-7"
        data-testid="search-field"
      >
        <div
          class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedStart MuiInputBase-adornedEnd emotion-8"
        >
          <div
            class="MuiInputAdornment-root MuiInputAdornment-positionStart MuiInputAdornment-outlined MuiInputAdornment-sizeMedium emotion-9"
          >
            <span
              class="notranslate"
            >
              ​
            </span>
            <svg
              aria-hidden="true"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-4"
              data-testid="SearchIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14"
              />
            </svg>
          </div>
          <input
            aria-invalid="false"
            class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd emotion-11"
            id=":r0:"
            placeholder="Search for lessons"
            type="text"
            value=""
          />
          <button
            aria-label="Clear"
            class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall emotion-12"
            tabindex="-1"
            type="button"
          >
            <svg
              aria-hidden="true"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-13"
              data-testid="ClearIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              />
            </svg>
            <span
              class="MuiTouchRipple-root emotion-5"
            />
          </button>
          <fieldset
            aria-hidden="true"
            class="MuiOutlinedInput-notchedOutline emotion-15"
          >
            <legend
              class="emotion-16"
            >
              <span
                class="notranslate"
              >
                ​
              </span>
            </legend>
          </fieldset>
        </div>
      </div>
    </div>
    <div
      class="MuiStack-root emotion-17"
    >
      <div
        class="MuiBox-root emotion-18"
      >
        <button
          aria-label="Assign to Classroom"
          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorPrimary MuiIconButton-sizeMedium emotion-19"
          data-mui-internal-clone-element="true"
          data-testid="assign-lesson-button"
          tabindex="0"
          type="button"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-4"
            data-testid="AddCircleIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m5 11h-4v4h-2v-4H7v-2h4V7h2v4h4z"
            />
          </svg>
          <span
            class="MuiTouchRipple-root emotion-5"
          />
        </button>
        <div
          class="MuiBox-root emotion-22"
        >
          <div
            class="MuiBox-root emotion-23"
          >
            <div
              class="MuiBox-root emotion-24"
              h="80"
              w="80"
            >
              <img
                alt="collection-thumbnail-Collection 1"
                data-testid="collection-thumbnail"
                style="object-fit: cover; height: inherit; width: 100%;"
              />
            </div>
            <div
              class="MuiStack-root emotion-25"
            >
              <h3
                class="MuiTypography-root MuiTypography-titleMedium emotion-26"
              >
                Collection 1
              </h3>
              <p
                class="MuiTypography-root MuiTypography-bodySmall emotion-27"
              >
                Description 1
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="MuiBox-root emotion-18"
      >
        <button
          aria-label="Assign to Classroom"
          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorPrimary MuiIconButton-sizeMedium emotion-19"
          data-mui-internal-clone-element="true"
          data-testid="assign-lesson-button"
          tabindex="0"
          type="button"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-4"
            data-testid="AddCircleIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m5 11h-4v4h-2v-4H7v-2h4V7h2v4h4z"
            />
          </svg>
          <span
            class="MuiTouchRipple-root emotion-5"
          />
        </button>
        <div
          class="MuiBox-root emotion-22"
        >
          <div
            class="MuiBox-root emotion-23"
          >
            <div
              class="MuiBox-root emotion-24"
              h="80"
              w="80"
            >
              <img
                alt="collection-thumbnail-Collection 2"
                data-testid="collection-thumbnail"
                style="object-fit: cover; height: inherit; width: 100%;"
              />
            </div>
            <div
              class="MuiStack-root emotion-25"
            >
              <h3
                class="MuiTypography-root MuiTypography-titleMedium emotion-26"
              >
                Collection 2
              </h3>
              <p
                class="MuiTypography-root MuiTypography-bodySmall emotion-27"
              >
                Description 2
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav
      aria-label="pagination navigation"
      class="MuiPagination-root MuiPagination-text emotion-38"
      style="display: flex; justify-content: center; align-items: center; margin-top: 25px;"
    >
      <ul
        class="MuiPagination-ul emotion-39"
      >
        <li>
          <button
            aria-label="Go to previous page"
            class="MuiButtonBase-root Mui-disabled MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-rounded Mui-disabled MuiPaginationItem-previousNext emotion-40"
            disabled=""
            tabindex="-1"
            type="button"
          >
            <svg
              aria-hidden="true"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiPaginationItem-icon emotion-41"
              data-testid="NavigateBeforeIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"
              />
            </svg>
          </button>
        </li>
        <li>
          <button
            aria-current="true"
            aria-label="page 1"
            class="MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-rounded Mui-selected MuiPaginationItem-page emotion-40"
            tabindex="0"
            type="button"
          >
            1
            <span
              class="MuiTouchRipple-root emotion-5"
            />
          </button>
        </li>
        <li>
          <button
            aria-label="Go to next page"
            class="MuiButtonBase-root Mui-disabled MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-rounded Mui-disabled MuiPaginationItem-previousNext emotion-40"
            disabled=""
            tabindex="-1"
            type="button"
          >
            <svg
              aria-hidden="true"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiPaginationItem-icon emotion-41"
              data-testid="NavigateNextIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
              />
            </svg>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</DocumentFragment>
`;
