import React, { useState } from 'react';
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Close, ContentCopy, Edit, Publish } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Classroom, LessonCollection } from 'controllers/types';
import { useCreateGoogleAssignment, useCreateKyronAssignment } from 'controllers/react-query/assignmentHooks';
import { useClassrooms } from 'controllers/react-query/classroomHooks';
import { Row } from 'components/Row/Row';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { LoadingIndicator } from 'components/LoadingIndicator';
import GoogleClassroomLogo from 'assets/google_classroom_logo.png';
import { getLessonCollectionURL } from 'components/utils/urlUtils';

const styles = {
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
};

const GoogleClassroomIcon = (
  <img src={GoogleClassroomLogo} alt='google classroom icon' style={{ width: '20px', height: '20px' }} />
);

type Props = {
  open: boolean;
  close: () => void;
  lessonCollection: LessonCollection;
  reauthenticate: () => void;
};

export const ClassroomSelector = ({ open, close, lessonCollection, reauthenticate }: Props) => {
  const { data: classrooms, isError, isFetching } = useClassrooms({ enabled: open });
  const [selectedClassroomId, setClassroomId] = useState(0);
  const { mutate: createGoogleAssignment } = useCreateGoogleAssignment();
  const { mutate: createKyronAssignment } = useCreateKyronAssignment(selectedClassroomId);
  const { enqueueSnackbar } = useSnackbar();

  const handleCreateKyronAssignment = (classroom: Classroom) => {
    createKyronAssignment(
      {
        classroomId: classroom.id,
        lessonId: lessonCollection.id,
      },
      {
        onSuccess: () => {
          const url = getLessonCollectionURL(lessonCollection.id, classroom);
          navigator.clipboard.writeText(url);
          enqueueSnackbar(`Assignment successfully created and the link has been copied to your clipboard`, {
            variant: 'success',
          });
        },
        onError: () => {
          enqueueSnackbar('Error: Assignment was not created', { variant: 'error' });
        },
      },
    );
  };

  const handleCreateGoogleAssignment = (classroomId: number, externalClassroomId: string, draft: boolean) => {
    createGoogleAssignment(
      {
        payload: {
          classroomId,
          externalClassroomId,
          lessonId: lessonCollection.id,
          draft,
        },
      },
      {
        onSuccess: () => {
          const handled = draft ? 'created as a draft assignment' : 'assigned';
          enqueueSnackbar(`${lessonCollection.name} was successfully ${handled}`, { variant: 'success' });
        },
        onError: () => {
          enqueueSnackbar('Error: Assignment was not created', { variant: 'error' });
          reauthenticate();
        },
      },
    );
  };

  const renderClassrooms = (classroom: Classroom) => (
    <ListItem key={classroom.id} divider sx={{ display: 'flex', justifyContent: 'space-between' }}>
      {classroom.external_classroom_provider === 'google_classroom' && (
        <>
          <Row sx={{ ml: 1 }} gap={1}>
            <Typography variant='labelLarge'>{classroom.name}</Typography>
            <Tooltip title='Google Classroom' placement='top'>
              {GoogleClassroomIcon}
            </Tooltip>
          </Row>
          <Stack direction='row' spacing={1}>
            <Tooltip title='Publish Assignment' placement='top'>
              <IconButton
                aria-label='Publish Assignment to Google Classroom'
                onClick={() => handleCreateGoogleAssignment(classroom.id, classroom.external_classroom_id, false)}
                data-testid='publish-to-google-classroom-button'
              >
                <Publish />
              </IconButton>
            </Tooltip>
            <Tooltip title='Create Draft Assignment in Google Classroom' placement='top'>
              <IconButton
                aria-label='Draft Assignment to Google Classroom'
                onClick={() => handleCreateGoogleAssignment(classroom.id, classroom.external_classroom_id, true)}
                data-testid='draft-to-google-classroom-button'
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </Stack>
        </>
      )}
      {classroom.external_classroom_provider === 'kyron_learning' && (
        <>
          <Typography variant='labelLarge' sx={{ ml: 1 }}>
            {classroom.name}
          </Typography>
          <Tooltip title='Copy Assignment Link to Clipboard' placement='top'>
            <IconButton
              aria-label='Copy Link to Clipboard'
              data-testid='copy-link-to-clipboard-button'
              onClick={() => {
                setClassroomId(classroom.id);
                handleCreateKyronAssignment(classroom);
              }}
            >
              <ContentCopy />
            </IconButton>
          </Tooltip>
        </>
      )}
    </ListItem>
  );

  const renderDialogContent = () => {
    if (isFetching) return <LoadingIndicator message='Loading classes...' />;
    if (isError) return <Alert severity='error'>Something went wrong. Could not load classrooms.</Alert>;
    if (!classrooms?.length)
      return <Typography sx={{ width: '100%', textAlign: 'center' }}>There are no classes found.</Typography>;
    return <List>{classrooms.map(renderClassrooms)}</List>;
  };

  return (
    <Dialog open={open} onClose={close} aria-label='Assign Lesson to Classroom' data-testid='assign-lesson-dialog'>
      <DialogTitle>
        {`Assign ${lessonCollection.name}`}
        <IconButton
          aria-label='Close'
          data-testid='close-assign-lesson-dialog-button'
          sx={styles.closeButton}
          onClick={close}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ height: 320, minWidth: 480, minHeight: 40 }}>
        <Typography variant='titleLarge'>Assign to a classroom</Typography>
        <Row gap={1.5} mt={1}>
          <ErrorOutlineIcon fontSize='small' />
          <Typography variant='bodyMedium'>
            Link your Google Classroom account to assign lessons to your class.
          </Typography>
        </Row>
        {renderDialogContent()}
      </DialogContent>
    </Dialog>
  );
};
