import React, { useContext, useMemo, useState } from 'react';
import { Collapse, IconButton, MenuItem, Pagination, Tooltip } from '@mui/material';
import { Add, MoreHoriz } from '@mui/icons-material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useActions, useTitle } from 'components/StudioLayout/StudioLayout';
import { LtiPlatformForm } from './LtiPlatformForm';
import { useGetLtiPlatforms } from '../../controllers/react-query/ltiPlatformHooks';
import { LoadingIndicator } from '../LoadingIndicator';
import { LtiPlatformCard } from './LtiPlatformCard';
import { LtiPlatform } from '../../controllers/ltiPlatforms';
import { UserContext } from '../UserContext';
import { KyronMenu } from '../KyronMenu';
import { getCanvasDeepLinkSetupURL } from '../utils/urlUtils';
import { CopyButton } from '../CopyButton';

const styles = {
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25px',
  },
};

export function LtiPlatformPage(): React.JSX.Element {
  const { user, isOrgAdmin } = useContext(UserContext);
  const [formOpen, setFormOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [platformToEdit, setPlatformToEdit] = useState<LtiPlatform | null>(null);
  const { data, isLoading } = useGetLtiPlatforms(currentPage);
  const ltiPlatforms = data?.lti_platforms ?? [];
  const pagination = data?.meta;
  const totalPages = pagination?.total_pages;

  const handleEditPlatform = (ltiPlatform: LtiPlatform) => {
    setFormOpen(true);
    setPlatformToEdit(ltiPlatform);
  };

  const handleAddButton = () => {
    setFormOpen(p => {
      // if closing, clear the editing state
      if (!p) {
        setPlatformToEdit(null);
      }
      return !p;
    });
  };

  const newLtiButton = useMemo(
    () => (
      <>
        <Tooltip title='Add new LTI Platform'>
          <IconButton aria-label='add new lti platform' onClick={handleAddButton}>
            <Add />
          </IconButton>
        </Tooltip>

        <KyronMenu menuIcon={<MoreHoriz />}>
          <MenuItem>
            <CopyButton isMenuItem value={getCanvasDeepLinkSetupURL()} label='Copy Canvas Deep Link Setup URL' />
          </MenuItem>
        </KyronMenu>
      </>
    ),
    [],
  );

  useTitle('LTI Platforms');
  useActions(newLtiButton);

  return (
    <>
      {user && isOrgAdmin && user.active_organization && (
        <Collapse data-testid='lti-form-collapse' in={formOpen} timeout='auto' unmountOnExit sx={{ mb: 1 }}>
          <LtiPlatformForm
            platformToEdit={platformToEdit}
            onClose={() => setFormOpen(false)}
            activeOrgId={user.active_organization.id}
          />
        </Collapse>
      )}

      {isLoading ? <LoadingIndicator /> : null}
      <Grid2
        container
        gridAutoFlow='dense'
        data-testid='lti-platform-list'
        display='flex'
        flexDirection='row'
        rowGap={1}
      >
        {ltiPlatforms.map(ltiPlatform => (
          <Grid2 xs={12} sm={6} md={4} lg={4} key={ltiPlatform.id}>
            <LtiPlatformCard ltiPlatform={ltiPlatform} editPlatform={handleEditPlatform} />
          </Grid2>
        ))}
      </Grid2>
      {!totalPages ? null : (
        <Pagination
          data-testid='lti-plaform-list-pagination'
          style={styles.paginationWrapper}
          count={totalPages}
          siblingCount={5}
          shape='rounded'
          page={currentPage}
          onChange={(_e, v) => setCurrentPage(v)}
        />
      )}
    </>
  );
}
