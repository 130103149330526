import { Stack, Typography } from '@mui/material';
import { KyronAvatar } from 'components/KyronAvatar/KyronAvatar';
import { Row } from 'components/Row/Row';
import { Locales } from 'components/utils/locales';
import { Lesson } from 'controllers/types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

type LessonSelectionDialogCardProps = {
  lesson: Lesson;
};

export function LessonSelectionDialogCard({ lesson }: LessonSelectionDialogCardProps) {
  const location = useLocation();

  return (
    <Row
      gap={2}
      component={Link}
      to={`${lesson.id.toString()}${location.search}`}
      sx={{ textDecoration: 'none', p: 1, borderRadius: 2, '&:hover': { bgcolor: 'surface.dark' } }}
      replace
      data-testid='lesson-selection-dialog-card'
    >
      <KyronAvatar image={lesson.tutor?.picture} label={lesson.tutor?.display_name || 'Unknown Tutor'} size={80} />
      <Stack>
        <Typography variant='titleLarge' color='text.primary' data-testid='tutor-name'>
          {lesson.tutor?.display_name || 'Unknown Tutor'}
        </Typography>
        <Typography variant='bodyMedium' color='text.secondary'>
          {Locales[lesson.locale]}
        </Typography>
      </Stack>
    </Row>
  );
}
