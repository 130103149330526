import { useKyronQuery } from './kyronQuery';
import { Role } from '../types';

export const useRolesQuery = () => {
  const { data, ...rest } = useKyronQuery<Role[]>('/roles', {
    queryKey: ['/roles'],
  });
  const allRoles = data?.filter(role => role.name !== 'admin');
  return { data: allRoles, ...rest };
};
