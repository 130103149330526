import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useLessonCollection } from '../LessonLayout/LessonLayout';
import { LessonSelectionDialog } from '../LessonSelectionDialog/LessonSelectionDialog';

export function LessonNavigator() {
  const location = useLocation();
  const { lessonCollection } = useLessonCollection();
  const { lessons } = lessonCollection;
  const { lesson_instances: lessonInstances } = lessonCollection;
  const { enqueueSnackbar } = useSnackbar();

  // If there are no lessons, display an error message and navigate to the marketplace.
  if (!lessons?.length) {
    enqueueSnackbar('Sorry, we encountered an error while loading this lesson.', { variant: 'error' });
    return <Navigate to='/' replace />;
  }

  if (lessonInstances?.length) {
    const path = `${lessonInstances[0].lesson_id.toString()}${location.search}`;
    return <Navigate to={path} replace />;
  }

  // If there is only one lesson, navigate to it directly.
  if (lessons.length === 1) {
    const path = `${lessons[0].id.toString()}${location.search}`;
    return <Navigate to={path} replace />;
  }

  // If there are multiple lessons, display a list to choose from.
  return LessonSelectionDialog({ lessons: lessons! });
}
