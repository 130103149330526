import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { VideoPreview } from 'components/LessonSegmentEditor/VideoPreview';

type SegmentMediaDisplayProps = {
  isPending: boolean;
  videoUrl?: string;
};
export function SegmentMediaDisplay({ isPending, videoUrl }: SegmentMediaDisplayProps) {
  return (
    <Box
      data-testid='segment-media-display'
      sx={{
        position: 'relative',
        flex: '1',
        aspectRatio: '16/9',
        borderRadius: 3,
        overflow: 'hidden',
        minWidth: '200px',
      }}
    >
      {isPending ? (
        <Skeleton
          variant='rectangular'
          sx={{ borderRadius: 1, height: 'auto', aspectRatio: '1280/720' }}
          data-testid='video-skeleton'
        />
      ) : (
        <VideoPreview url={videoUrl} key={videoUrl} start={0} />
      )}
    </Box>
  );
}
