import React from 'react';
import { Paper, Typography } from '@mui/material';
import { LessonCollection } from 'controllers/types';
import { getLessonCollectionDuration } from 'components/utils/displayDuration';

type DurationBoxProps = {
  lessonCollection: LessonCollection;
};

export const DurationBox = ({ lessonCollection }: DurationBoxProps) => {
  const duration = getLessonCollectionDuration(lessonCollection);

  return (
    <Paper
      sx={{
        borderRadius: 1,
        bottom: 8,
        right: 8,
        display: 'flex',
        px: 1,
        py: 0.5,
        backgroundColor: 'scrim',
        position: 'absolute',
      }}
      data-testid='duration-box'
    >
      <Typography variant='labelSmall' color='primary.contrastText'>
        {duration}
      </Typography>
    </Paper>
  );
};
