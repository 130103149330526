import { Lesson } from 'controllers/types';
import { useKyronMutationV2 } from './kyronMutation';

export type GenerateQuestionsPayload = {
  lessonTitle: string;
  lessonObjective: string;
  audience: string;
  signedMaterialIds: string[];
  questionType: string | null;
  activityTemplate: string;
  ttsVoiceId: string;
};

export const useGenerateEntireLesson = () =>
  useKyronMutationV2<{ payload: GenerateQuestionsPayload }, Lesson>(`/simple_lessons/generate_entire_lesson`, {
    invalidates: ['/lessons'],
  });

export const useRegenerateAllLessonVideos = () =>
  useKyronMutationV2('/lessons/:lessonId/regenerate_all_lesson_videos', {
    invalidates: ({ lessonId }) => [`/lessons/${lessonId}/lesson_details`],
  });
