import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { keepPreviousData, useKyronQuery } from './kyronQuery';
import { useKyronMutationV2 } from './kyronMutation';
import { LessonCollection, LessonCollectionPayload, Pagination, QueryPaginationProps } from '../types';
import { useMarketPlaceLayoutCtx } from '../../components/Marketplace/MarketplaceLayout/marketPlaceLayoutCtx';

export const useGetMarketplaceLessonCollections = ({ page = 1, perPage = 24 }: QueryPaginationProps) => {
  const { setDataLoading } = useMarketPlaceLayoutCtx();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('q');

  const result = useKyronQuery<{
    lesson_collections: LessonCollection[];
    meta: Pagination;
  }>(`/lesson_collections/marketplace?page=${page}&per_page=${perPage}${search ? `&q=${search}` : ''}`, {
    queryKey: ['/lesson_collections', 'marketplace', page, perPage, search],
    placeholderData: keepPreviousData,
  });

  // we wanna call setDataLoading function from the context to set the loading state if isFetching is true
  useEffect(() => {
    setDataLoading(result.isFetching);
  }, [result.isFetching, setDataLoading]);

  return { ...result, search };
};

export const useGetLessonCollection = (id: number) => useKyronQuery<LessonCollection>(`/lesson_collections/${id}`);

export const useDeleteLessonCollection = () =>
  useKyronMutationV2(`/lesson_collections/:lessonCollectionId`, {
    method: 'DELETE',
    invalidatesMultiple: ({ lessonCollectionId }) => [
      ['/lesson_collections/library'],
      ['/lessons'],
      [`/lesson_collections/${lessonCollectionId}`],
    ],
  });

export const useGetLibraryLessonCollections = ({
  page = 1,
  perPage = 25,
  search,
}: { page?: number; perPage?: number; search?: string } = {}) => {
  const searchQueryStr = search ? `&q=${search}` : '';
  return useKyronQuery<{ lesson_collections: LessonCollection[]; meta: Pagination }>(
    `/lesson_collections/library?page=${page}&per_page=${perPage}${searchQueryStr}`,
    {
      queryKey: [`/lesson_collections/library`, { page, perPage, ...(search ? { search } : {}) }],
      placeholderData: keepPreviousData,
    },
  );
};

export const useGetAssignableCollections = ({
  page = 1,
  perPage = 25,
  search,
}: { page?: number; perPage?: number; search?: string } = {}) => {
  const searchQueryStr = search ? `&q=${search}` : '';
  return useKyronQuery<{ lesson_collections: LessonCollection[]; meta: Pagination }>(
    `/lesson_collections/assignable_collections?page=${page}&per_page=${perPage}${searchQueryStr}`,
    {
      queryKey: ['/lesson_collections/assignable_collections', { page, perPage, ...(search ? { search } : {}) }],
      placeholderData: keepPreviousData,
    },
  );
};

export const useUpdateLessonCollection = () =>
  useKyronMutationV2<{ collectionId: number; payload: Partial<LessonCollectionPayload> }, LessonCollection>(
    `/lesson_collections/:collectionId`,
    {
      method: 'PATCH',
      invalidates: ['/lesson_collections'],
    },
  );
