import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { LessonSegment, MultipleChoiceOption, SegmentMedia } from 'controllers/types';
import { useUpdateEdgeOfSegment, useUpdateSegment, useSegmentQuery } from 'controllers/react-query';
import { Row } from 'components/Row/Row';
import { sortByKey } from 'components/utils/arrayUtils';
import { SegmentMediaSelection } from './SegmentMediaSelection';
import { isUsingSegmentDescription, getTooltipNote } from '../helpers';
import { SegmentField } from './SegmentField';
import { useWatchSegmentBackgroundTasks } from '../../BackgroundTasks/useProgressBarChannel';
import { SegmentMediaDisplay } from '../Editor/SegmentMediaDisplay';

function MultipleChoiceModal({
  open,
  choiceKey,
  waitSegmentId,
  onClose,
  sectionId,
}: {
  open: boolean;
  choiceKey: number;
  waitSegmentId: number;
  onClose: () => void;
  sectionId?: number;
}) {
  // We need to useSegmentQuery here rather than pass it through props in order to have up-to-date background task data
  const {
    data: waitSegment,
    isFetching: isSegmentFetching,
    error: segmentFetchError,
    refetch: refetchSegment,
  } = useSegmentQuery(waitSegmentId);
  const choice = waitSegment?.multiplechoices.find(option => option.key === choiceKey);

  // define mutations
  const updateEdgeOfSegment = useUpdateEdgeOfSegment({
    segmentId: waitSegmentId,
    sectionId,
  });
  const updateSegmentMutation = useUpdateSegment({
    segmentId: choice?.target_segment?.id,
    sectionId,
  });

  const { hasActiveBackgroundTask } = useWatchSegmentBackgroundTasks({
    segment: choice?.target_segment,
    onSuccess: refetchSegment,
    onError: err => {
      console.error('Error in video generation', err, choice?.target_segment?.background_tasks);
      refetchSegment();
    },
  });

  const videoUrl = choice?.target_segment?.video_url;
  const isFetching = isSegmentFetching || updateSegmentMutation.isPending || hasActiveBackgroundTask;
  const [targetSegmentDescription, setTargetSegmentDescription] = useState(choice?.target_segment?.description || '');
  const [answer, setAnswer] = useState(choice?.label || '');
  const [correctness, setCorrectness] = useState(choice?.correctness_status === 'correct' || false);
  const [mediaSelection, setMediaSelection] = useState<LessonSegment['media_type']>(
    choice?.target_segment?.media_type ? choice.target_segment.media_type : null,
  );
  const defaultTimes = { start_time: 0, end_time: 99999 };
  function useUpdateSegmentAndVideoAsync(segment?: LessonSegment, text?: string) {
    return {
      updateSegmentAndVideoAsync: async (mediaType: SegmentMedia) => {
        if (!segment) return;
        const segmentMutation = updateSegmentMutation.mutateAsync({
          lessonId: segment.lesson_id,
          payload: { ...segment, ...defaultTimes, description: text?.trim() || '', media_type: mediaType },
        });
        const edgeMutation = updateEdgeOfSegment.mutateAsync({
          payload: {
            edge_position: choiceKey,
            answer: answer.trim(),
            description: targetSegmentDescription.trim(),
            correctness_status: correctness ? 'correct' : 'incorrect',
          },
        });
        await Promise.all([segmentMutation, edgeMutation]).catch(() => {
          console.error('Error updating multiple choice option');
        });
      },
      isPending: updateSegmentMutation.isPending,
      errors: [updateSegmentMutation.error, updateEdgeOfSegment.error].filter((e): e is Error => !!e),
    };
  }
  const { updateSegmentAndVideoAsync, isPending, errors } = useUpdateSegmentAndVideoAsync(
    choice?.target_segment,
    targetSegmentDescription,
  );

  const regenerateKyronVideo = async (mediaType: SegmentMedia) => {
    if (!choice) return;
    await updateSegmentAndVideoAsync(mediaType);
  };

  const handleBlurLabel = (labelText: string) => {
    if (!choice) return;
    // if description is changed and media selection is not user video, regenerate video on blur of textbox
    const label = labelText.trim();
    if (!label || label === choice.label) return;
    if (mediaSelection != null && mediaSelection !== SegmentMedia.USER_VIDEO) {
      updateSegmentAndVideoAsync(mediaSelection);
    } else if (mediaSelection === SegmentMedia.USER_VIDEO) {
      updateEdgeOfSegment.mutate({
        payload: {
          edge_position: choice.key,
          answer: label,
          description: targetSegmentDescription,
          correctness_status: correctness ? 'correct' : 'incorrect',
        },
      });
    }
  };

  const handleBlurResponse = (responseText: string) => {
    if (!choice) return;
    // if description is changed and media selection is not user video, regenerate video on blur of textbox
    const description = responseText.trim();
    if (!description || description === choice.target_segment.description) return;
    if (mediaSelection != null && mediaSelection !== SegmentMedia.USER_VIDEO) {
      updateSegmentAndVideoAsync(mediaSelection);
    }
  };

  function handleCorrectnessToggle(checked: boolean) {
    if (!choice) return;
    setCorrectness(checked);
    updateEdgeOfSegment.mutate({
      payload: {
        edge_position: choice.key,
        answer,
        description: targetSegmentDescription,
        correctness_status: checked ? 'correct' : 'incorrect',
      },
    });
  }

  useEffect(() => {
    if (!choice) return;
    setTargetSegmentDescription(choice.target_segment.description);
    setAnswer(choice.label);
    setCorrectness(choice.correctness_status === 'correct');
  }, [choice]);

  useEffect(() => {
    if (!segmentFetchError) return;
    errors.push(segmentFetchError);
  }, [segmentFetchError, errors]);

  return (
    choice && (
      <Dialog
        open={open}
        onClose={() => (!isPending ? onClose() : {})}
        data-testid={`modal-id-${choice.key}`}
        PaperProps={{ style: { maxWidth: '640px' } }}
      >
        <DialogTitle>Multiple Choice Option</DialogTitle>

        <DialogContent sx={{ width: '100%' }}>
          {errors.length > 0 ? (
            <Stack mt={1} mb={2} gap={1}>
              {errors.map(error => (
                <Alert key={error?.message} severity='error'>
                  {error?.message}
                </Alert>
              ))}
            </Stack>
          ) : null}
          <Stack gap={2}>
            <Box>
              <SegmentField
                aria-label='Option Text'
                data-testid={`content-option-id-${choice.key}`}
                disabled={isFetching}
                fullWidth
                info=''
                onBlur={e => handleBlurLabel(e.target.value)}
                onChange={e => setAnswer(e.target.value)}
                required
                title='Option Text'
                tooltipNote={getTooltipNote(mediaSelection, isFetching, false)}
                value={answer}
              />
            </Box>
            <Box>
              <Typography variant='titleSmall'>Correct or Incorrect</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={correctness}
                    disabled={isFetching}
                    onChange={e => handleCorrectnessToggle(e.target.checked)}
                    data-testid='correct-switch'
                  />
                }
                label='Correct'
                componentsProps={{ typography: { variant: 'labelLarge' } }}
              />
            </Box>
            <Stack gap={1}>
              <Box sx={{ position: 'relative' }}>
                <SegmentField
                  disabled={isFetching || !isUsingSegmentDescription(mediaSelection)}
                  fullWidth
                  info='When a learner selects this option, how should the lesson respond? Note: for correct options, after this response the lesson will move on; for incorrect options, after this response the student will be prompted to attempt the question again.'
                  minRows={3}
                  multiline
                  onBlur={e => handleBlurResponse(e.target.value)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTargetSegmentDescription(e.target.value)}
                  required
                  title='Response'
                  tooltipNote={getTooltipNote(mediaSelection, isFetching, true)}
                  value={targetSegmentDescription}
                />
              </Box>
            </Stack>
            <Row
              direction={{ xs: 'column', md: 'row' }}
              gap={{ xs: 4, md: 2 }}
              sx={{ alignItems: { xs: 'stretch', md: 'flex-start' } }}
            >
              <SegmentMediaDisplay isPending={isFetching} videoUrl={videoUrl} />
              <Box>
                <SegmentMediaSelection
                  getSelection={setMediaSelection}
                  isLoading={isFetching}
                  columnLayout
                  regenerateKyronVideo={regenerateKyronVideo}
                  segment={choice.target_segment}
                  sectionId={sectionId}
                />
              </Box>
            </Row>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ width: '100%' }}>
          <Stack direction='row' justifyContent='flex-end' gap={1}>
            <Button onClick={onClose} variant='text' disabled={isPending} data-testid={`close-button-${choice.key}`}>
              Close
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    )
  );
}

type Props = {
  waitSegment: LessonSegment;
  sectionId?: number;
};

export function SegmentMultipleChoice({ waitSegment, sectionId }: Props) {
  const [selectedChoice, setSelectedChoice] = useState<MultipleChoiceOption | null>(null);

  return (
    <>
      <Stack gap={2}>
        <Typography variant='labelLarge'>Multiple Choice Options</Typography>

        {sortByKey(waitSegment.multiplechoices)?.map(choice => (
          <Card
            key={choice.key}
            sx={{
              cursor: 'move',
              bgcolor: choice.correctness_status === 'correct' ? 'primaryContainer.main' : 'tertiaryContainer.main',
              p: 1,
              pl: 2,
              flexShrink: 0,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography
                variant='bodySmall'
                color={choice.correctness_status === 'correct' ? 'primary.main' : 'tertiary.main'}
              >
                {choice.correctness_status === 'correct' ? 'Correct' : 'Incorrect'}
              </Typography>
              <Typography
                variant='bodyLarge'
                color={
                  choice.correctness_status === 'correct'
                    ? 'primaryContainer.contrastText'
                    : 'tertiaryContainer.contrastText'
                }
              >
                {choice.label}
              </Typography>
            </Box>

            <Box justifyContent='flex-end'>
              <IconButton
                size='small'
                data-testid={`edit-button-${choice.key}`}
                onClick={() => setSelectedChoice(choice)}
              >
                <Edit />
              </IconButton>
            </Box>
          </Card>
        ))}
      </Stack>
      {selectedChoice != null && (
        <MultipleChoiceModal
          open={selectedChoice !== null}
          onClose={() => setSelectedChoice(null)}
          choiceKey={selectedChoice.key}
          waitSegmentId={waitSegment.id}
          sectionId={sectionId}
        />
      )}
    </>
  );
}
