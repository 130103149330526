import React, { useState } from 'react';
import { Stack, Alert, Theme, useMediaQuery } from '@mui/material';
import { LessonSegment, SegmentMedia } from 'controllers/types';
import { useUpdateSegment } from 'controllers/react-query';
import { KyronEvents } from 'components/utils/KyronEvents';
import { useUserContext } from 'components/UserContext';
import { SegmentMediaSelection } from './SegmentMediaSelection';
import { SegmentMultipleChoice } from './SegmentMultipleChoice';
import { SegmentField } from './SegmentField';
import { isUsingSegmentDescription, getTooltipNote } from '../helpers';

function useUpdateSegments({
  segmentId,
  waitSegmentId,
  sectionId,
}: {
  segmentId: number;
  waitSegmentId?: number;
  sectionId: number;
}) {
  const updateSegmentMutation = useUpdateSegment({ segmentId, sectionId });
  const updateWaitSegmentMutation = useUpdateSegment({ segmentId: waitSegmentId || 0, sectionId });
  // backend does some magic to set start_time and end_time but we want to ignore them in this case
  // TODO(derick): figure out a better way of managing times without messing up existing internal lesson creation flow
  const defaultTimes = { start_time: 0, end_time: 99999 };

  return {
    updateWaitSegmentAndVideoAsync: async (segment: LessonSegment, text: string, mediaType: SegmentMedia) => {
      await updateWaitSegmentMutation.mutateAsync({
        lessonId: segment.lesson_id,
        payload: { ...segment, ...defaultTimes, description: text, question: text, media_type: mediaType },
      });
    },
    updateSegmentAndVideoAsync: async (segment: LessonSegment, text: string, mediaType: SegmentMedia) => {
      await updateSegmentMutation.mutateAsync({
        lessonId: segment.lesson_id,
        payload: { ...segment, ...defaultTimes, description: text, media_type: mediaType },
      });
    },
    updateWaitSegmentAnswer: (segment: LessonSegment, text: string) => {
      updateWaitSegmentMutation.mutate({
        lessonId: segment.lesson_id,
        payload: { ...segment, ...defaultTimes, answer: text },
      });
    },
    isPending: updateSegmentMutation.isPending || updateWaitSegmentMutation.isPending,
    errors: [updateSegmentMutation.error, updateWaitSegmentMutation.error].filter((e): e is Error => !!e),
  };
}

type EditSegmentProps = {
  title: string;
  info: string;
  segment: LessonSegment;
  waitSegmentProps?: EditSegmentProps;
  sectionId: number;
};

export function EditSegmentField({ title, info, segment, waitSegmentProps, sectionId }: EditSegmentProps) {
  const { updateSegmentAndVideoAsync, updateWaitSegmentAndVideoAsync, updateWaitSegmentAnswer, isPending, errors } =
    useUpdateSegments({
      segmentId: segment.id,
      waitSegmentId: waitSegmentProps?.segment?.id,
      sectionId,
    });
  const [segmentDescription, setSegmentDescription] = useState(segment.description);
  const [answer, setAnswer] = useState(waitSegmentProps?.segment?.answer || '');
  const [mediaSelection, setMediaSelection] = useState<LessonSegment['media_type']>(segment.media_type);
  const isMobileView = useMediaQuery((t: Theme) => t.breakpoints.down('sm'));
  const { user } = useUserContext();

  const saveSegment = (param: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const description = param.target.value.trim();
    if (!description || description === segment.description) return;
    if (waitSegmentProps && mediaSelection) {
      updateWaitSegmentAndVideoAsync({ ...waitSegmentProps.segment }, description, mediaSelection); // wait segment shares the same text as step segment
    }
    if (mediaSelection) {
      updateSegmentAndVideoAsync({ ...segment }, description, mediaSelection);
    }
  };

  const regenerateKyronVideo = async (mediaType: SegmentMedia) => {
    await updateSegmentAndVideoAsync({ ...segment }, segmentDescription, mediaType);
    if (waitSegmentProps) {
      await updateWaitSegmentAndVideoAsync({ ...waitSegmentProps.segment }, segmentDescription, mediaType); // wait segment shares the same text as step segment
    }
    KyronEvents.sendEvent('Regenerated Kyron Video', {
      user_id: user?.id || 'anonymous',
      lesson_id: segment.lesson_id,
      segment_id: segment.id,
    });
  };

  const saveWaitSegment = () => {
    if (!waitSegmentProps || !answer || answer === waitSegmentProps?.segment?.answer) return;
    const trimmedAnswer = answer.trim();
    updateWaitSegmentAnswer({ ...waitSegmentProps.segment }, trimmedAnswer); // wait segment has its own answer text
  };

  return (
    <Stack gap={1}>
      {/* Step Segment */}
      <SegmentField
        title={title}
        info={info}
        value={segmentDescription}
        onChange={e => setSegmentDescription(e.target.value)}
        onBlur={saveSegment}
        disabled={isPending || !isUsingSegmentDescription(mediaSelection)}
        tooltipNote={getTooltipNote(mediaSelection, isPending, true)}
      />

      <SegmentMediaSelection
        getSelection={setMediaSelection}
        segment={segment}
        regenerateKyronVideo={regenerateKyronVideo}
        columnLayout={isMobileView}
        sectionId={sectionId}
      />

      {/* Wait Segment paired with Step segment */}
      {waitSegmentProps &&
        (waitSegmentProps.segment?.multiplechoices?.length > 0 ? (
          <SegmentMultipleChoice waitSegment={waitSegmentProps.segment} sectionId={sectionId} />
        ) : (
          <SegmentField
            title={waitSegmentProps.title}
            info={waitSegmentProps.info}
            value={answer}
            onChange={e => setAnswer(e.target.value)}
            onBlur={saveWaitSegment}
            disabled={isPending}
          />
        ))}

      {errors.length > 0 ? (
        <Stack mt={1} gap={1}>
          {errors.map(error => (
            <Alert key={error.message} severity='error'>
              {error.message}
            </Alert>
          ))}
        </Stack>
      ) : null}
    </Stack>
  );
}
