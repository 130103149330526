// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`StudioLayout should render 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: 10;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  height: 64px;
  background-color: #FFFFFF;
}

@media (min-width:0px) {
  .emotion-0 {
    left: 0;
  }
}

@media (min-width:600px) {
  .emotion-0 {
    left: 200px;
  }
}

.emotion-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 64px;
  padding: 0 16px;
  background-color: #FFFFFF;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  margin-right: auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%;
}

.emotion-3 {
  margin-top: 12px;
}

.emotion-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
  margin-left: auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-5 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(75, 53, 255, 0.5);
  color: #4B35FF;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.emotion-5::-moz-focus-inner {
  border-style: none;
}

.emotion-5.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-5 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-5:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(75, 53, 255, 0.04);
  border: 1px solid #4B35FF;
}

@media (hover: none) {
  .emotion-5:hover {
    background-color: transparent;
  }
}

.emotion-5.Mui-disabled {
  color: #1C1B1F61;
  border: 1px solid #1C1B1F1F;
}

.emotion-5:hover {
  box-shadow: none;
}

.emotion-5.Mui-focusVisible {
  box-shadow: none;
}

.emotion-5:active {
  box-shadow: none;
}

.emotion-5.Mui-disabled {
  box-shadow: none;
}

.emotion-6 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-7 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-7::-moz-focus-inner {
  border-style: none;
}

.emotion-7.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-7 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-7:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-7:hover {
    background-color: transparent;
  }
}

.emotion-7.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-8 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-family: Open Sans;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #FFFFFF;
  background-color: #bdbdbd;
  width: 48px;
  height: 48px;
  background-color: #193615;
}

<header
    class="MuiBox-root emotion-0"
    data-testid="app-bar"
  >
    <div
      class="MuiStack-root emotion-1"
    >
      <div
        class="MuiStack-root emotion-2"
      />
      <div
        class="MuiBox-root emotion-3"
      />
      <div
        class="MuiStack-root emotion-4"
      >
        <a
          class="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation emotion-5"
          href="/"
          tabindex="0"
        >
          Back to Public Catalog
          <span
            class="MuiTouchRipple-root emotion-6"
          />
        </a>
        <button
          aria-haspopup="true"
          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-7"
          data-testid="account-menu-button"
          tabindex="0"
          type="button"
        >
          <div
            class="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault emotion-8"
            data-testid="initials-avatar"
          >
            T
          </div>
          <span
            class="MuiTouchRipple-root emotion-6"
          />
        </button>
      </div>
    </div>
  </header>
  .emotion-0 {
  height: 64px;
}

<div
    class="MuiBox-root emotion-0"
    data-comment="header padding"
  />
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 22px 8px;
  background-color: #FCFAFD;
  position: fixed;
  bottom: 0;
  top: 0;
  width: 200px;
  -webkit-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
}

.emotion-0>:not(style):not(style) {
  margin: 0;
}

.emotion-0>:not(style)~:not(style) {
  margin-top: 40px;
}

.emotion-1 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 4px 10px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #FFFFFF;
  background-color: #4B35FF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34),2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34),6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34);
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  background-color: #F1ECF4;
  border-radius: 4px;
  color: #1C1B1FB3;
  height: 32px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-width: 0;
  padding-left: 8px;
  padding-right: 8px;
}

.emotion-1::-moz-focus-inner {
  border-style: none;
}

.emotion-1.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-1 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-1:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(52, 37, 178);
  box-shadow: none;
}

@media (hover: none) {
  .emotion-1:hover {
    background-color: #4B35FF;
  }
}

.emotion-1:active {
  box-shadow: none;
}

.emotion-1.Mui-focusVisible {
  box-shadow: none;
}

.emotion-1.Mui-disabled {
  color: #1C1B1F61;
  box-shadow: none;
  background-color: #1C1B1F1F;
}

.emotion-1:hover {
  box-shadow: none;
}

.emotion-1.Mui-focusVisible {
  box-shadow: none;
}

.emotion-1:active {
  box-shadow: none;
}

.emotion-1.Mui-disabled {
  box-shadow: none;
}

.emotion-1 typography {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.emotion-1:hover {
  background-color: #D9D3DD;
}

.emotion-2 {
  margin: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.emotion-3 {
  display: inherit;
  margin-right: -2px;
  margin-left: 8px;
}

.emotion-3>*:nth-of-type(1) {
  font-size: 18px;
}

.emotion-4 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}

.emotion-5 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: #1C1B1FB3;
  overflow-x: hidden;
  overflow-y: auto;
}

.emotion-6>:not(style):not(style) {
  margin: 0;
}

.emotion-6>:not(style)~:not(style) {
  margin-top: 8px;
}

.emotion-7 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  min-width: 0;
  box-sizing: border-box;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 100px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  text-wrap: nowrap;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  color: inherit;
}

.emotion-7::-moz-focus-inner {
  border-style: none;
}

.emotion-7.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-7 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-7:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-7:hover {
    background-color: transparent;
  }
}

.emotion-7.Mui-selected {
  background-color: rgba(75, 53, 255, 0.08);
}

.emotion-7.Mui-selected.Mui-focusVisible {
  background-color: rgba(75, 53, 255, 0.2);
}

.emotion-7.Mui-selected:hover {
  background-color: rgba(75, 53, 255, 0.12);
}

@media (hover: none) {
  .emotion-7.Mui-selected:hover {
    background-color: rgba(75, 53, 255, 0.08);
  }
}

.emotion-7.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-7.Mui-disabled {
  opacity: 0.38;
}

.emotion-7:focus,
.emotion-7:hover {
  background-color: #F1ECF4;
  color: #47464F;
}

.emotion-7.active {
  background-color: #F3DBF8;
  color: #340042;
}

.emotion-7.active:focus,
.emotion-7.active:hover {
  background-color: #E1CDE6;
  color: #340042;
}

.emotion-8 {
  min-width: 56px;
  color: rgba(0, 0, 0, 0.54);
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: inherit;
  margin-right: 8px;
  min-width: 0px;
  margin-right: 8px;
}

.emotion-10 {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.emotion-10 .MuiListItemText-secondary {
  color: inherit;
}

.emotion-11 {
  margin: 0;
  font: inherit;
  display: block;
}

.emotion-37 {
  height: 16px;
}

.emotion-38 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  position: absolute;
  bottom: 80px;
  left: 186px;
  z-index: 99999;
  -webkit-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
}

.emotion-39 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 24px;
  height: 24px;
  background: #F1ECF4;
}

.emotion-39::-moz-focus-inner {
  border-style: none;
}

.emotion-39.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-39 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-39:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-39:hover {
    background-color: transparent;
  }
}

.emotion-39.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-40 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

<div
    class="MuiStack-root emotion-0"
    data-testid="studio-sidebar"
  >
    <a
      data-testid="app-bar-logo"
      href="/"
      style="display: flex; position: relative; justify-content: center;"
    >
      <img
        alt="Kyron Learning"
        height="24"
        src="SvgrURL"
        style="opacity: 0; visibility: hidden;"
      />
      <img
        alt="Kyron Learning"
        height="24"
        src="SvgrURL"
        style="opacity: 1; position: absolute; webkit-transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;"
      />
    </a>
    <button
      aria-haspopup="true"
      class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-colorPrimary MuiButton-disableElevation emotion-1"
      data-testid="organization-select"
      tabindex="0"
      type="button"
    >
      <span
        class="MuiTypography-root MuiTypography-labelMedium MuiTypography-noWrap emotion-2"
      >
        Test Org
      </span>
      <span
        class="MuiButton-icon MuiButton-endIcon MuiButton-iconSizeSmall emotion-3"
      >
        <svg
          aria-hidden="true"
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
          data-testid="UnfoldMoreIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path
            d="M12 5.83 15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15z"
          />
        </svg>
      </span>
      <span
        class="MuiTouchRipple-root emotion-5"
      />
    </button>
    <nav
      class="MuiStack-root emotion-6"
    >
      <a
        class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-7"
        href="/studio/library"
        tabindex="0"
      >
        <div
          class="MuiListItemIcon-root emotion-8"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
            data-testid="MenuBookIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M21 5c-1.11-.35-2.33-.5-3.5-.5-1.95 0-4.05.4-5.5 1.5-1.45-1.1-3.55-1.5-5.5-1.5S2.45 4.9 1 6v14.65c0 .25.25.5.5.5.1 0 .15-.05.25-.05C3.1 20.45 5.05 20 6.5 20c1.95 0 4.05.4 5.5 1.5 1.35-.85 3.8-1.5 5.5-1.5 1.65 0 3.35.3 4.75 1.05.1.05.15.05.25.05.25 0 .5-.25.5-.5V6c-.6-.45-1.25-.75-2-1m0 13.5c-1.1-.35-2.3-.5-3.5-.5-1.7 0-4.15.65-5.5 1.5V8c1.35-.85 3.8-1.5 5.5-1.5 1.2 0 2.4.15 3.5.5z"
            />
            <path
              d="M17.5 10.5c.88 0 1.73.09 2.5.26V9.24c-.79-.15-1.64-.24-2.5-.24-1.7 0-3.24.29-4.5.83v1.66c1.13-.64 2.7-.99 4.5-.99M13 12.49v1.66c1.13-.64 2.7-.99 4.5-.99.88 0 1.73.09 2.5.26V11.9c-.79-.15-1.64-.24-2.5-.24-1.7 0-3.24.3-4.5.83m4.5 1.84c-1.7 0-3.24.29-4.5.83v1.66c1.13-.64 2.7-.99 4.5-.99.88 0 1.73.09 2.5.26v-1.52c-.79-.16-1.64-.24-2.5-.24"
            />
          </svg>
        </div>
        <div
          class="MuiListItemText-root emotion-10"
        >
          <p
            class="MuiTypography-root MuiTypography-inherit MuiListItemText-primary emotion-11"
          >
            Library
          </p>
        </div>
        <span
          class="MuiTouchRipple-root emotion-5"
        />
      </a>
      <a
        class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-7"
        href="/studio/classrooms"
        tabindex="0"
      >
        <div
          class="MuiListItemIcon-root emotion-8"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
            data-testid="SchoolIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M5 13.18v4L12 21l7-3.82v-4L12 17zM12 3 1 9l11 6 9-4.91V17h2V9z"
            />
          </svg>
        </div>
        <div
          class="MuiListItemText-root emotion-10"
        >
          <p
            class="MuiTypography-root MuiTypography-inherit MuiListItemText-primary emotion-11"
          >
            Classrooms
          </p>
        </div>
        <span
          class="MuiTouchRipple-root emotion-5"
        />
      </a>
      <a
        class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-7"
        href="/studio/members"
        tabindex="0"
      >
        <div
          class="MuiListItemIcon-root emotion-8"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
            data-testid="PeopleIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3m-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3m0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5m8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5"
            />
          </svg>
        </div>
        <div
          class="MuiListItemText-root emotion-10"
        >
          <p
            class="MuiTypography-root MuiTypography-inherit MuiListItemText-primary emotion-11"
          >
            Members
          </p>
        </div>
        <span
          class="MuiTouchRipple-root emotion-5"
        />
      </a>
      <a
        class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-7"
        href="/studio/settings"
        tabindex="0"
      >
        <div
          class="MuiListItemIcon-root emotion-8"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
            data-testid="SettingsIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6"
            />
          </svg>
        </div>
        <div
          class="MuiListItemText-root emotion-10"
        >
          <p
            class="MuiTypography-root MuiTypography-inherit MuiListItemText-primary emotion-11"
          >
            Settings
          </p>
        </div>
        <span
          class="MuiTouchRipple-root emotion-5"
        />
      </a>
      <a
        class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-7"
        href="/studio/lti_platforms"
        tabindex="0"
      >
        <div
          class="MuiListItemIcon-root emotion-8"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
            data-testid="IntegrationInstructionsIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 3h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04-.39.08-.74.28-1.01.55-.18.18-.33.4-.43.64-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m-8 11.17-1.41 1.42L6 12l3.59-3.59L11 9.83 8.83 12zm1-9.92c-.41 0-.75-.34-.75-.75s.34-.75.75-.75.75.34.75.75-.34.75-.75.75m2.41 11.34L13 14.17 15.17 12 13 9.83l1.41-1.42L18 12z"
            />
          </svg>
        </div>
        <div
          class="MuiListItemText-root emotion-10"
        >
          <p
            class="MuiTypography-root MuiTypography-inherit MuiListItemText-primary emotion-11"
          >
            LTI Platforms
          </p>
        </div>
        <span
          class="MuiTouchRipple-root emotion-5"
        />
      </a>
      <div
        class="MuiBox-root emotion-37"
      />
    </nav>
    <div
      aria-label="Collapse Menu"
      class="MuiBox-root emotion-38"
      data-mui-internal-clone-element="true"
      data-testid="collapse-button"
    >
      <button
        class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-39"
        tabindex="0"
        type="button"
      >
        <svg
          aria-hidden="true"
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-40"
          data-testid="KeyboardArrowLeftIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path
            d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6z"
          />
        </svg>
        <span
          class="MuiTouchRipple-root emotion-5"
        />
      </button>
    </div>
  </div>
  .emotion-0 {
  -webkit-transition: padding-left 0.2s ease-in-out;
  transition: padding-left 0.2s ease-in-out;
}

@media (min-width:0px) {
  .emotion-0 {
    padding-bottom: 80px;
    padding-left: 0px;
  }
}

@media (min-width:600px) {
  .emotion-0 {
    padding-bottom: 0px;
    padding-left: 200px;
  }
}

@media (min-width:0px) {
  .emotion-1 {
    padding: 16px;
  }
}

@media (min-width:600px) {
  .emotion-1 {
    padding: 40px;
  }
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1600px;
}

.emotion-2>:not(style):not(style) {
  margin: 0;
}

.emotion-2>:not(style)~:not(style) {
  margin-top: 24px;
}

.emotion-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 2px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.emotion-5 {
  margin: 0;
  color: #1C1B1FB3;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: #1C1B1FB3;
}

.emotion-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.emotion-7 {
  margin: 0;
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
}

.emotion-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

<main
    class="MuiBox-root emotion-0"
  >
    <div
      class="MuiBox-root emotion-1"
    >
      <div
        class="MuiStack-root emotion-2"
      >
        <div
          class="MuiStack-root emotion-3"
        >
          <div
            class="MuiStack-root emotion-4"
          >
            <nav
              aria-label="breadcrumb"
              class="MuiTypography-root MuiTypography-body1 MuiBreadcrumbs-root emotion-5"
            >
              <ol
                class="MuiBreadcrumbs-ol emotion-6"
              />
            </nav>
            <h3
              class="MuiTypography-root MuiTypography-headlineMedium emotion-7"
            />
          </div>
          <div
            class="MuiStack-root emotion-3"
          />
        </div>
        <div
          class="MuiStack-root emotion-9"
        />
      </div>
    </div>
  </main>
</DocumentFragment>
`;
