import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { LESSON_EDITOR_VIDEO_PLAYBACK_RATE_KEY, LESSON_EDITOR_VIDEO_VOLUME } from '../../constants';

export const VideoPreview = ({ url = '', start, end }) => {
  // mediafragment syntax: https://www.w3.org/TR/media-fragments/
  const startParam = start ? `#t=${start}` : '';
  const endParam = end ? `,${end}` : '';
  const urlWithParams = `${url}${startParam}${endParam}`;

  const [isPlaying, setIsPlaying] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const playbackRateFromStorage = Number(sessionStorage.getItem(LESSON_EDITOR_VIDEO_PLAYBACK_RATE_KEY)) || 1;

  const playerRef = useRef();

  const PROGRESS_INTERVAL = 100;

  const handleProgress = e => {
    if (e.playedSeconds + PROGRESS_INTERVAL / 2000.0 >= end) {
      setIsPlaying(false);
      playerRef.current.seekTo(start);
    }
  };

  const ref = newPlayer => {
    playerRef.current = newPlayer;
  };

  const onReady = useCallback(() => {
    if (!isReady) {
      playerRef.current.seekTo(start);
      setIsReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  const handlePlaybackRateChange = val => {
    sessionStorage.setItem(LESSON_EDITOR_VIDEO_PLAYBACK_RATE_KEY, val.toString());
  };

  const handleVolumeChange = e => {
    const vol = Number(e.target.volume).toFixed(1);
    sessionStorage.setItem(LESSON_EDITOR_VIDEO_VOLUME, vol);
  };

  if (!url) {
    return null;
  }

  return (
    <ReactPlayer
      ref={ref}
      url={urlWithParams}
      controls
      width='100%'
      height='100%'
      onReady={onReady}
      playing={isPlaying}
      progressInterval={PROGRESS_INTERVAL}
      onProgress={handleProgress}
      onPlay={() => setIsPlaying(true)}
      onPause={() => setIsPlaying(false)}
      playbackRate={playbackRateFromStorage}
      onPlaybackRateChange={handlePlaybackRateChange}
      volume={Number(sessionStorage.getItem(LESSON_EDITOR_VIDEO_VOLUME)) || 1}
      onVolumeChange={handleVolumeChange}
    />
  );
};

VideoPreview.propTypes = {
  url: PropTypes.string,
  start: PropTypes.number,
  end: PropTypes.number,
};
