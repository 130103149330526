import { KyronClient } from '../components/utils/KyronClient';
import {
  TOCItem,
  LessonInstanceResponse,
  GotoLessonInstanceResponse,
  NextLessonSegmentResponse,
  NextLessonSegmentForm,
} from './types';

export async function createLessonInstance(
  lessonId: number,
  segmentId: number,
  containerId: number,
  containerType: string,
  restart: boolean,
  ltiLaunch: string | null,
): Promise<LessonInstanceResponse> {
  const client = new KyronClient();

  const formData = client.convertJsonToFormData(
    {
      lessonId,
      containerId,
      containerType,
      restart,
      ltiLaunch,
    },
    { convertToSnakeCase: true },
  );
  const path = `/api/v1/lesson_instances?segment_id=${segmentId || ''}`;
  return await client.submitJsonDataWithError(path, 'POST', formData);
}

export async function gotoLessonSegment(
  instanceId: number,
  tableOfContent: TOCItem,
): Promise<GotoLessonInstanceResponse> {
  const client = new KyronClient();

  let path = `/api/v1/lesson_instances/${instanceId}/goto_lesson_segment`;
  if (tableOfContent.lesson_segment_id !== undefined) {
    path += `?lesson_segment_id=${tableOfContent.lesson_segment_id}`;
  }
  return await client.getDataWithError(path);
}

export async function nextLessonSegment(
  instanceId: number,
  instanceForm: NextLessonSegmentForm,
): Promise<NextLessonSegmentResponse> {
  const client = new KyronClient();
  const formData = client.convertJsonToFormData(instanceForm);

  const path = `/api/v1/lesson_instances/${instanceId}/next_lesson_segment`;
  return await client.submitJsonDataWithError(path, 'PUT', formData);
}
