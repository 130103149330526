// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`CreateLessonForm should render the component 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.emotion-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.emotion-2 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #FFFFFF;
  background-color: #4B35FF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34),2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34),6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34);
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  background: linear-gradient(to bottom right, #4B35FF, #A911DF, #FF1F00);
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
  width: 300px;
  height: 48px;
}

.emotion-2::-moz-focus-inner {
  border-style: none;
}

.emotion-2.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-2 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-2:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(52, 37, 178);
  box-shadow: none;
}

@media (hover: none) {
  .emotion-2:hover {
    background-color: #4B35FF;
  }
}

.emotion-2:active {
  box-shadow: none;
}

.emotion-2.Mui-focusVisible {
  box-shadow: none;
}

.emotion-2.Mui-disabled {
  color: #1C1B1F61;
  box-shadow: none;
  background-color: #1C1B1F1F;
}

.emotion-2:hover {
  box-shadow: none;
}

.emotion-2.Mui-focusVisible {
  box-shadow: none;
}

.emotion-2:active {
  box-shadow: none;
}

.emotion-2.Mui-disabled {
  box-shadow: none;
}

.emotion-2.Mui-disabled {
  background: linear-gradient(to bottom right, #4B35FF, #A911DF, #FF1F00);
  box-shadow: none;
  color: #FFFFFF99;
}

.emotion-3 {
  display: inherit;
  margin-right: 8px;
  margin-left: -4px;
}

.emotion-3>*:nth-of-type(1) {
  font-size: 20px;
}

.emotion-4 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-5 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.emotion-6>:not(style):not(style) {
  margin: 0;
}

.emotion-6>:not(style)~:not(style) {
  margin-top: 2px;
}

.emotion-7 {
  margin: 0;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.emotion-8 {
  margin: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1C1B1FB3;
}

.emotion-9 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  width: 100%;
}

.emotion-10 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 8px;
}

.emotion-10.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-10:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-10:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-10.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-10.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-10.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-11 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}

.emotion-11::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-11::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-11:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-11::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-11:focus {
  outline: 0;
}

.emotion-11:invalid {
  box-shadow: none;
}

.emotion-11::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-11::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-11::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-11:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-11::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-11:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-11:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-11:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-11:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-11.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-11:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-11:-webkit-autofill {
  border-radius: inherit;
}

.emotion-12 {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.emotion-13 {
  float: unset;
  width: auto;
  overflow: hidden;
  padding: 0;
  line-height: 11px;
  -webkit-transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.emotion-18 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 4px 0 5px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  padding: 16.5px 14px;
}

.emotion-18.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-18:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-18:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-18.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-18.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-18.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-19 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 0;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: auto;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  resize: none;
  padding-top: 0;
  padding: 0;
}

.emotion-19::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-19::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-19:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-19::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-19:focus {
  outline: 0;
}

.emotion-19:invalid {
  box-shadow: none;
}

.emotion-19::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-19::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-19::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-19:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-19::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-19:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-19:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-19:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-19:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-19.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-19:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-19:-webkit-autofill {
  border-radius: inherit;
}

.emotion-35 {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
}

.emotion-36 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 600px;
  gap: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-37 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(75, 53, 255, 0.5);
  color: #4B35FF;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  min-width: 156px;
}

.emotion-37::-moz-focus-inner {
  border-style: none;
}

.emotion-37.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-37 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-37:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(75, 53, 255, 0.04);
  border: 1px solid #4B35FF;
}

@media (hover: none) {
  .emotion-37:hover {
    background-color: transparent;
  }
}

.emotion-37.Mui-disabled {
  color: #1C1B1F61;
  border: 1px solid #1C1B1F1F;
}

.emotion-37:hover {
  box-shadow: none;
}

.emotion-37.Mui-focusVisible {
  box-shadow: none;
}

.emotion-37:active {
  box-shadow: none;
}

.emotion-37.Mui-disabled {
  box-shadow: none;
}

.emotion-40 {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
}

<div
    class="MuiStack-root emotion-0"
  >
    <div
      class="MuiStack-root emotion-1"
    >
      <button
        class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation emotion-2"
        tabindex="0"
        type="button"
      >
        <span
          class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-3"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-4"
            data-testid="AutoAwesomeIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="m19 9 1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25z"
            />
          </svg>
        </span>
        Feeling lucky?
        <span
          class="MuiTouchRipple-root emotion-5"
        />
      </button>
    </div>
    <div
      class="MuiStack-root emotion-6"
    >
      <label
        class="MuiTypography-root MuiTypography-labelLarge emotion-7"
        for="lesson-title"
      >
        Title
      </label>
      <p
        class="MuiTypography-root MuiTypography-bodyMedium emotion-8"
      >
        What would you like to teach? 
      </p>
      <div
        class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root emotion-9"
      >
        <div
          class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth Mui-focused MuiInputBase-formControl emotion-10"
        >
          <input
            aria-invalid="false"
            class="MuiInputBase-input MuiOutlinedInput-input emotion-11"
            id="lesson-title"
            placeholder="How to make the perfect cappuccino."
            required=""
            type="text"
            value=""
          />
          <fieldset
            aria-hidden="true"
            class="MuiOutlinedInput-notchedOutline emotion-12"
          >
            <legend
              class="emotion-13"
            >
              <span
                class="notranslate"
              >
                ​
              </span>
            </legend>
          </fieldset>
        </div>
      </div>
    </div>
    <div
      class="MuiStack-root emotion-6"
    >
      <label
        class="MuiTypography-root MuiTypography-labelLarge emotion-7"
        for="lesson-audience"
      >
        Audience
      </label>
      <p
        class="MuiTypography-root MuiTypography-bodyMedium emotion-8"
      >
        Who is this lesson intended for?
      </p>
      <div
        class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root emotion-9"
      >
        <div
          class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-multiline emotion-18"
        >
          <textarea
            aria-invalid="false"
            class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline emotion-19"
            id="lesson-audience"
            placeholder="Intelligent cats who live in single family homes, own an espresso machine, have opposable thumbs, and know the basics of making coffee."
            required=""
            style="height: 0px; overflow: hidden;"
          />
          <textarea
            aria-hidden="true"
            class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline emotion-19"
            readonly=""
            style="visibility: hidden; position: absolute; overflow: hidden; height: 0px; top: 0px; left: 0px; transform: translateZ(0); padding-top: 0px; padding-bottom: 0px; width: 100%;"
            tabindex="-1"
          />
          <fieldset
            aria-hidden="true"
            class="MuiOutlinedInput-notchedOutline emotion-12"
          >
            <legend
              class="emotion-13"
            >
              <span
                class="notranslate"
              >
                ​
              </span>
            </legend>
          </fieldset>
        </div>
      </div>
    </div>
    <div
      class="MuiStack-root emotion-6"
    >
      <label
        class="MuiTypography-root MuiTypography-labelLarge emotion-7"
        for="lesson-objectives"
      >
        Learning Objectives
      </label>
      <p
        class="MuiTypography-root MuiTypography-bodyMedium emotion-8"
      >
        What would you like your audience to be able to do by the end of the lesson?
      </p>
      <div
        class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root emotion-9"
      >
        <div
          class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-multiline emotion-18"
        >
          <textarea
            aria-invalid="false"
            class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline emotion-19"
            id="lesson-objectives"
            placeholder="Students will know the best approaches to making a cappuccino, including coffee grind, type of milk, steaming techniques, and pouring techniques."
            required=""
            style="height: 0px; overflow: hidden;"
          />
          <textarea
            aria-hidden="true"
            class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline emotion-19"
            readonly=""
            style="visibility: hidden; position: absolute; overflow: hidden; height: 0px; top: 0px; left: 0px; transform: translateZ(0); padding-top: 0px; padding-bottom: 0px; width: 100%;"
            tabindex="-1"
          />
          <fieldset
            aria-hidden="true"
            class="MuiOutlinedInput-notchedOutline emotion-12"
          >
            <legend
              class="emotion-13"
            >
              <span
                class="notranslate"
              >
                ​
              </span>
            </legend>
          </fieldset>
        </div>
      </div>
    </div>
    <div
      class="MuiStack-root emotion-6"
    >
      <label
        class="MuiTypography-root MuiTypography-labelLarge emotion-7"
        for="lesson-objectives"
      >
        Upload additional materials
      </label>
      <p
        class="MuiTypography-root MuiTypography-bodyMedium emotion-8"
      >
        Do you have additional learning context like a text file? Upload it to provide more context and make your lesson more relevant.
      </p>
      <ul
        class="MuiList-root MuiList-padding emotion-35"
      />
      <div
        class="MuiStack-root emotion-36"
      >
        <label
          class="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation upload-button emotion-37"
          role="button"
          tabindex="0"
        >
          <span
            class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-3"
          >
            <svg
              aria-hidden="true"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-4"
              data-testid="UploadFileIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8zm4 18H6V4h7v5h5zM8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"
              />
            </svg>
          </span>
          Upload file
          <input
            accept=".doc, .docx, .txt, .pdf"
            class="emotion-40"
            data-testid="file-uploader-input"
            type="file"
          />
          <span
            class="MuiTouchRipple-root emotion-5"
          />
        </label>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
